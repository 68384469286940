import { fork, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './TeacherDashboardTypes';
import { teacherDashboardServices } from '../../services/teacherDashboard.service';

function* workerMyLeavesList(data) {
  const state = yield select();
  const teacherId = state.Auth.Login.data._id;
  const value = {
    teacherId,
    status: data?.payload?.status,
    pageNumber: data?.payload?.pageNumber,
    limit: data?.payload?.limit,
  };
  const token = state.Auth.Login.data.accessToken;
  const response = yield teacherDashboardServices.myLeavesList(value, token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_MY_LEAVES_LIST,
      payload: response,
    });
  }
}

function* workerMyProfile() {
  const state = yield select();
  const teacherID = state?.Auth?.Login?.data?._id;
  const token = state.Auth.Login.data.accessToken;
  const response = yield teacherDashboardServices.getMyProfile(
    teacherID,
    token
  );
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_PROFILE,
      payload: response,
    });
  }
}

function* workerApplyLeave(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield teacherDashboardServices.applyLeave(value, token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_APPLY_LEAVE,
      payload: response,
    });
  }
}
function* workerEditLeave(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield teacherDashboardServices.editLeave(
    value?.value,
    token
  );
  if (response) {
    value?.onSuccess();
    yield put({
      type: actionTypes.UPDATE_EDIT_LEAVE,
      payload: response,
    });
  }
}
function* workerCancelLeave(data) {
  const value = data?.payload?.id;
  const token = data?.payload?.token;
  const response = yield teacherDashboardServices.cancelLeave(value, token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_CANCEL_LEAVE,
      payload: response,
    });
  }
}

function* workerChangeName(data) {
  const value = { fullName: data?.payload?.name };
  const id = data?.payload?.id;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield teacherDashboardServices.changeTeacherName(
    id,
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_CHANGE_NAME,
      payload: response,
    });
  }
}

function* workerChangePassword(data) {
  const value = { password: data?.payload?.password };
  const id = data?.payload?.id;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield teacherDashboardServices.changeTeacherEmail(
    id,
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_CHANGE_PASSWORD,
      payload: response,
    });
  }
}

function* workerChangePhone(data) {
  const value = { phoneNumber: data?.payload?.phone };
  const id = data?.payload?.id;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield teacherDashboardServices.changeTeacherPhoneNumber(
    id,
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_CHANGE_PHONE_NUM,
      payload: response,
    });
  }
}

function* workerChangeQualification(data) {
  const value = { qualification: data?.payload?.qualification };
  const id = data?.payload?.id;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield teacherDashboardServices.changeTeacherQualification(
    id,
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_CHANGE_QUALIFICATION,
      payload: response,
    });
  }
}

function* workerChangeUniversity(data) {
  const value = { university: data?.payload?.univ };
  const id = data?.payload?.id;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield teacherDashboardServices.changeTeacherUniversity(
    id,
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_CHANGE_UNVIERSITY,
      payload: response,
    });
  }
}

function* workerMyClasses() {
  const state = yield select();
  const teacherID = state.Auth.Login.data._id;
  const token = state.Auth.Login.data.accessToken;
  const body = {
    teacher: teacherID,
    subject: '',
    page_no: 1,
  };
  const response = yield teacherDashboardServices.getMyClasses(body, token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_MY_CLASSES,
      payload: response,
    });
  }
}

function* workerMyCoCurricularClasses(onSuccess) {
  const state = yield select();
  const teacherID = state.Auth.Login.data._id;
  const token = state.Auth.Login.data.accessToken;
  const body = {
    teacher: teacherID,
    activity: '',
    page_no: 1,
  };
  const response = yield teacherDashboardServices.getMyCoCurricularClasses(
    body,
    token
  );
  if (response) {
    if (typeof onSuccess === 'function') yield onSuccess();
    yield put({
      type: actionTypes.UPDATE_GET_MY_CO_CURRICULAR_CLASSES,
      payload: response,
    });
  }
}

function* workerGetChapterBySubject(data) {
  const value = {
    subjectId: data?.payload?.subject_id,
    moduleType: data?.payload?.moduleType,
  };

  const response = yield teacherDashboardServices.getChapterBySubjectId(value);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_CHAPTER_BY_SUBJECT,
      payload: response,
    });
  }
}

function* workerGetConceptByChapter(data) {
  const value = { chapterId: data?.payload?.chapterId };
  const response = yield teacherDashboardServices.getConceptsByChapterId(value);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_CONCEPT_BY_CHAPTER,
      payload: response,
    });
  }
}

function* workerGetConceptsByConceptId(data) {
  const value = { conceptId: data?.payload?.conceptId };
  const response = yield teacherDashboardServices.getSubConceptsByConceptId(
    value
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_SUB_CONCEPTS,
      payload: response,
    });
  }
}

function* workerAddTeachingHistory(data) {
  const value = data.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield teacherDashboardServices.addTeachingHistory(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_ADD_HISTORY,
      payload: response,
    });
  }
}

function* workerMyClassesHistory() {
  const state = yield select();
  const teacherID = state.Auth.Login.data._id;
  const token = state.Auth.Login.data.accessToken;
  const body = {
    teacher: teacherID,
    subject: '',
    timeFrame: 'last_30_days',
    page_no: 1,
  };
  const response = yield teacherDashboardServices.getMyClassesHistory(
    body,
    token
  );
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_MY_CLASSES_HISTORY,
      payload: response,
    });
  }
}

function* workerRescheduleClass(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const body = {
    teacher: state.Auth.Login.data._id,
    schedule: data?.payload?.schedule,
    timeSlot: data?.payload?.timeSlot,
    reason: data?.payload?.reason,
    date: data?.payload?.date,
  };
  const response = yield teacherDashboardServices.rescheduleClass(body, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_RESCHEDULE_CLASS,
      payload: response,
    });
  }
}

function* workerRescheduleCoCurricularClass(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const body = {
    teacher: state.Auth.Login.data._id,
    schedule: data?.payload?.schedule,
    timeSlot: data?.payload?.timeSlot,
    reason: data?.payload?.reason,
    date: data?.payload?.date,
  };
  const response = yield teacherDashboardServices.rescheduleCoCurricularClass(
    body,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_RESCHEDULE_COCURRICULAR_CLASS,
      payload: response,
    });
  }
}

function* workerTeacherSlotsPerDate(data) {
  const date = data?.payload?.date;
  const state = yield select();
  const id = state.Auth.Login.data._id;
  const token = state.Auth.Login.data.accessToken;
  const response = yield teacherDashboardServices.teacherSlotsPerDate(
    id,
    date,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_TEACHER_SLOTS_PER_DATE,
      payload: response,
    });
  }
}

function* workerTeacherSlotsCoCurricularPerDate(data) {
  const date = data?.payload?.date;
  const state = yield select();
  const id = state.Auth.Login.data._id;
  const token = state.Auth.Login.data.accessToken;
  const response =
    yield teacherDashboardServices.teacherSlotsCoCurricularPerDate(
      id,
      date,
      token
    );
  if (response) {
    yield put({
      type: actionTypes.UPDATE_COCURRICULAR_TEACHER_SLOTS_PER_DATE,
      payload: response,
    });
  }
}

function* workerUpdateProfilePic(data) {
  const state = yield select();
  const { id } = data.payload;
  const token = state.Auth.Login.data.accessToken;
  const body = {
    ProfilePic: data.payload.url,
  };
  const response = yield teacherDashboardServices.updateProfilePic(
    id,
    body,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_SET_PROFILE_PIC,
      payload: response,
    });
  }
}

function* workerSendPassword(data) {
  const response = yield teacherDashboardServices.sendPassword(data.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_SEND_PASSWORD_LINK,
      payload: response,
    });
  }
}

function* workerGetpayment(data) {
  const response = yield teacherDashboardServices.getPaymentHistory(
    data.payload
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_PAYMENT_HISTORY,
      payload: response,
    });
  }
}

function* workerUpdatePassword(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const id = state.Auth.Login.data._id;
  const body = {
    oldPasswordByUser: data.payload.oldPassword,
    newPasswordByUser: data.payload.newPassword,
  };
  const response = yield teacherDashboardServices.updatePassword(
    id,
    body,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_UPDATE_PASSWORD,
      payload: response,
    });
  }
}

function* watcherTeacherDashboard() {
  yield takeLatest(actionTypes.MY_LEAVES_LIST, workerMyLeavesList);
  yield takeLatest(actionTypes.APPLY_LEAVE, workerApplyLeave);
  yield takeLatest(actionTypes.EDIT_LEAVE, workerEditLeave);
  yield takeLatest(actionTypes.CANCEL_LEAVE, workerCancelLeave);
  yield takeLatest(actionTypes.GET_PROFILE, workerMyProfile);
  yield takeLatest(actionTypes.CHANGE_NAME, workerChangeName);
  yield takeLatest(actionTypes.CHANGE_PASSWORD, workerChangePassword);
  yield takeLatest(actionTypes.CHANGE_PHONE_NUM, workerChangePhone);
  yield takeLatest(actionTypes.CHANGE_QUALIFICATION, workerChangeQualification);
  yield takeLatest(actionTypes.CHANGE_UNVIERSITY, workerChangeUniversity);
  yield takeLatest(actionTypes.GET_MY_CLASSES, workerMyClasses);
  yield takeLatest(
    actionTypes.GET_MY_CO_CURRICULAR_CLASSES,
    workerMyCoCurricularClasses
  );
  yield takeLatest(
    actionTypes.GET_CHAPTER_BY_SUBJECT,
    workerGetChapterBySubject
  );
  yield takeLatest(
    actionTypes.GET_CONCEPT_BY_CHAPTER,
    workerGetConceptByChapter
  );
  yield takeLatest(actionTypes.GET_SUB_CONCEPTS, workerGetConceptsByConceptId);
  yield takeLatest(actionTypes.GET_MY_CLASSES_HISTORY, workerMyClassesHistory);
  yield takeLatest(
    actionTypes.TEACHER_SLOTS_PER_DATE,
    workerTeacherSlotsPerDate
  );
  /* eslint-disable */
  yield takeLatest(
    actionTypes.COCURRICULAR_TEACHER_SLOTS_PER_DATE,
    workerTeacherSlotsCoCurricularPerDate
  );
  /* eslint-enable */
  yield takeLatest(actionTypes.RESCHEDULE_CLASS, workerRescheduleClass);
  /* eslint-disable */
  yield takeLatest(
    actionTypes.RESCHEDULE_COCURRICULAR_CLASS,
    workerRescheduleCoCurricularClass
  );
  /* eslint-enable */
  yield takeLatest(actionTypes.ADD_HISTORY, workerAddTeachingHistory);
  yield takeLatest(actionTypes.SET_PROFILE_PIC, workerUpdateProfilePic);
  yield takeLatest(actionTypes.SEND_PASSWORD_LINK, workerSendPassword);
  yield takeLatest(actionTypes.GET_PAYMENT_HISTORY, workerGetpayment);
  yield takeLatest(actionTypes.UPDATE_PASSWORD, workerUpdatePassword);
}

function* fetchAll() {
  yield fork(watcherTeacherDashboard);
}

export default fetchAll();
