import React, { useEffect, useState } from 'react';
import { array, func, string } from 'prop-types';
import { renderText } from '../BrainGym/QueAnswer/textHelper';
import { findKeyByValue } from '../BrainGym/QueAnswer/questionHelper';
import { checkIcon } from '../../../../../assets/icons/IconList';

const PracticeAnswerOption = ({
  questionsInfo,
  itemId,
  item,
  ind,
  selectedOption,
  setSelectedOption,
  optionsId,
  setIsSubmitValid,
}) => {
  const [dragData, setDragData] = useState([]);
  const questionType = findKeyByValue(questionsInfo?.solutionType);
  useEffect(() => {
    setDragData();
  }, [questionsInfo]);

  const selectAns = (id, questId, itemIdData) => {
    if (questionType === 'passage') {
      if (selectedOption.length > 0) {
        const index = selectedOption.findIndex((o) => o.qid === questId);
        if (index !== -1) selectedOption.splice(index, 1);
      }
      setSelectedOption([
        ...selectedOption,
        {
          qid: questId,
          answer: id,
          item: itemIdData,
        },
      ]);
      if (questionsInfo.passageQuestions.length === selectedOption.length + 1) {
        setIsSubmitValid(true);
      }
    } else {
      setSelectedOption([id]);
      setIsSubmitValid(true);
    }
  };

  let renderAnswer = null;
  switch (questionType) {
    case 'drag-and-drop':
      renderAnswer = (
        <table className='drag-detail-table'>
          <thead>
            <th colSpan='2'>Statement</th>
            <th colSpan='2'>Options</th>
          </thead>
          <tbody>
            {dragData?.length > 0 &&
              dragData?.map((option) => (
                <tr>
                  <td>
                    {option?.image ? (
                      <img src={option?.image} alt='option_image' />
                    ) : (
                      <span>No Image</span>
                    )}
                  </td>
                  <td>
                    {option?.text ? (
                      <span>{option?.text}</span>
                    ) : (
                      <span>No text</span>
                    )}
                  </td>
                  <td>
                    {option?.statementImage ? (
                      <img src={option?.statementImage} alt='option_image' />
                    ) : (
                      <span>No Image</span>
                    )}
                  </td>
                  <td>
                    {option?.statementText ? (
                      <span>{option?.statementText}</span>
                    ) : (
                      <span>No text</span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      );
      break;
    case 'passage':
      renderAnswer = (
        <div
          className={`option-wrapper-tile-type d-flex align-items-center justify-content-between p-4
                     ${
                       selectedOption.find((o) => o.item === item._id)
                         ? 'true'
                         : ''
                     }`}
          id={itemId + ind}
        >
          <button
            type='button'
            id={itemId + ind}
            className='optionsbtn'
            onClick={() => selectAns(ind, itemId, item._id)}
          >
            <span className='opti'> {String.fromCharCode(ind + 65)} . </span>
            {item?.imageElement === null && (
              <div className='questionHtlm-right'>
                <span>{renderText(item?.text)} </span>
              </div>
            )}
            {item?.imageElement !== '' && (
              <div className='questionHtlm-right'>
                <span>{renderText(item?.imageElement)}</span>
              </div>
            )}
            <div className='icon--check-wrapper d-flex align-items-center justify-content-center'>
              {checkIcon}
            </div>
          </button>
        </div>
      );
      break;
    default:
      renderAnswer = (
        <div
          className={`option-wrapper-tile-type d-flex align-items-center justify-content-between p-4
                     ${selectedOption?.includes(ind) ? 'true' : ''}
                    `}
          id={ind}
        >
          <button
            type='button'
            id={optionsId + ind}
            className='optionsbtn'
            onClick={() => selectAns(ind)}
          >
            <span className='opti'> {String.fromCharCode(ind + 65)} . </span>
            {item?.imageElement === null && (
              <div className='questionHtlm-right'>
                <span>{renderText(item?.text)} </span>
              </div>
            )}
            {item?.imageElement !== '' && (
              <div className='questionHtlm-right'>
                <span>{renderText(item?.imageElement)}</span>
              </div>
            )}
            <div className='icon--check-wrapper d-flex align-items-center justify-content-center'>
              {checkIcon}
            </div>
          </button>
        </div>
      );
  }
  return renderAnswer;
};

PracticeAnswerOption.propTypes = {
  itemId: string.isRequired,
  item: array.isRequired,
  ind: array.isRequired,
  selectedOption: string.isRequired,
  setSelectedOption: func.isRequired,
  optionsId: string.isRequired,
  setIsSubmitValid: func.isRequired,
};

export default PracticeAnswerOption;
