import React, { useEffect, useState } from 'react';
import '../../../../components/dashboard/dashboard.scss';
import { Modal, Spinner } from 'react-bootstrap';
import { func, bool, object } from 'prop-types';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import moment from 'moment';
import Gradeicon from '../../../../../assets/images/gradeicon.svg';
import Studenticon from '../../../../../assets/images/students.svg';
import stopwatch from '../../../../../assets/images/stopwatch.png';
import defaultPic from '../../../../../assets/images/defaultPic.png';
import { useDispatch, useSelector } from 'react-redux';
import Calendericon from '../../../../../assets/images/calendaricon.svg';
import Progressbar from '../../../../components/dashboard/Rightsidepart/Progressbar';
import '../../teachers.scss';
import { getStudentListCurricular } from '../../../../../stores/StudentData/StudentDataAction';
import { useHistory } from 'react-router';

function SubjectModel({ handleSubjectModel, showSubjectModel, modalInfo, studentList }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { myClasses } = useSelector((state) => state?.TeacherDashboard);
  const dateformatted = moment(modalInfo.date).format('dddd, MMMM Do');
  useEffect(() => {
    if (myClasses?.data?.[0]?.response?.[0]) {
      dispatch(
        getStudentListCurricular(myClasses?.data?.[0]?.response?.[0]?.scheduleInfo?._id)
      );
    }
  }, [myClasses]);
  const Loader = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner animation='border' variant='warning' />
    </div>
  );
  return (
    <>
      <Modal
        size='lg'
        show={showSubjectModel}
        onHide={() => {handleSubjectModel(false);
          history.push('/teacher/co-curricular');
        }}
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-lg'>
            {modalInfo.subject}
            <br />
          </Modal.Title>
        </Modal.Header>
        <div className='col-sm-12 modelsecdiv'>
          <div className='row'>
            <div className='col-sm-5 floatleft'>
              <img className='Clockicon2' src={Calendericon} alt='clock' />
              <span>
                {dateformatted} | {modalInfo.timeSlot}
              </span>
            </div>
            <div className='col-sm-2 floatleft'>
              <img className='Clockicon2' src={Gradeicon} alt='clock' />
              <span>{modalInfo.grade}</span>
            </div>
            <div className='col-sm-2 padd0'>
              <img className='Clockicon2' src={Studenticon} alt='clock' />
              <span>{modalInfo.numberOfStudents} students</span>
            </div>
            <div className='col-sm-3 floatleft'>
              <img className='Clockicon2' src={stopwatch} alt='clock' />
              <span>{modalInfo.duration} minutes class</span>
            </div>
          </div>
        </div>
        <div className='tabsection'>
          <Tabs defaultActiveKey='first'>
            <Tab eventKey='first' title='Students'>
              <div className='col-sm-12 secondtabcontent floatleft'>
                <div className='row'>
                  {!studentList?.length > 0 ? <>
                    <Loader />
                  </> : studentList?.map((item) => (
                    <div className='col-sm-6 divpadd floatleft' key={item.id}>
                      <div className='col-sm-12 floatleft studentlistdiv'>
                        <div className='row'>
                          <div className='col-sm-2 floatleft'>
                            <img
                              className='Clockicon'
                              src={item.profilePic ? item.profilePic : defaultPic}
                              alt='studentimg'
                            />
                          </div>
                          <div className='col-sm-10 progressbarsection floatleft'>
                            <div className='studentname'> {item.studentName}</div>
                            <div className='studentgrade'>
                              Grade {item.gradeInfo.name}
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-12 floatleft'>
                          <Progressbar
                            className='progress-bar-one'
                            peronevalue={Math.floor(
                              (item.completedSubConceptsCount * 100) /
                              item.subConceptsCount
                            )}
                            perone
                            title='Subject completion'
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <Modal.Body />
      </Modal>
    </>
  );
}

SubjectModel.propTypes = {
  handleSubjectModel: func.isRequired,
  showSubjectModel: bool.isRequired,
  modalInfo: object.isRequired,
};

export default SubjectModel;
