import { all } from 'redux-saga/effects';
import goalWatcher from '../BrainGym/BrainGymOperation';
import authWatcher from '../Auth/AuthOperation';
import teacherDashboardWatcher from '../TeacherDashboard/TeacherDashboardOperation';
import myExamWatcher from '../MyExam/MyExamOperation';
import MyOlympiadExamWatcher from '../MyOlympiad/MyOlympiadExamOperation';
import studentSubscription from '../MySubscription/MySubscriptionOperation';
import studentDataWatcher from '../StudentData/StudentDataOperation';
import myAssignmentWatcher from '../StudentAssignment/StudentAssignmentOperation';
import watcherStudentDashboard from '../StudentDashboard/StudentDashboardOperation';
import myStudentAssignmentWatcher from '../MyAssignments/MyAssignmentExamOperation';
import syllabusWatcher from '../Syllabus/syllabusOperations';

export default function* rootSaga() {
  yield all([
    goalWatcher,
    authWatcher,
    teacherDashboardWatcher,
    myExamWatcher,
    studentSubscription,
    studentDataWatcher,
    myAssignmentWatcher,
    watcherStudentDashboard,
    MyOlympiadExamWatcher,
    myStudentAssignmentWatcher,
    syllabusWatcher,
  ]);
}
