const RouteEnum = {
  HOME: '/',
  SUBJECT: '/subject',
  GRADES: '/grades',
  CHAPTERS: '/chapters/:subject',
  PRACTICE_TEST: '/practice-test',
  // HELPDESK: '/helpdesk',
  // COMPLAINT: '/dashboard/helpdesk/complaint',
  DASHBOARDHOME: '/dashboard/',
  DASHBOARD: '/dashboard/:viewtype',
  // DASHBOARDPOPUPS: '/dashboard/:viewtype/:popup',
  EXAMSECTION: '/dashboard/exams/:id/:sid',
  OLYMPIADEXAMSECTION: '/dashboard/olympiad/:id/:sid',
  ASSIGNMENTEXAMSECTION: '/dashboard/assignment/:id',
  // BRAINGYM: '/dashboard',

  REGISTER: '/register',
  NEWREGISTERHOME: '/newregister',
  NEWREGISTER: '/newregister/:viewtype',
  NEWEXP: '/newregister/:viewtype/:name',
  // USERTYPE: '/newregister/',
  PARENTDETAILS: '/newregister/parentdetails',
  OLYMPIAD: '/olympiad',
  LOGIN: '/login',
  PAYMENT: '/payment',
  HOMESKELETON: '/home-skeleton',
  TEACHERSHOME: '/teacher/',
  TEACHERS: '/teacher/:viewtype',
  EXAMDETAILS: '/exam-details',
  ZOOMCALL: '/zoomCall',
  ZOOMCALLCOCURRICULAR: '/zoomCallCoCurricular',
  PRACTICETEST_STUDENT:
    '/practice-test/:subjectp/:chapterp/:conceptp/:subConceptp',
  PRACTICE_WITHOUT_LOGIN: '/practice/:subId/:conId',
  ZOOMCALLSTUDENT: '/zoomCallStudent',
  ZOOMCALLSTUDENTCOCURRICULAR: '/zoomCallStudentCoCurricular',
  ForgotPassword: '/forgotPassword',
  ForgotPasswordConfirmation: '/forgotPasswordConfirmation/:type',
  SetNewPassword: '/setNewPassword/:user',
  myProfile: '/dashboard/My-profile',
  FORGOTPASSWORDOTP: '/forgotPasswordOtp',
  TERMSANDCONDITION: '/termsandcondition',
  // PRIVACYPOLICY: '/privacy-policy',
  ABOUTUS: '/aboutus',
  SCHOOLS: '/schools',
  CONTACTUS: '/contactus',
  PARENTABOUTUS: '/parentaboutus',
  FAQS: '/faqs',
  TEACHERABOUTUS: '/teacherAboutUs',
  BLOGS: '/blogs',
  FULLBLOGCONTENT: '/blog-details/:id/:title',

  //Olympiad practice test links
  // PRACTICE_OLYMPIAD: '/practice-test-olympiad',
  PRACTICE_TEST_OLYMPIAD: '/practice-test-olympiad/:subject/:chapter',
  BOOK_DEMO: '/book-demo',
  PAYMENTD : '/payment_details'
};

export default RouteEnum;
