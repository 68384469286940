import React, { useState } from 'react';
import {
  chevRight,
  select,
  // student,
  parents,
  teachers,
  errowRight,
} from '../../../assets/icons/IconList';
import RegisterOption from '../auth/register/RegisterOption';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../../assets/tempimages/Logo.svg';
import close from '../../../assets/images/close.png';
import ParentDetails from './ParentDetails';

const UserTypeSelection = ({ userType, setUserType }) => {
  const [step, setStep] = useState(0);
  const [countryVal, setCountryVal] = useState('');
  const [emailVal, setEmailVal] = useState('');
  // const [userType, setUserType] = useState('');
  const history = useHistory();

  const handledata = () => {
    if (userType !== '') {
      // if (userType === 'Teacher') {
      //   history.push('/register');
      // } else {
      history.push({
        pathname:
          userType === 'Parent'
            ? '/newregister/parent-details'
            : 'newregister/teacher-details',
        state: [userType],
      });
      setStep(1);
      // }
    }
  };

  const selectForm = () => {
    if (userType !== '') {
      setStep(1);
    }
  };
  const closeStep = () => {
    window.location.reload();
  };
  return (
    <div className={`register-main ${step === 5 && 'success'}`}>
      <div className='container'>
        {/* {step < 5 && (
          <div className='register-top'>
            <div className='row'>
              <div className='col-md-4 col-sm-12'>
                <Link to='/'>
                  <img className='logo' src={Logo} alt='website_log' />
                </Link>
              </div>
              <div className='col-md-7 col-sm-12'>
                {step > 0 && step < 5 && (
                  <h2>
                    You are registering as a <span>{userType}</span>
                  </h2>
                )}
              </div>
              <div className='inline-cross'>
                {step > 0 && step < 5 && (
                  // <Link to="/">
                  <div aria-hidden onClick={() => closeStep()}>
                    <img className='close' src={close} alt='website_log' />
                  </div>
                  // </Link>
                )}
              </div>
            </div>
          </div>
        )} */}
        {step === 0 && (
          // TEACHER AND PARENT SALECTING SCREEN
          <>
            <div className='row text-center'>
              <div className='register-top'>
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                    <Link to='/'>
                      <img className='logo' src={Logo} alt='website_log' />
                    </Link>
                  </div>
                </div>
                <div className='col-12 option-part-head'>
                  <h3>Register</h3>
                </div>
              </div>

              <div className='col-12'>
                <div className='row option-part'>
                  <div className='col-12'>
                    <p>Jolly good! Be a part of TautMore as a </p>
                  </div>
                  <div className='col-12'>
                    <RegisterOption
                      icon={parents}
                      title='Parent'
                      setUserType={() => setUserType('Parent')}
                      userType={userType}
                    />
                    <RegisterOption
                      icon={teachers}
                      title='Teacher'
                      setUserType={() => {
                        // console.log('the teacher has been disabled');
                        setUserType('Teacher');
                      }}
                      userType={userType}
                    />
                  </div>
                </div>
                <div className='row col-12'>
                  <h5 className='already-account'>
                    Already have an account? <Link to='/login'>Login here</Link>
                  </h5>
                  <button
                    className='next-button'
                    type='button'
                    disabled={userType === ''}
                    onClick={handledata}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserTypeSelection;
