import React, { useEffect, useState, useRef } from 'react';
import { array, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { startListening } from '../startLearning';
import { relative } from 'path';
import { right } from '@popperjs/core';

const DragAndDropAnswer = ({
  questionInChest,
  questionType,
  setSelect,
  setIsSubmitValid,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 1200) setIsMobile(true);
    // console.log('window.innerWidth', window.innerWidth, isMobile);
  }, [window.innerWidth]);

  const dragDefaultOption = [...questionInChest?.options];
  const [dragData, setDragData] = useState(dragDefaultOption);

  const defaultDropOptions = dragDefaultOption.map((e, i) => ({
    name: `name ${i + 1}`,
    value: '',
  }));
  const [dropData, setDropData] = useState(defaultDropOptions);

  const [dragItem, setDragItem] = useState();
  const [dragSource, setDragSource] = useState();

  useEffect(() => {
    let allHasValue = true;
    let solIndex = [];
    dropData?.forEach((element) => {
      if (element?.value !== '' && allHasValue) {
        solIndex.push(element?.dragsource);
      } else {
        allHasValue = false;
        solIndex = [-1];
      }
    });
    if (allHasValue) {
      setIsSubmitValid(true);
      if (!questionInChest?.solutionIndex) setSelect(solIndex);
      else if (
        questionInChest?.includes(-1) ||
        questionInChest?.length === 0 ||
        questionInChest?.join() !== solIndex.join()
      )
        setSelect(solIndex);
    } else {
      setIsSubmitValid(false);
    }
  }, [dropData]);

  useEffect(() => {
    const solutionIndex = questionInChest;
    if (solutionIndex?.length > 1) {
      const mapAnsOpt = solutionIndex?.map((element) => ({
        name: '',
        value: questionInChest?.options[element],
        dragsource: element,
      }));
      setDropData(mapAnsOpt);
      setDragData(Array(mapAnsOpt?.length).fill(''));
    }
  }, []);

  const handleDragStartTop = (index) => {
    setDragItem(index);
    setDragSource('TopItem');
  };

  const handleDropTop = (e, ind) => {
    if (ind === dropData[dragItem].dragsource) {
      const newList = [...dragData];
      const newitem = dropData[dragItem].value;
      newList.splice(ind, 1);
      newList.splice(ind, 0, newitem);
      setDragData(newList);
      dropData[dragItem].value = '';
    }
  };
  const handleClick = (ind) => {
    const newList = [...dragData];
    const newitem = dropData[ind].value;
    newList.splice(dropData[ind].dragsource, 1, newitem);
    setDragData(newList);
    dropData[ind].value = '';
    delete dropData[ind].dragsource;
  };

  const handleDropBottom = (e, ind) => {
    if (dragSource === 'TopItem') {
      if (dropData[ind].value === '') {
        const newList = [...dropData];
        const newitem = dragData[dragItem];
        newList.splice(ind, 1);
        newList.splice(ind, 0, {
          name: dropData[ind].name,
          value: newitem,
          dragsource: dragItem,
        });

        setDropData(newList);
        dragData[dragItem] = '';
      } else {
        dragData[dropData[ind].dragsource] = dropData[ind].value;
        const newList = [...dropData];
        const newitem = dragData[dragItem];
        newList.splice(ind, 1);
        newList.splice(ind, 0, {
          name: dropData[ind].name,
          value: newitem,
          dragsource: dragItem,
        });
        setDropData(newList);
        dragData[dragItem] = '';
      }
    } else if (dragSource === 'bottomItem') {
      const newList = [...dropData];
      const newitem = dropData[dragItem].value;

      newList.splice(ind, 1);
      newList.splice(ind, 0, {
        name: dropData[ind].name,
        value: newitem,
        dragsource: dropData[dragItem].dragsource,
      });

      newList.splice(dragItem, 1);
      newList.splice(dragItem, 0, {
        name: dropData[dragItem].name,
        value: '',
        dragsource: dragItem,
      });
      setDropData(newList);
      if (dropData[ind].value !== '') {
        dragData[dropData[ind].dragsource] = dropData[ind].value;
      }
    }
  };

  const handleDragStartBottom = (index) => {
    setDragItem(index);
    setDragSource('bottomItem');
  };

  const handleListenOption = (dataaa) => {
    let kllll = dataaa?.map((item, index) => {
      const cleanText = item.text.replace(/<\/?[^>]+(>|$)/g, '');
      const removeNbsp = cleanText.replace(/&nbsp;/g, ' ');
      return removeNbsp;
    });
    if (kllll?.length) startListening(kllll.join(' '));
  };
  const [dragging, setDragging] = useState(false);
  const [pos, setPos] = useState({ index: 0, left: 0, top: 0 });
  const ref = useRef(null);
  const DragDataRefs = dragData.map(() => useRef(null));
  const BottomDragDataRefs = dragData.map(() => useRef(null));
  const [highlightDropboxIndex, setHighlightDropboxIndex] = useState(null);

  const handleTouchStart = (e, i, isBottomItem) => {
    setDragging(true);
    // e.preventDefault();

    if (isBottomItem) {
      setDragSource('bottomItem');
      const { left, top } =
        BottomDragDataRefs[i].current.getBoundingClientRect();

      const touch = e.targetTouches[0];

      // let shiftX = touch.clientX - left;
      // let shiftY = touch.clientY - top;

      const x = touch.pageX;
      const y = touch.pageY;
      setPos({
        index: i,
        left: x,
        top: y,
      });
      handleDragStartBottom(i);
    } else {
      const { left, top } = DragDataRefs[i].current.getBoundingClientRect();

      const touch = e.targetTouches[0];

      const x = touch.pageX - left;
      const y = touch.pageY - top;

      setPos({ index: i, left: x, top: y });
      handleDragStartTop(i);
    }
  };
  const handleTouchMove = (e, i, isBottomItem) => {
    if (e.targetTouches[0].pageY < 20) {
      document.body.scrollBy(0, -15);
    }

    if (e.targetTouches[0].pageY + 60 > e.view.innerHeight) {
      document.body.scrollBy(0, 15);
    }
    if (isBottomItem) {
      const touch = e.targetTouches[0];
      const element = document.elementFromPoint(touch.clientX, touch.clientY);
      if (element && element.className.includes('right-part')) {
        setHighlightDropboxIndex(
          parseInt(
            element.id
              ? element.id
              : element.parentElement.id
              ? element.parentElement.id
              : element.parentElement.parentElement.id
          )
        );
      }
      const { left, top } =
        BottomDragDataRefs[i].current.getBoundingClientRect();
      // let shiftX =
      //   e.clientX -
      //   DragDataRefs[i].current.getBoundingClientRect().left;
      // let shiftY =
      //   e.clientY -
      //   DragDataRefs[i].current.getBoundingClientRect().top;
      if (dragging) {
        const x = touch.pageX;
        const y = touch.pageY;
        setPos({ index: i, left: x + 2, top: y + 2 });
      }
    } else {
      const touch = e.touches[0];
      const element = document.elementFromPoint(touch.clientX, touch.clientY);
      if (element && element.className.includes('right-part')) {
        setHighlightDropboxIndex(
          parseInt(
            element.id
              ? element.id
              : element.parentElement.id
              ? element.parentElement.id
              : element.parentElement.parentElement.id
          )
        );
      }

      const { left, top } = DragDataRefs[i].current.getBoundingClientRect();
      // let shiftX =
      //   e.clientX -
      //   DragDataRefs[i].current.getBoundingClientRect().left;
      // let shiftY =
      //   e.clientY -
      //   DragDataRefs[i].current.getBoundingClientRect().top;
      if (dragging) {
        const x = touch.pageX - left;
        const y = touch.pageY - top;
        setPos({ index: i, left: x + 2, top: y + 2 });
      }
    }

    // console.log('the div is drageeddddd nvn2', pos, touch);
  };
  const handleTouchEnd = (e, i, isBottomItem) => {
    setDragging(false);
    setHighlightDropboxIndex(null);
    // e.preventDefault();

    if (isBottomItem) {
      // handleDropBottom(e, i);
      const touch = e.changedTouches[0];
      const element = document.elementFromPoint(touch.clientX, touch.clientY);

      if (element && element.className.includes('right-part')) {
        const DropBoxIndex = parseInt(
          element.id
            ? element.id
            : element.parentElement.id
            ? element.parentElement.id
            : element.parentElement.parentElement.id
        );
        const DragSourceIndex = i;
        const newList = [...dropData];
        const newitem = dropData[DragSourceIndex].value;

        newList.splice(DropBoxIndex, 1);
        newList.splice(DropBoxIndex, 0, {
          name: dropData[DropBoxIndex].name,
          value: newitem,
          dragsource: dropData[DragSourceIndex].dragsource,
        });

        newList.splice(DragSourceIndex, 1);
        newList.splice(DragSourceIndex, 0, {
          name: dropData[DragSourceIndex].name,
          value: '',
          dragsource: DragSourceIndex,
        });
        setDropData(newList);
        if (dropData[DropBoxIndex].value !== '') {
          dragData[dropData[DropBoxIndex].dragsource] =
            dropData[DropBoxIndex].value;
        }
      }
    } else {
      // handleDropTop(e, i);
      const touch = e.changedTouches[0];
      const element = document.elementFromPoint(touch.clientX, touch.clientY);

      if (element && element.className.includes('right-part')) {
        const DropBoxIndex = parseInt(element.id);
        const DragSourceIndex = i;

        if (dropData[DropBoxIndex]?.value === '') {
          const newList = [...dropData];
          const newitem = dragData[DragSourceIndex];
          newList.splice(DropBoxIndex, 1);
          newList.splice(DropBoxIndex, 0, {
            name: dropData[DropBoxIndex].name,
            value: newitem,
            dragsource: DragSourceIndex,
          });

          setDropData(newList);
          dragData[DragSourceIndex] = '';
        } else {
          dragData[dropData[DropBoxIndex]?.dragsource] =
            dropData[DropBoxIndex].value;
          const newList = [...dropData];
          const newitem = dragData[DragSourceIndex];
          newList.splice(DropBoxIndex, 1);
          newList.splice(DropBoxIndex, 0, {
            name: dropData[DropBoxIndex].name,
            value: newitem,
            dragsource: DragSourceIndex,
          });
          setDropData(newList);
          dragData[DragSourceIndex] = '';
        }

        // console.log(
        //   `You dropped ${data} to ${element.id} `,
        //   data,
        //   element,

        // );
      }
    }
  };

  return (
    <div className='drag-and-drop-que-part'>
      {questionType !== 'scrambled-and-unscrambled' && (
        <div className='drag-and-drop-stiky-option'>
          <div className='col-12 d-flex align-items-center justify-content-between mt-3 mt-lg-4'>
            <span className='answer-title col-11'>Select your answer</span>
          </div>
          <div className='object-container '>
            {dragData?.map((item, i) => (
              <div
                className='drag-item'
                ref={DragDataRefs[i]}
                style={{
                  touchAction: 'none',
                }}
                draggable
                onDragStart={() => handleDragStartTop(i)}
                onTouchStart={(e) => handleTouchStart(e, i)}
                onTouchMove={(e) => handleTouchMove(e, i)}
                onTouchEnd={(e) => handleTouchEnd(e, i)}
                onDrop={(e) => {
                  handleDropTop(e, i);
                }}
                onDragOver={(e) => e.preventDefault()}
              >
                <div className='drag-item-index'>
                  <span>{i + 1}</span>
                </div>
                <div
                  className='drag-item-sub'
                  style={{
                    position:
                      dragSource !== 'bottomItem' && dragging && pos.index === i
                        ? 'absolute'
                        : 'initial',
                    top: `${pos.top}px`,
                    left: `${pos.left}px`,
                    zIndex: 1000,
                  }}
                >
                  {item?.text && (
                    <span className='horizontal-text'>{item?.text}</span>
                  )}
                  {item?.image && <img src={item?.image} alt='option_image' />}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {questionInChest?.questionOrientaion === 'horizontal' && !isMobile && (
        <div className='preview-table-main'>
          <table className='drag-detail-table horizontal'>
            <thead>
              <th>Statement</th>
              {questionInChest?.statement?.length > 0 &&
                questionInChest?.statement?.map((option, key) => (
                  <td>
                    <div className='horizontal-cell'>
                      {String.fromCharCode(key + 65)}.
                      <div className='hori-text-img d-flex justify-content-center align-items-center'>
                        {option?.text && (
                          <span className='horizontal-text d-flex justify-content-center align-items-center'>
                            <span className='opti'></span>
                            {option?.text}
                          </span>
                        )}
                        {option?.image && (
                          <img src={option?.image} alt='option_image' />
                        )}
                      </div>
                    </div>
                  </td>
                ))}
            </thead>
            <tbody>
              <tr>
                <th>Options</th>
                {questionInChest?.statement?.length > 0 &&
                  questionInChest?.statement?.map((option, key) => (
                    <td>
                      <div className='horizontal-cell'>
                        <span className='opti'>{key + 1}. </span>
                        <span
                          className='horizontal-right-part-val'
                          onDrop={(e) => {
                            handleDropBottom(e, key);
                          }}
                          onTouchEnd={(e) => {
                            handleDropBottom(e, key);
                          }}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <span className='horizontal-right-part-val-common'>
                            {dropData[key]?.value !== '' &&
                              dropData[key]?.value.image !== '' && (
                                <img
                                  draggable
                                  onDragStart={() => handleDragStartBottom(key)}
                                  onDoubleClick={() => handleClick(key)}
                                  src={dropData[key]?.value.image}
                                  alt='option_image'
                                />
                              )}

                            {dropData[key]?.value !== '' &&
                              dropData[key]?.value.text !== '' && (
                                <div
                                  draggable
                                  onDragStart={() => handleDragStartBottom(key)}
                                  onDoubleClick={() => handleClick(key)}
                                >
                                  {dropData[key]?.value.text}
                                </div>
                              )}
                          </span>
                        </span>
                      </div>
                    </td>
                  ))}
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {(questionInChest?.questionOrientaion === 'vertical' || isMobile) && (
        <table className='drag-detail-table vertical'>
          <thead>
            <th>Statement</th>
            <th>Options</th>
          </thead>
          <tbody>
            {questionInChest?.statement?.length > 0 &&
              questionInChest?.statement?.map((option, key) => (
                <tr>
                  <td className='left-part'>
                    <div className='vertical-cell answered-section'>
                      <span className='opti'>
                        {String.fromCharCode(key + 65)}.
                      </span>
                      {option?.text && (
                        <span className='vertical-text'>{option?.text}</span>
                      )}
                      {option?.image && (
                        <span className='vertical-text'>
                          <img src={option?.image} alt='option_image' />
                        </span>
                      )}
                    </div>
                  </td>
                  <td
                    className='right-part'
                    id={key}
                    style={{
                      background:
                        dragging && highlightDropboxIndex === key
                          ? '#EDFFFA'
                          : 'none',
                    }}
                  >
                    <div className='vertical-cell answered-section'>
                      <span className='opti'>{key + 1}. </span>
                      <span
                        className='right-part-val'
                        id={key}
                        ref={BottomDragDataRefs[key]}
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          touchAction: 'none',
                        }}
                        onDrop={(e) => {
                          handleDropBottom(e, key);
                        }}
                        onDragOver={(e) => e.preventDefault()}
                        onDragEnter={(e) => {
                          // handleDropBottom(e, key);
                        }}
                        onTouchStart={(e) => handleTouchStart(e, key, true)}
                        onTouchMove={(e) => handleTouchMove(e, key, true)}
                        onTouchEnd={(e) => handleTouchEnd(e, key, true)}
                      >
                        <span
                          className='right-part-val-common'
                          id={key}
                          style={{
                            position:
                              dragSource === 'bottomItem' &&
                              dragging &&
                              pos.index === key
                                ? 'absolute'
                                : 'initial',
                            top: `${pos.top}px`,
                            left: `${pos.left}px`,
                            zIndex: 1000,
                          }}
                        >
                          {dropData[key]?.value !== '' &&
                            dropData[key]?.value.image !== '' && (
                              <img
                                draggable
                                id={key}
                                className='right-part-val-imagee'
                                onDragStart={() => handleDragStartBottom(key)}
                                onDoubleClick={() => handleClick(key)}
                                src={dropData[key]?.value.image}
                                alt='option_image'
                              />
                            )}

                          {dropData[key]?.value !== '' &&
                            dropData[key]?.value.text !== '' && (
                              <div
                                draggable
                                onDragStart={() => handleDragStartBottom(key)}
                                onDoubleClick={() => handleClick(key)}
                              >
                                <span>{dropData[key]?.value.text}</span>
                              </div>
                            )}
                        </span>
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

DragAndDropAnswer.propTypes = {
  questionInChest: array.isRequired,
  questionType: string.isRequired,
  setSelect: func.isRequired,
  selectedOption: array.isRequired,
  allQuesAns: array.isRequired,
};
const mapStateToProps = (state) => ({
  allQuesAns: state.MyExam.allQuesAns,
});
export default connect(mapStateToProps)(DragAndDropAnswer);
