import React from 'react';
import { object, string } from 'prop-types';
import { renderText } from '../../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/textHelper';
import DragAndDropQuestion from '../DragAndDropQuestion/DragAndDropQuestion';

const TestQuestion = ({ qIndex, description, queDetails }) => (
  <div className='row'>
    <div className='col-12'>
      <div className='question-box'>
        <h4>{qIndex ? `${qIndex}. Question:` : 'Question title:'}</h4>
        <h2>
          <span>{renderText(description)}</span>
        </h2>
        <DragAndDropQuestion questionDetailsData={queDetails} />
      </div>
    </div>
  </div>
);
TestQuestion.propTypes = {
  queDetails: object.isRequired,
  qIndex: string.isRequired,
  description: string.isRequired,
};
export default TestQuestion;
