import React from 'react';
import { object } from 'prop-types';
import DragAndDropAnswer from './DragAndDropAnswer';
import {
  findKeyByValue,
  checkDragDropSnunscramble,
} from '../../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/questionHelper';

const DragAndDropQuestion = ({ questionDetailsData }) => {
  const questionType = findKeyByValue(questionDetailsData?.solutionType);

  if (!checkDragDropSnunscramble(questionType)) return null;
  return (
    <div>
      <DragAndDropAnswer
        questionDetailsData={questionDetailsData}
        questionType={questionType}
      />
    </div>
  );
};

DragAndDropQuestion.propTypes = {
  questionDetailsData: object.isRequired,
};

export default DragAndDropQuestion;
