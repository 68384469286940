import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { func, bool, string, array } from 'prop-types';
import { Formik, Form, Field } from 'formik';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import enGb from 'date-fns/locale/en-GB';
import { rescheduleValidation } from './mockData/rescheduleData';
import {
  teacherSlotsPerDateAction,
  rescheduleClassAction,
} from '../../../../../stores/TeacherDashboard/TeacherDashboardAction';
import { chevRight } from '../../../../../assets/icons/IconList';

const ReschedulePopup = ({
  model,
  handleModel,
  scheduleId,
  teacherSlotsPerDate,
  teacherSlotsPerDateData,
  rescheduleClass,
  reScheduleClassData,
  getMyClasses,
}) => {
  const [checked, setChecked] = useState('');
  const [timeError, setTimeError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [slotsArr, setSlotsArr] = useState([]);

  const [isRescheduling, setIsRescheduling] = useState(false);

  useEffect(() => {
    if (teacherSlotsPerDateData?.status === 'success') {
      setLoading(false);
      setSlotsArr(teacherSlotsPerDateData.data.availableSlots);
    }
  }, [teacherSlotsPerDateData]);

  useEffect(() => {
    if (reScheduleClassData.status === 'success') {
      setIsRescheduling(false);
      setToggle(true);
      getMyClasses();
    }
  }, [reScheduleClassData]);

  const rescheduleDateChange = (e, setFieldValue) => {
    setLoading(true);
    setFieldValue('rescheduleDate', moment(e).format('YYYY-MM-DD'));
    const date = moment(e).format('YYYY-MM-DD');
    const data = { date };
    teacherSlotsPerDate(data);
  };

  const handleClick = (time) => {
    setChecked(time);
    if (checked !== '') {
      setTimeError(false);
    }
  };

  const submitReschedule = (values) => {
    if (checked === '') {
      setTimeError(true);
      return;
    }
    if (values.rescheduleDate === '' || values.reason === '') {
      return;
    }

    const data = {
      schedule: scheduleId,
      date: values?.rescheduleDate,
      timeSlot: checked,
      reason: values?.reason,
    };
    rescheduleClass(data);
    setIsRescheduling(true);
  };
  const modelHide = () => {
    handleModel(false);
    getMyClasses();
    setSlotsArr([]);
    setToggle(false);
  };

  const renderList = () => {
    if (!slotsArr.length || slotsArr.length === 0) {
      return 'No available slots for the selected date';
    }

    return slotsArr.map((val) => (
      <div key={val} className={`time-list ${checked === val ? 'active' : ''}`}>
        <label htmlFor={val.label} className='round'>
          <input
            type='radio'
            name='time'
            id={val}
            onClick={() => handleClick(val)}
          />
          <span className='checkmark' />
        </label>
        {val}
      </div>
    ));
  };

  return (
    <Modal
      show={model}
      onHide={() => modelHide()}
      className='calendar-leave-popup reschedule-popup'
    >
      <Modal.Header closeButton>
        {toggle ? (
          <div />
        ) : (
          <Modal.Title id='example-modal-sizes-title-lg'>
            <h3>Reschedule Class</h3>
            <p>
              Please make sure you reschedule your class 1 day prior and not on
              the same day
            </p>
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={{ rescheduleDate: '', reason: '' }}
          validationSchema={rescheduleValidation}
          validator={() => ({})}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => (
            <>
              {toggle ? (
                <div className='reschedule-success'>
                  <h3>Class rescheduled successfully</h3>
                  <button
                    className='button-common'
                    onClick={() => modelHide()}
                    type='button'
                  >
                    Continue {/* <span>{chevRight}</span> */}
                  </button>
                </div>
              ) : (
                <Form onSubmit={handleSubmit}>
                  <div className='label'>Reschedule date* </div>
                  <DatePicker
                    minDate={new Date()}
                    name='rescheduleDate'
                    dateFormat='MMMM dd, yyyy'
                    locale={enGb}
                    value={values.rescheduleDate}
                    formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                    onChange={(e) => rescheduleDateChange(e, setFieldValue)}
                    placeholderText='Select the new date'
                  />
                  <br /> <br />
                  {errors.rescheduleDate && touched.rescheduleDate ? (
                    <div className='mt-25 ml-25 rp-manage-school_error-message'>
                      {errors.rescheduleDate}
                    </div>
                  ) : null}
                  {loading ? (
                    <div className='timer-loader'>
                      <Spinner animation='border' variant='success' />
                    </div>
                  ) : (
                    teacherSlotsPerDateData?.data?.availableSlots?.length >
                      0 && (
                      <>
                        <div className='label'>Time*</div>
                        <div className='time-list-main'>{renderList()}</div>
                        {timeError && (
                          <div className='mt-25 ml-25 rp-manage-school_error-message'>
                            Please select a time slot
                          </div>
                        )}
                      </>
                    )
                  )}
                  {!loading &&
                    teacherSlotsPerDateData?.data?.availableSlots?.length ===
                      0 && (
                      <p style={{ color: 'red', fontSize: '16px' }}>
                        No available slots for the selected date
                      </p>
                    )}
                  {!loading &&
                  teacherSlotsPerDateData?.data?.availableSlots?.length ===
                    0 ? (
                    ''
                  ) : (
                    <>
                      <div className='label'>Rescheduling reason*</div>
                      <Field
                        className='rescheduleTextarea'
                        component='textarea'
                        name='reason'
                        onChange={handleChange}
                        value={values.reason}
                        placeholder='Briefly explain the reason for rescheduling the class.'
                      />
                    </>
                  )}
                  <br /> <br />
                  {errors.reason && touched.reason ? (
                    <div className='mt-25 ml-25 rp-manage-school_error-message'>
                      {errors.reason}
                    </div>
                  ) : null}
                  <div className='text-center'>
                    <button
                      type='submit'
                      onClick={() => {
                        submitReschedule(values);
                      }}
                      className='button-common'
                    >
                      {isRescheduling ? (
                        <Spinner animation='border' variant='light' />
                      ) : (
                        'Reschedule'
                      )}

                      {/* <span>{chevRight}</span> */}
                    </button>
                  </div>
                </Form>
              )}
            </>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

ReschedulePopup.propTypes = {
  model: bool.isRequired,
  handleModel: func.isRequired,
  scheduleId: string.isRequired,
  teacherSlotsPerDate: func.isRequired,
  teacherSlotsPerDateData: array.isRequired,
  rescheduleClass: func.isRequired,
  reScheduleClassData: array.isRequired,
  getMyClasses: func.isRequired,
};

const mapStateToProps = (state) => ({
  teacherSlotsPerDateData: state.TeacherDashboard.teacherSlotsPerDateData,
  reScheduleClassData: state.TeacherDashboard.rescheduleclassData,
});

const mapDispatchToProps = (dispatch) => ({
  teacherSlotsPerDate: (data) => dispatch(teacherSlotsPerDateAction(data)),
  rescheduleClass: (data) => dispatch(rescheduleClassAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReschedulePopup);
