import React from 'react';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { array } from 'prop-types';

const CalendarMonth = ({ events, views }) => (
  <BigCalendar
    events={events}
    views={views}
    step={60}
    showMultiDayTimes
    defaultDate={new Date()}
  />
);

CalendarMonth.propTypes = {
  events: array.isRequired,
  views: array.isRequired,
};

export default CalendarMonth;
