import React, { useEffect } from 'react';
import { func, object, string } from 'prop-types';
import MyexamanswerLeftpart from './MyexamanswerLeftpart';
import MyexamanswerRightpart from './MyexamanswerRightpart';
import close from '../../../../assets/images/close.png';
import Spinner from 'react-bootstrap/Spinner';

const MyexamAnswer = ({
  setExam,
  prevExamId,
  myReport,
  myReportData,
  setQueAns,
  type,
  closePopup,
}) => {
  const [isLoading, setLoading] = React.useState(true);
  const closeReport = () => {
    setExam(false);
    setQueAns(false);
    closePopup ? closePopup() : '';
    if (type == 'assignment') {
      history.push('/dashboard/exams');
    } else {
      history.push('/dashboard/assignments');
    }
  };
  useEffect(() => {
    if (type == 'assignment') {
      myReport({
        assignment: prevExamId,
      });
    } else
      myReport({
        examResultId: prevExamId,
      });
  }, [prevExamId]);

  useEffect(() => {
    if (myReportData?.statusCode !== undefined) {
      if (myReportData?.statusCode === 200) {
        setLoading(false);
      }
    } else {
      setLoading(true);
    }
  }, [myReportData]);
  return (
    <div className='myexam-answer-main-top'>
      {isLoading ? (
        <Spinner
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
          }}
          animation='border'
          role='status'
          variant='warning'
        />
      ) : (
        <>
          <div className='close-top'>
            <button type='button' className='close-btn' onClick={closeReport}>
              <img src={close} alt='close' />
            </button>
          </div>
          <div className='row myexam-answer-main'>
            <div className='col-md-4'>
              <MyexamanswerLeftpart myReportData={myReportData} type={type} />
            </div>
            <div className='col-md-8'>
              <MyexamanswerRightpart myReportData={myReportData} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

MyexamAnswer.propTypes = {
  setExam: func.isRequired,
  prevExamId: string.isRequired,
  myReport: func.isRequired,
  myReportData: object.isRequired,
  setQueAns: func.isRequired,
  clearMyReport: func.isRequired,
  closePopup: func,
};

export default MyexamAnswer;
