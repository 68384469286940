import React from 'react';
import { array, func } from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router';
const statusColor = {
  PENDING: '#FD2A5B',
  COMPLETED: '#43AA8B',
};
const backGroundClr = {
  PENDING: '#FFEAF1',
  COMPLETED: '#e3fff6',
};
const AssignmentsCommon = ({
  assignmentList,
  setAssignment,
  setAssignmentId,
}) => {
  const history = useHistory();
  const showReport = (id) => {
    setAssignment(true);
    setAssignmentId(id);
  
  };
  return assignmentList
    .sort((a, b) => b.startedOn.localeCompare(a.startedOn))
    .map((assign) => (
      <div className='col-4 col-sm-12 col-md-4 col-lg-4'>
        <div className='assignment-common'>
          <h3>
            {assign.subjectName}
            <span
              className='status assignStatus d-flex align-items-center justify-content-center'
              style={{
                color: statusColor[assign.status],
                backgroundColor: backGroundClr[assign.status],
                border: `1px solid ${statusColor[assign.status]}`,
              }}
            >
              {assign.status}
            </span>
          </h3>
          <p className='desc'>{assign.subconceptName}</p>
          <h5>Submission date</h5>
          <p className='date'>
            {moment(assign.dueOn).format('MMM Do, YYYY')}
            {assign.completedOn !== null ? (
              <span>
                <button
                  type='button'
                  onClick={() => {showReport(assign._id);
                    history.push({
                      pathname: '/teacher/students',
                      search: '?grades=true&&viewassignment=true',
                    });
                  }}
                  style={{ color: '#79A2E7' }}
                >
                  View assignment
                </button>
              </span>
            ) : (
              ''
            )}
          </p>
        </div>
      </div>
    ));
};

AssignmentsCommon.propTypes = {
  assignmentList: array.isRequired,
  setAssignment: func.isRequired,
  setAssignmentId: func.isRequired,
};

export default AssignmentsCommon;
