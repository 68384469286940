import React from 'react';
import { array, func, string } from 'prop-types';
import { checkIcon } from '../../../../../../assets/icons/IconList';
import { renderText } from '../../BrainGym/QueAnswer/textHelper';

const MultiSelectOptions = ({ item, ind, handleSelect, check }) => {
  return (
    <div
      className={`option-wrapper-tile-type d-flex align-items-center justify-content-between p-4
                 ${check ? 'true' : ''}`}
    >
      <button
        type='button'
        className='optionsbtn'
        onClick={() => handleSelect(ind)}
      >
        <span className='opti'>{String.fromCharCode(ind + 65)}. </span>
        {item?.imageElement === null && (
          <div className='questionHtlm-right'>
            <span>{renderText(item?.text)} </span>
          </div>
        )}
        {item?.imageElement !== '' && (
          <div
            className={
              item?.image !== ''
                ? 'questionHtlm-left'
                : 'questionHtlm-right-imageElement'
            }
          >
            <span>{renderText(item?.imageElement)}</span>
          </div>
        )}
        <div className='icon--check-wrapper d-flex align-items-center justify-content-center'>
          {checkIcon}
        </div>
      </button>
    </div>
  );
};

MultiSelectOptions.propTypes = {
  item: array.isRequired,
  ind: string.isRequired,
  selectedOption: array.isRequired,
  handleSelect: func.isRequired,
};

export default MultiSelectOptions;
