import React, { Component } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import About from '../../../assets/icons/about-us.svg';
import Contact from '../../../assets/icons/contactusicon.svg';
import Parent from '../../../assets/icons/parenticon.svg';
import Teacher from '../../../assets/icons/teachersideicon.svg';
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
function MobMenu() {
  return (
    <div>
      <Menu mode='vertical'>
        <Menu.Item key='about' className='d-flex'>
          <Link className='header_main_style' to={'/aboutus'}>
            <img className='about-us' src={About} />
            <span> About Us</span>
          </Link>
        </Menu.Item>

        <Menu.Item key='contactus'>
          <Link className='header_main_style' to={'/contactus'}>
            <img className='about-us' src={Contact} />
            <span>Contact Us</span>
          </Link>
        </Menu.Item>

        <Menu.Item key='parents'>
          <Link className='header_main_style' to={'/parentaboutus'}>
            <img className='about-us' src={Parent} />
            <span>Parents</span>
          </Link>
        </Menu.Item>

        <Menu.Item key='teacher'>
          <Link className='header_main_style' to={'/teacherAboutUs'}>
            <img className='about-us' src={Teacher} />
            <span>Teachers</span>
          </Link>
        </Menu.Item>

        <Menu.Item key='teacher'>
          <Link className='header_main_style' to={'/schools'}>
            <img className='about-us' src={Teacher} />
            <span>Schools</span>
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default MobMenu;
