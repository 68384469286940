import React from 'react';
import './faqs.scss';
import Layout from '../../../Layout/Layout';
import Tautmorequestions from '../home/Tautmorequestions';
import Faqbanner from './Faqbanner';
import Subjectlist from '../home/Subjectlist';


const Faqs = () => {
  return (
     <Layout>
        <Subjectlist />
        <Faqbanner />
        <div className='mx-md-3'>
        <Tautmorequestions />   
        </div>
     </Layout>
  )
}

export default Faqs
