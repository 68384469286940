import React from 'react';

import Spinner from 'react-bootstrap/Spinner';

const Loading = ({ variant }) => (
  <div className='text-center success-part'>
    <div className='timer-loader'>
      <Spinner animation='border' variant={variant ? variant : 'success'} />
    </div>
  </div>
);
export default Loading;
