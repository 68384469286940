import React, { useEffect, useState } from 'react';
import { array, func, string } from 'prop-types';
import { renderText } from './BrainGym/QueAnswer/textHelper';
import {
  findKeyByValue,
  checkDragDropSnunscramble,
  structureDragAndDrop,
} from './BrainGym/QueAnswer/questionHelper';
import { checkIcon } from '../../../../assets/icons/IconList';

const MyExamReportOptions = ({ questionObject, item, ind, selectedOption }) => {
  const [dragData, setDragData] = useState([]);
  useEffect(() => {
    if (
      checkDragDropSnunscramble(findKeyByValue(questionObject?.solutionType))
    ) {
      setDragData(
        structureDragAndDrop(
          questionObject?.statement,
          questionObject?.options,
          questionObject?.solutionIndex
        )
      );
    }
  }, [questionObject]);

  const questionType = findKeyByValue(questionObject?.solutionType);

  let renderAnswer = null;
  switch (questionType) {
    case 'drag-and-drop':
      renderAnswer = (
        <table className='drag-detail-table'>
          <thead>
            <th>Statement</th>
            <th>Options</th>
          </thead>
          <tbody>
            {dragData?.length > 0 &&
              dragData?.map((option) => (
                <tr>
                  <td>
                    {option?.image ? (
                      <img src={option?.image} alt='option_image' />
                    ) : (
                      <span>{option?.text}</span>
                    )}
                  </td>
                  <td>
                    {option?.statementImage ? (
                      <img src={option?.statementImage} alt='option_image' />
                    ) : (
                      <span>{option?.statementText}</span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      );
      break;
    case 'match-the-following':
      renderAnswer = (
        <table className='drag-detail-table'>
          <thead>
            <th>Statement</th>
            <th>Options</th>
          </thead>
          <tbody>
            {dragData?.length > 0 &&
              dragData?.map((option) => (
                <tr>
                  <td>
                    {option?.image ? (
                      <img src={option?.image} alt='option_image' />
                    ) : (
                      <span>{option?.text}</span>
                    )}
                  </td>
                  <td>
                    {option?.statementImage ? (
                      <img src={option?.statementImage} alt='option_image' />
                    ) : (
                      <span>{option?.statementText}</span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      );
      break;
    case 'select-the-word-to-match-the-correct-option':
      renderAnswer = (
        <table className='drag-detail-table'>
          <thead>
            <th>Statement</th>
            <th>Options</th>
          </thead>
          <tbody>
            {questionObject?.statement?.length > 0 &&
              questionObject?.statement?.map((option, key) => (
                <tr>
                  <td className='left-part'>
                    <div className='vertical-cell'>
                      <span className='opti'>
                        {String.fromCharCode(key + 65)}.
                      </span>
                      {option?.text && (
                        <span className='vertical-text'>{option?.text}</span>
                      )}
                      {option?.image !== '' && (
                        <img src={option?.image} alt='option_image' />
                      )}
                    </div>
                  </td>
                  <td className='right-part'>
                    <div className='vertical-cell'>
                      <span className='opti'>{key + 1}. </span>
                      <span className='right-part-val'>
                        <span className='right-part-val-common'>
                          {questionObject?.options?.filter(
                            (op) => op?.index == option?.index
                          )?.[0]?.image && (
                            <img
                              src={
                                questionObject?.options?.filter(
                                  (op) => op?.index == option?.index
                                )?.[0]?.image
                              }
                              alt='option_image'
                            />
                          )}
                          <div>
                            {renderText(
                              questionObject?.options?.filter(
                                (op) => op?.index == option?.index
                              )?.[0]?.text
                            )}
                          </div>
                        </span>
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      );
      break;
    default:
      renderAnswer = (
        <div
          className={`option-wrapper-tile-type d-flex align-items-center
                 justify-content-between p-4
                     ${selectedOption?.includes(ind) ? 'true' : ''}
                    `}
        >
          <div
            className='optionsbtn d-flex align-items-center
                justify-content-between'
          >
            {String.fromCharCode(ind + 65)} <span className='opti'> . </span>
            {item?.image && (
              <div className='questionHtlm-left'>
                <img src={item?.image} alt='option_image' />
              </div>
            )}
            {item?.text !== '' && (
              <div className='questionHtlm-right'>
                <span>{renderText(item?.text)}</span>
              </div>
            )}
            <div
              className='icon--check-wrapper d-flex align-items-center
                 justify-content-center'
            >
              {checkIcon}
            </div>
          </div>
        </div>
      );
  }
  return renderAnswer;
};

MyExamReportOptions.propTypes = {
  item: array.isRequired,
  ind: array.isRequired,
  selectedOption: string.isRequired,
  setSelectedOption: func.isRequired,
};

export default MyExamReportOptions;
