import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { func, bool, string, array, object } from 'prop-types';
import '../../teachers.scss';
import Select from 'react-select';
import {
  getProfileAction,
  getChapterBySubjectAction,
  getConceptByChapterAction,
  getSubconceptByConceptAction,
  addTeachingHistoryAction,
} from '../../../../../stores/TeacherDashboard/TeacherDashboardAction';
import { chevRight } from '../../../../../assets/icons/IconList';
import { useHistory } from 'react-router';
const AddHistoryPopUp = ({
  model,
  handleModel,
  myProfile,
  getMyProfile,
  latestClass,
  chapterBySubject,
  conceptByChapter,
  subconceptByConcept,
  getChapterBySubject,
  getConceptByChapter,
  getSubconceptByConcept,
  addTeachingHistory,
  selectedChapter,
  setSelectedChapter,
  selectedConcept,
  setSelectedConcept,
  selectedSubConcept,
  setSelectedSubConcept,
  setEnableJoin,
  setResetPage,
  teacherHistoryData,
}) => {
  useEffect(() => {
    if (myProfile.length === 0) {
      getMyProfile();
    }
  }, [myProfile]);

  const customCssConfig = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#43AA8B' : '#20282A',
      backgroundColor: state.isSelected ? '#E3FFF6' : 'white',
      padding: '13px 15px',
      fontSize: 20,
      marginTop: -5,
      ':hover': {
        backgroundColor: '#43AA8B',
        color: 'white',
      },
    }),

    input: (provided) => ({
      ...provided,
      fontSize: 20,
    }),

    menu: (provided) => ({
      ...provided,
      borderRadius: 10,
      width: '102%',
      left: '-1%',
    }),

    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,

      color: '#7D8283',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'grey',
    }),
  };

  const [selectedChapterId, setSelectedChapterId] = useState('');
  const [chapterListBySubject, setChapterListBySubject] = useState([
    { value: '', id: '' },
  ]);

  useEffect(() => {
    if (latestClass?.subjectInfo._id) {
      getChapterBySubject({
        subject_id: latestClass?.subjectInfo._id,
        moduleType: 'practice-test',
      });
    }
  }, [latestClass?.subjectInfo._id]);

  const scheduleId = latestClass?.scheduleInfo?._id;

  useEffect(() => {
    const cData = chapterBySubject?.data?.map((item) => ({
      value: item.name,
      label: item.name,
      id: item._id,
    }));

    setChapterListBySubject(cData);
  }, [chapterBySubject]);

  const [selectedConceptId, setSelectedConceptId] = useState('');
  const [conceptListByChapter, setConceptListbyChapter] = useState([
    { value: '', id: '' },
  ]);
  const history = useHistory();

  useEffect(() => {
    if (selectedChapterId)
      getConceptByChapter({ chapterId: selectedChapterId });
  }, [selectedChapterId]);

  useEffect(() => {
    const cData2 = conceptByChapter?.data?.map((item) => ({
      value: item.name,
      label: item.name,
      id: item._id,
    }));

    setConceptListbyChapter(cData2);
  }, [conceptByChapter]);
  const [subConceptList, setSubConceptList] = useState([{ value: '', id: '' }]);
  const [selectedSubConceptId, setSelectedSubConceptId] = useState('');

  useEffect(() => {
    if (selectedConceptId)
      getSubconceptByConcept({ conceptId: selectedConceptId });
  }, [selectedConceptId]);

  useEffect(() => {
    const cData2 = subconceptByConcept?.data?.map((item) => ({
      value: item.name,
      label: item.name,
      id: item._id,
    }));

    setSubConceptList(cData2);
  }, [subconceptByConcept]);

  const modelHide = () => {
    handleModel(false);
  };

  useEffect(() => {
    if (teacherHistoryData?.statusCode === 200 && teacherHistoryData?.data) {
      setEnableJoin(true);
      modelHide();
      setResetPage(true);
    }
  }, [teacherHistoryData]);

  const historyData = {
    schedule: scheduleId,
    chapter: selectedChapterId,
    chapterName: selectedChapter,
    concept: selectedConceptId,
    conceptName: selectedConcept,
    subConcept: selectedSubConceptId,
    subConceptName: selectedSubConcept,
  };

  const onAddDetailsClick = () => {
    addTeachingHistory(historyData);
  };

  return (
    <div className='modal-div'>
      <Modal
        show={model}
        onHide={() => {
          modelHide();
          setSelectedChapter('');
          setSelectedConcept('');
          setSelectedSubConcept('');
          history.push('/teacher/classes');
        }}
        className='add-history-modal'
        scrollable
      >
        <Modal.Header closeButton>
          <div>
            <h2 className='text-dark'>Meeting Details</h2>
            <p className='font-color'>
              Please select the following details prior to the meeting
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='label-div font-styles'>
            Which chapter are you going to teach today*
          </div>
          <Select
            id='chapters'
            name='chapter'
            className='border-color-none'
            placeholder='Select chapter'
            options={chapterListBySubject}
            value={selectedChapter.label}
            styles={customCssConfig}
            onChange={(value) => {
              setSelectedChapter(value.label);
              setSelectedChapterId(value.id);
            }}
            style={{ marginBottom: 10 }}
          />

          <div className='label-div font-styles mt-3'>
            Select a concept in selected chapter*
          </div>
          <Select
            id='chapters'
            name='chapter'
            placeholder='Select concept'
            options={conceptListByChapter}
            value={selectedConcept.label}
            styles={customCssConfig}
            onChange={(value) => {
              setSelectedConcept(value.label);
              setSelectedConceptId(value.id);
            }}
            style={{ display: 'none' }}
            isDisabled={selectedChapter ? false : true}
          />

          <div className='label-div font-styles mt-3'>
            Select a subtopic for the selected topic*
          </div>

          <Select
            id='chapters'
            name='chapter'
            placeholder='Select sub-concept'
            options={subConceptList}
            value={selectedSubConcept.label}
            styles={customCssConfig}
            onChange={(value) => {
              setSelectedSubConcept(value.label);
              setSelectedSubConceptId(value.id);
            }}
            isDisabled={selectedConcept ? false : true}
          />

          <div className='d-flex justify-content-center'>
            <button
              type='submit'
              onClick={() => onAddDetailsClick()}
              className='button-common2'
              disabled={
                !(selectedConcept && selectedChapter && selectedSubConcept)
              }
            >
              Click to Submit
              {/* <span>{chevRight}</span> */}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

AddHistoryPopUp.propTypes = {
  model: bool.isRequired,
  handleModel: func.isRequired,
  myProfile: array.isRequired,
  getMyProfile: func.isRequired,
  latestClass: object.isRequired,
  chapterBySubject: object.isRequired,
  conceptByChapter: object.isRequired,
  subconceptByConcept: object.isRequired,
  getChapterBySubject: func.isRequired,
  getConceptByChapter: func.isRequired,
  getSubconceptByConcept: func.isRequired,
  addTeachingHistory: func.isRequired,
  selectedChapter: string.isRequired,
  setSelectedChapter: func.isRequired,
  selectedConcept: string.isRequired,
  setSelectedConcept: func.isRequired,
  selectedSubConcept: string.isRequired,
  setSelectedSubConcept: func.isRequired,
  setEnableJoin: bool.isRequired,
};

const mapStateToProps = (state) => ({
  myProfile: state.TeacherDashboard.getProfile,
  chapterBySubject: state.TeacherDashboard.chapterBySubject,
  conceptByChapter: state.TeacherDashboard.conceptByChapter,
  subconceptByConcept: state.TeacherDashboard.subconceptByConcept,
  teacherHistoryData: state.TeacherDashboard.teachingHistory,
});

const mapDispatchToProps = (dispatch) => ({
  getMyProfile: () => dispatch(getProfileAction()),
  getChapterBySubject: (data) => dispatch(getChapterBySubjectAction(data)),
  getConceptByChapter: (data) => dispatch(getConceptByChapterAction(data)),
  getSubconceptByConcept: (data) =>
    dispatch(getSubconceptByConceptAction(data)),
  addTeachingHistory: (data) => dispatch(addTeachingHistoryAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddHistoryPopUp);
