import { fork, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './sullabusTypes';
import { authServices } from '../../services/auth.services';

function* workerGetAllCountryList() {
  const response = yield authServices.getOpenPracticeCountryList();
  if (response) {
    yield put({
      type: actionTypes.UPDATE_ALL_COUNTRY_LIST,
      payload: response,
    });
  }
}

function* workerGetCountryBasedOnIp() {
  const state = yield select();
  const ipCountryData = state.Syllabus.ipCountryData;
  if (!ipCountryData) {
    const response = yield authServices.getLocationByIp();
    if (response) {
      localStorage.setItem('ipLocation', JSON.stringify(response));
      yield put({
        type: actionTypes.UPDATE_COUNTRY_BASED_ON_IP,
        payload: response,
      });
    }
  }
}

function* watcherSyllabus() {
  yield takeLatest(actionTypes.GET_ALL_COUNTRY_LIST, workerGetAllCountryList);
  yield takeLatest(
    actionTypes.GET_COUNTRY_BASED_ON_IP,
    workerGetCountryBasedOnIp
  );
}

function* fetchAll() {
  yield fork(watcherSyllabus);
}

export default fetchAll();
