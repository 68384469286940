import { fork, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './BrainGymTypes';
import { brainGymServices } from '../../services';

function* workerStartChest(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const auth = state.Auth;
  const reqData = {
    studentId: auth?.Login?.data?._id,
    subjectId: data?.payload,
  };

  const response = yield brainGymServices.startTest(reqData, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_START_CHEST,
      payload: response,
    });
  }
}

function* workerGetQuestionInChest(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const value = data?.payload;
  const response = yield brainGymServices.getQuestionInChest(value, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_QUESTION_IN_CHEST,
      payload: response,
    });
  }
  if (
    response.message === 'COMPLETED' ||
    response.message === 'QUESTION_EXHAUSTED'
  ) {
    const { chests } = state?.BrainGym;
    if (chests?.chests?.length > 0) {
      yield put({
        type: actionTypes.COMPLETE_CHEST,
        payload: {
          chestId: chests?.chests?.find((data) => data.status !== 'COMPLETED')
            ?._id,
        },
      });
    }
  }
}
function* workerGetChestInfo(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield brainGymServices.chestInfo(value, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_CHEST_INFO,
      payload: response,
    });
  }
}

function* workerAttemptQuestion(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield brainGymServices.attemptQuestion(value, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_ATTEMPT_QUESTION,
      payload: response,
    });
  }
}
function* workerGetAllShell(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield brainGymServices.masterDetails(value, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_ALL_SHELL,
      payload: response,
    });
  }
}
function* workerGetIncorrectAns(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield brainGymServices.incorrectAnswer(value, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_INCORRECT_ANSWER,
      payload: response,
    });
  }
}
function* workerCompleteChest(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield brainGymServices.completeChest(value, token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_COMPLETE_CHEST,
      payload: response,
    });
  }
}
function* workergetLastFiveChest(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  try {
    const response = yield brainGymServices.lastFiveAttempts(
      {
        ...data.payload,
      },
      token
    );
    if (response.status === 'success') {
      yield put({
        type: actionTypes.UPDATE_GET_LAST_FIVE_CHEST,
        payload: response,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* workergetLongestSTreak(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  try {
    const response = yield brainGymServices.longestStreak(
      {
        ...data.payload,
      },
      token
    );
    if (response.status === 'success') {
      yield put({
        type: actionTypes.UPDATE_LONGEST_STREAK,
        payload: response,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* watcherBrainGym() {
  yield takeLatest(actionTypes.START_CHEST, workerStartChest);
  yield takeLatest(actionTypes.GET_QUESTION_IN_CHEST, workerGetQuestionInChest);
  yield takeLatest(actionTypes.ATTEMPT_QUESTION, workerAttemptQuestion);
  yield takeLatest(actionTypes.COMPLETE_CHEST, workerCompleteChest);
  yield takeLatest(actionTypes.GET_CHEST_INFO, workerGetChestInfo);
  yield takeLatest(actionTypes.GET_ALL_SHELL, workerGetAllShell);
  yield takeLatest(actionTypes.GET_INCORRECT_ANSWER, workerGetIncorrectAns);
  yield takeLatest(actionTypes.GET_LAST_FIVE_CHEST, workergetLastFiveChest);
  yield takeLatest(actionTypes.GET_LONGEST_STREAK, workergetLongestSTreak);
}

function* fetchAll() {
  yield fork(watcherBrainGym);
}

export default fetchAll();
