import { func, array } from 'prop-types';

import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { chevRight, chevLeft } from '../../../../../assets/icons/IconList';

const prevIcon = (
  <div className='col-md-2 col-sm-2 col-2'>
    <div className='icon '>{chevLeft}</div>
  </div>
);

const nextIcon = (
  <div className='col-md-2 col-sm-2 col-2'>
    <div className='icon '>{chevRight}</div>
  </div>
);

const Mysubjectcounttop = ({ concepts, setConceptsData }) => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    setConceptsData(concepts[selectedIndex].index);
  };
  return (
    <div className='row mysubject-count-top'>
      <div className='col-md-12 col-sm-12 col-12 text-center'>
        <span className='chapterName'>chapters</span>

        <Carousel
          nextIcon={nextIcon}
          prevIcon={prevIcon}
          interval={null}
          activeIndex={index}
          onSelect={handleSelect}
        >
          {concepts.map((data) => (
            <Carousel.Item className='innerItem'>
              <span>
                {data.index}. {data.chapterName}
              </span>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

Mysubjectcounttop.propTypes = {
  concepts: array.isRequired,
  setConceptsData: func.isRequired,
};

export default Mysubjectcounttop;
