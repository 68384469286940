export const MYASSIGNMENTS_UPCOMING_EXAM = 'MYASSIGNMENTS_UPCOMING_EXAM';
export const UPDATE_MYASSIGNMENTS_UPCOMING_EXAM = 'UPDATE_MYASSIGNMENTS_UPCOMING_EXAM';
export const PREVIOUS_MYASSIGNMENTS_EXAM = 'PREVIOUS_MYASSIGNMENTS_EXAM';
export const UNATTEEMPTED_MYASSIGNMENTS_EXAM = 'UNATTEEMPTED_MYASSIGNMENTS_EXAM';
export const UPDATE_PREVIOUS_MYASSIGNMENTS_EXAM = 'UPDATE_PREVIOUS_MYASSIGNMENTS_EXAM';
export const START_MYASSIGNMENTS_EXAM = 'START_MYASSIGNMENTS_EXAM';
export const UPDATE_START_MYASSIGNMENTS_EXAM = 'UPDATE_START_MYASSIGNMENTS_EXAM';
export const FINISH_MYASSIGNMENTS_EXAM = 'FINISH_MYASSIGNMENTS_EXAM';
export const UPDATE_FINISH_MYASSIGNMENTS_EXAM = 'UPDATE_FINISH_MYASSIGNMENTS_EXAM';

export const GET_QUESTION_IN_MYASSIGNMENTS_EXAM = 'GET_QUESTION_IN_MYASSIGNMENTS_EXAM';
export const UPDATE_GET_QUESTION_IN_MYASSIGNMENTS_EXAM =
  'UPDATE_GET_QUESTION_IN_MYASSIGNMENTS_EXAM';
export const GET_MY_MYASSIGNMENTS_REPORT = 'GET_MY_MYASSIGNMENTS_REPORT';
export const UPDATE_MY_MYASSIGNMENTS_REPORT = 'UPDATE_MY_MYASSIGNMENTS_REPORT';
export const MY_MYASSIGNMENTS_REPORT_ANALYTICS = 'MY_MYASSIGNMENTS_REPORT_ANALYTICS';
export const UPDATE_MY_MYASSIGNMENTS_REPORT_ANALYTICS =
  'UPDATE_MY_MYASSIGNMENTS_REPORT_ANALYTICS';
export const ALL_MYASSIGNMENTS_QUESTION_ANSWER = 'ALL_MYASSIGNMENTS_QUESTION_ANSWER';
export const UPDATE_ALL_MYASSIGNMENTS_QUESTION_ANSWER =
  'UPDATE_ALL_MYASSIGNMENTS_QUESTION_ANSWER';
export const ADD_ALL_MYASSIGNMENTS_QUESTION_ANSWER =
  'ADD_ALL_MYASSIGNMENTS_QUESTION_ANSWER';
  export const VIEW_MY_MYASSIGNMENTS_EXAM_REPORT =
  'VIEW_MY_MYASSIGNMENTS_EXAM_REPORT';
  export const CLEAR_FINISH_MYASSIGNMENTS_EXAM =
  'CLEAR_FINISH_MYASSIGNMENTS_EXAM';
  export const CLEAR_QUESTION_IN_MYASSIGNMENTS_EXAM =
  'CLEAR_QUESTION_IN_MYASSIGNMENTS_EXAM';



  
  
