import React from 'react';
import { string } from 'prop-types';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './temp.scss';

const MyconceptHead = ({
  initailSubName,
  chapterName,
  conceptName,
  subConName,
  subConceptsList,
  handleChange,
  subConcData,
  setQuestionsData,
}) => (
  <div className='myconcept-head-main'>
    <div className='myconcept-head-top'>
      <Breadcrumb onClick={() => setQuestionsData('')}>
        <Breadcrumb.Item>{initailSubName}</Breadcrumb.Item>
        <Breadcrumb.Item>{chapterName}</Breadcrumb.Item>
        <Breadcrumb.Item>{conceptName}</Breadcrumb.Item>

        <Breadcrumb.Item active>{subConName}</Breadcrumb.Item>
        {/* )
                } */}
      </Breadcrumb>
    </div>

    <div className='myconcept-head-bottom'>
      <div>
        <div className='d-flex justify-content-between'>
          <div className='col-md-9'>
            <h4>{conceptName}</h4>
          </div>
        </div>
        <div></div>
        {subConceptsList?.subconcepts?.map((sublist) => (
          <li
            className={`${
              subConName == sublist?.subconceptName ? 'head-li-active' : ''
            }`}
            role='button'
            tabIndex={0}
            onClick={() =>
              handleChange(
                sublist.subconcept,
                subConceptsList.concept,
                subConceptsList.conceptName,
                sublist.subconceptName,
                subConceptsList?.subconcepts
              )
            }
          >
            {sublist.subconceptName}
          </li>
        ))}
      </div>
    </div>
  </div>
);

MyconceptHead.propTypes = {
  initailSubName: string.isRequired,
  chapterName: string.isRequired,
  conceptName: string.isRequired,
  subConName: string.isRequired,
};

export default MyconceptHead;
