import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../../assets/tempimages/Logo.svg';
import SidebarLogo from '../../../assets/icons/sidebarlogo.svg';
import IstemLogo from '../../../assets/images/istemLogo.svg';
import { Menu, Icon, Space } from 'antd';
import './header.scss';
import MobMenu from './MobMenu';
import { Drawer, Button } from 'antd';
import ham from '../../../assets/icons/hamburger.svg';
import Playstore from '../../../assets/icons/playstoreicon.svg';
import AppleStore from '../../../assets/icons/appleicon.svg';
import LeadCaptureForm from '../../../Layout/leadCaptureForm';
import 'antd/dist/antd.css';
const MobileHeader = () => {
  const history = useHistory();
  const isMobile =
    window.innerWidth > 0
      ? window.innerWidth <= '1024'
      : screen.width <= '1024';
  const [visible, setVisible] = useState(false);
  const [showLeadCaptureForm, setShowLeadCaptureForm] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };

  const handleMobile = () => {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width <= '1024') {
      // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      setShowLeadCaptureForm(true);
    } else {
      history.push('/newregister');
    }
  };

  const antdDrawer = (
    <div className='d-flex justify-content-center '>
      <Button
        type='button'
        className='mob-login-signin '
        onClick={handleMobile}
      >
        {isMobile ? 'Signup' : '  Login/Signup'}
      </Button>
    </div>
  );

  const onClose = () => {
    setVisible(false);
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // fade: true,
    // centerMode: true,
    // vertical: true,
    // verticalSwiping: true,
    arrows: false,
  };

  return (
    <div className='mob_header'>
      {/* <div className=' custom-container m-auto'> */}
      {/* <div className='w-100 '> */}
      <div className='col-sm-12 container_flex'>
        <div className='col-lg-6  mob_header-left '>
          <Link to='/'>
            <img src={Logo} alt='website_log' />
          </Link>

          <div className='istem-logo'>
            <Slider {...settings}>
              <div>
                <img
                  src={IstemLogo}
                  className='silder_img '
                  alt='website_log'
                />
              </div>

              <div>
                <img
                  src={IstemLogo}
                  className='silder_img '
                  alt='website_log'
                />
              </div>
            </Slider>
          </div>
          {/* <div>
              <MobMenu />
            </div> */}
        </div>
        <div className='col-lg-6 mob_header-right'>
          <div className='pr-1 '>
            <a href='https://play.google.com/store/apps/details?id=com.tautmore'>
              <img className='playstore_img' src={Playstore} alt='playStore' />
            </a>
          </div>
          <div className='p-2'>
            <a href='https://apps.apple.com/us/app/tautmore/id1612243202'>
              <img src={AppleStore} alt='App Store' />
            </a>
          </div>

          <div className=''>
            <div className='ham_icon ' onClick={showDrawer}>
              <img src={ham} alt='ham' />
            </div>
            <Drawer
              className='side-bar-dr'
              closeIcon={false}
              width={isMobile ? '70%' : '50%'}
              //   contentWrapperStyle={{ width: '50%' }}
              //   title='Basic Drawer
              placement='right'
              closable={true}
              onClose={onClose}
              extra={
                <Space className='ant-space'>
                  <Link to='/'>
                    <img
                      className='side_bar_logo'
                      src={SidebarLogo}
                      alt='website_log'
                    />
                  </Link>
                </Space>
              }
              open={visible}
              footer={antdDrawer}
            >
              <MobMenu />
            </Drawer>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}

      {showLeadCaptureForm ? (
        <LeadCaptureForm
          modal={showLeadCaptureForm}
          setModal={setShowLeadCaptureForm}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default MobileHeader;
