import config from '../helpers/config';
import { fetchWrapper } from '../helpers/fetch-wrapper';
import http from '../helpers/Axios';

const baseUrl = `${config.apiBaseUrl}`;

function startAssignment(data, token) {
  if (data.trial === 'firstTry') {
    return fetchWrapper.post(
      `${baseUrl}/assignment/students/start-assignment`,
      { assignment: data.assignment },
      token
    );
  }
  return fetchWrapper.post(`${baseUrl}/assignment/students/retry-assignment`, {
    assignment: data.assignment,
    token,
  });
}

function questionsInAssignment(params, token) {
  return fetchWrapper.get(
    `${baseUrl}/assignment/students/questions-in-exam?assignment=${params.assignment}`,
    token
  );
}

function finishAssignment(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/assignment/students/complete-assignment`,
    params,
    token
  );
}

const getStudentAssignmentList = async (params) =>
  http.get('/assignment/students/pending-assignments', { params });

const getHistoryAssignments = async (params) =>
  http.get('/assignment/students/assignment-history', { params });

const intelliReport = async (params) =>
  http.get(`/assignment/students/my-report?assignment=${params.assignment}`);

const viewAssignment = async (data) =>
  http.get(
    `/assignment/teachers/view-student-assignment?assignment=${data.assignment}`
  );

const getUnattemptedAssignments = async (params) =>
  http.get(`/assignment/students/unattempted-assignment-history`, { params });

export const studentAssignmentService = {
  startAssignment,
  questionsInAssignment,
  finishAssignment,
  getStudentAssignmentList,
  getHistoryAssignments,
  intelliReport,
  viewAssignment,
  getUnattemptedAssignments,
};

export default {};
