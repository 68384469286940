import { bool, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

const ExamSidebar = ({
  questionInExamData,
  selectedQuestion,
  setSelectedQuestion,
  allQuesAns,
  isOlympiad,
}) => {
  const [isReasoningExist, setIsReasoningExist] = useState(false);
  const [distinctSubjects, setDistinctSubjects] = useState([]);
  const [subjectIndex, setSubjectIndex] = useState(0);

  useEffect(() => {
    let newIsReasoningExist = false;
    let newDistinctSub = [];
    let newSubIndex = 0;
    newIsReasoningExist = questionInExamData?.some(
      (each) => each?.subjectName == 'Reasoning'
    );
    if (newIsReasoningExist) {
      questionInExamData.map((each) => {
        !newDistinctSub.includes(each.subjectName) &&
          newDistinctSub.push(each.subjectName);
      });
      if (newDistinctSub.length == 2) {
        newSubIndex = questionInExamData?.findIndex(
          (e) => e.subjectName === newDistinctSub[1]
        );
        setIsReasoningExist(newIsReasoningExist);
        setDistinctSubjects(newDistinctSub);
        setSubjectIndex(newSubIndex);
      }
    }
  }, [questionInExamData]);

  return (
    <div className='olympiad-sidebar'>
      <ul>
        {questionInExamData &&
          questionInExamData.map((data, ind) => (
            <>
              {isOlympiad && isReasoningExist && distinctSubjects.length > 1 ? (
                ind === 0 ? (
                  <div>{distinctSubjects[0]}</div>
                ) : ind === subjectIndex ? (
                  <div>{distinctSubjects[1]}</div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              <li
                aria-hidden='true'
                key={data?._id}
                onClick={() => setSelectedQuestion(ind)}
                className={`${selectedQuestion === ind ? 'active' : ''}
                         ${
                           data.solutionType === '13'
                             ? allQuesAns[ind]?.fillInTheBlankSolution &&
                               allQuesAns[ind]?.fillInTheBlankSolution.length >
                                 0
                               ? 'answered'
                               : ''
                             : data.solutionType === '7'
                             ? allQuesAns[ind]?.passageQuestions &&
                               allQuesAns[ind]?.passageQuestions.some(
                                 (e) => e.solutionIndex[0] !== -1
                               )
                               ? 'answered'
                               : ''
                             : allQuesAns[ind]?.solutionIndex &&
                               !allQuesAns[ind]?.solutionIndex.includes(-1)
                             ? 'answered'
                             : ''
                         }`}
              >
                {`Questions ${ind + 1}`}
              </li>
            </>
          ))}
      </ul>
    </div>
  );
};
ExamSidebar.propTypes = {
  questionInExamData: object.isRequired,
  selectedQuestion: string.isRequired,
  setSelectedQuestion: func.isRequired,
  allQuesAns: object.isRequired,
  isOlympiad: bool,
};

export default ExamSidebar;
