import React, { useEffect, useRef, useState } from 'react';
import { object, func, array } from 'prop-types';
import { data } from './data';
import './style.scss';
import Subjectlist from '../home/Subjectlist';
import mathIcon from '../../../assets/images/Mathematics.svg';
import engIcon from '../../../assets/images/English.svg';
import physicsIcon from '../../../assets/images/Physics1.png';
import chemIcon from '../../../assets/images/Chemestry.svg';
import biologyIcon from '../../../assets/images/Biology.svg';
import scienceIcon from '../../../assets/images/Science.svg';
import Chapterslink from './Chapterslink';
import Topiclist from './Topiclist';
import Layout from '../../../Layout/Layout';
import { connect } from 'react-redux';
import {
  getAllGradesAction,
  getOpenPracticeCountryList,
  // getSyllabusHierarchy,
} from '../../../stores/Auth/AuthAction';
import { getSyllabusHierarchyWithoutLogin } from '../../../services/teacherAssignment';
import Spinner from 'react-bootstrap/Spinner';
import { useCookies } from 'react-cookie';
import { v4 as uuid } from 'uuid';
import SubjectData from '../home/mockData/SubjectlistData';
import DropdownCountry from './customdropdown/customDropdownStudent';
import config from '../../../helpers/config';
import {
  getCountries,
  getAllClassByCountry,
} from '../../../stores/Auth/AuthAction';

import { authServices } from '../../../services/auth.services';

import {
  getAllCountryList,
  updateSyllabus,
  getCountryBasedOnIP,
} from '../../../stores/Syllabus/syllabusActions';

import {} from '../../../stores/Syllabus/syllabusActions';
// import { each } from 'immer/dist/internal';

const Chapters = ({
  match,
  getAllGrades,
  allGrades,
  classListByCountry,
  getAllCountriesAction,
  countries,
  getAllClassByCountry,
  // getSyllabusData,
  // allSyllabus,

  newCountryList,
  getNewCountryList,

  syllabusData,
  updateSyllabus,

  getCountryBasedOnIP,
  ipCountryData,
}) => {
  const [viewMoreTopic, setViewMoreTopic] = useState(['', '']);
  const [descriptionAnchor, setDescriptionAnchor] = useState(['', '', '']);
  const [shouldToggleStyle, setShouldToggleStyle] = useState(false);
  const [subdata, setSubdata] = useState();
  const [selectedSubject, setSelectedSubject] = useState();
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  const [gradeLoader, setGradeLoader] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState();

  const descRef = useRef(null);

  const [selectedCountry, setSelectedCountry] = useState('');
  const [countryList, setCountryList] = useState([
    {
      value: '',
      name: 'Fetching countries..',
      flag: `${config.adminImageUrl}/countryFlags/mc.svg`,
    },
  ]);

  useEffect(() => {
    if (!newCountryList || !ipCountryData) {
      getNewCountryList();
      getCountryBasedOnIP();
      setGradeLoader(true);
    } else {
      if (syllabusData?.country) {
        setSelectedCountry(syllabusData.country.find((each) => each.selected));
        setCountryList(syllabusData?.country);
      } else {
        if (newCountryList?.data?.length > 0) {
          let isCountryCodeExist = newCountryList?.data?.some(
            (eachData) => eachData.country_code === ipCountryData.country_code
          );
          const cdata = newCountryList?.data.map((data) => {
            if (
              (isCountryCodeExist &&
                data.country_code === ipCountryData.country_code) ||
              (!isCountryCodeExist && data.country_code === 'IN')
            ) {
              setSelectedCountry({
                id: data._id,
                name: data.country_label,
                label: data.country_label,
                code: data.country_code,
                flag: data.flag,
              });
              return {
                id: data._id,
                name: data.country_label,
                label: data.country_label,
                code: data.country_code,
                flag: data.flag,
                subject: SubjectData.map((each) => ({
                  title: each.title,
                  selected: each.title === match?.params?.subject,
                })),
                selected: true,
              };
            } else
              return {
                id: data._id,
                name: data.country_label,
                label: data.country_label,
                code: data.country_code,
                flag: data.flag,
                subject: SubjectData?.map((each) => ({
                  title: each.title,
                  selected: each.title === match?.params?.subject,
                })),
                selected: false,
              };
          });
          // setCountryList(cdata);
          updateSyllabus({ country: [...cdata] });
          setCountryList(cdata);
        }
      }
    }
  }, [ipCountryData, newCountryList]);

  // useEffect(() => {
  //   console.log('the neeeeeeewww countryList', newCountryList);
  // }, [newCountryList]);

  // useEffect(async () => {
  //   if (!countries?.data) {
  //     getAllCountriesAction();
  //     getNewCountryList();
  //     setGradeLoader(true);
  //   }
  //   if (countries?.data?.length > 0) {
  //     let response = await authServices.getLocationByIp();
  //     if (response) {
  //       let isCountryCodeExist = countries?.data?.some(
  //         (eachData) => eachData.country_code === response.country_code
  //       );
  //       const cdata = countries?.data.map((data) => {
  //         if (
  //           (isCountryCodeExist &&
  //             data.country_code === response.country_code) ||
  //           (!isCountryCodeExist && data.country_code === 'IN')
  //         ) {
  //           setSelectedCountry({
  //             id: data._id,
  //             name: data.country_label,
  //             label: data.country_label,
  //             code: data.country_code,
  //             flag: data.flag,
  //           });
  //           return {
  //             id: data._id,
  //             name: data.country_label,
  //             label: data.country_label,
  //             code: data.country_code,
  //             flag: data.flag,
  //           };
  //         } else
  //           return {
  //             id: data._id,
  //             name: data.country_label,
  //             label: data.country_label,
  //             code: data.country_code,
  //             flag: data.flag,
  //           };
  //       });
  //       setCountryList(cdata);
  //     } else {
  //       console.error('the country based on IP not working', response);
  //     }
  //   }
  // }, [countries]);

  useEffect(() => {
    //
    if (selectedCountry?.id) {
      let newSyllabus = { ...syllabusData };
      let updatingCountryIndex = newSyllabus?.country?.findIndex(
        (each) => each?.id === selectedCountry?.id
      );

      let updatingSubjectIndex = newSyllabus?.country[
        updatingCountryIndex
      ]?.subject?.findIndex((each) => each.title === subdata);

      const classList =
        newSyllabus?.country[updatingCountryIndex]?.subject[
          updatingSubjectIndex
        ]?.grade;

      if (classList) {
        if (classList?.length > 0) {
          setGradeValue(classList);
          const oldSelectedGradeIndex = classList.findIndex(
            (each) => each.selected
          );
          setSelectedLink({
            num: oldSelectedGradeIndex,
            class: classList[oldSelectedGradeIndex].value,
            label: classList[oldSelectedGradeIndex].label,
          });

          setSelectedGrade(classList[oldSelectedGradeIndex].label);
        } else {
          // else if (res.status === 404) {
          setSyllabus([]);
          // setLoader(false);
          setErrorMessage('No data found');
          // }
          setGradeValue('');
          setGradeLoader(false);

          // console.log('ERROR : classListByCountry ', classListByCountry);
          setSelectedGrade(null);
        }
      } else {
        setGradeLoader(true);
        setLoader(true);
        getAllClassByCountry({
          countryId: selectedCountry?.id,
          subject: subdata,
        });
      }
    }
  }, [selectedCountry, subdata]);

  const onCountrySelect = (val) => {
    setSelectedCountry({ ...val });
    let newSyllabus = { ...syllabusData };
    newSyllabus.country = newSyllabus?.country?.map((each) => ({
      ...each,
      selected: each.id === val.id,
    }));
    updateSyllabus(newSyllabus);
    // getAllStatesAction(val?.code);
  };

  useEffect(() => {
    const elStart = descRef.current?.getBoundingClientRect();
    setShouldToggleStyle(elStart?.right < window.innerWidth / 2 + 200);
  }, [descriptionAnchor, descRef]);

  useEffect(() => {
    const sub = match.params.subject;
    const selectedSubject = SubjectData.find(
      (subject) => subject.title === sub
    );
    if (syllabusData) {
      let newSyllabus = { ...syllabusData };
      let updatingCountryIndex = newSyllabus?.country?.findIndex(
        (each) => each?.selected
      );
      let allSubjects = newSyllabus?.country[
        updatingCountryIndex
      ]?.subject?.map((each) => ({ ...each, selected: each.title === sub }));
      newSyllabus.country[updatingCountryIndex].subject = allSubjects;
      updateSyllabus(newSyllabus);
    }

    selectedSubject.discription = `Here is a list of the ${
      selectedSubject.title
    } concepts${
      selectedGrade ? ' that ' + selectedGrade + '-graders acquire' : ''
    }. These concepts are categorised into micro level subtopics. Simply click on any link to begin practising, then enjoy responding to challenging inquiries while improving all your skills and learning techniques.`;

    // console.log('the selected subject', selectedSubject, sub);
    setSelectedSubject(selectedSubject);
    setSubdata(sub);
  }, [match.params.subject, selectedGrade]);

  const [gradeValue, setGradeValue] = useState();
  const [syllabus, setSyllabus] = useState([]);
  const [selectedLink, setSelectedLink] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [loader, setLoader] = useState(false);
  const colors = ['#7D93F2', '#F3722C', '#43AA8B'];
  const licolors = ['#F0F3FF', '#FFF4E9', '#EDFFFA'];
  const [liColours, setLiColours] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [uiInfo, setUiInfo] = useState(false);

  useEffect(() => {
    if (
      classListByCountry?.statusCode === 200 &&
      subdata &&
      selectedCountry?.id
    ) {
      setGradeLoader(false);
      if (classListByCountry?.data?.length > 0) {
        //const allGradeSorted;
        const gdata = classListByCountry?.data?.map((data, i) => ({
          value: data._id,
          label: data.name,
          selected: i === 0,
        }));

        let newSyllabus = { ...syllabusData };
        let updatingCountryIndex = newSyllabus?.country?.findIndex(
          (each) => each?.id === selectedCountry?.id
        );
        let updatingSubjectIndex = newSyllabus?.country[
          updatingCountryIndex
        ]?.subject?.findIndex((each) => each.title === subdata);

        newSyllabus.country[updatingCountryIndex].subject[
          updatingSubjectIndex
        ].grade = gdata;

        // console.log('newSyllabus', newSyllabus);

        updateSyllabus(newSyllabus);

        setGradeValue(gdata);
        setSelectedLink({
          num: 0,
          class: gdata[0].value,
          label: gdata[0].label,
        });

        setSelectedGrade(gdata[0].label);
      }
    } else {
      // else if (res.status === 404) {
      setSyllabus([]);
      // setLoader(false);
      setErrorMessage('No data found');
      // }
      setGradeValue('');
      setGradeLoader(false);
      // console.log('ERROR : classListByCountry ', classListByCountry);
      setSelectedGrade(null);
    }
  }, [classListByCountry]);

  // const SubjectData = [
  //   {
  //     title: 'English',
  //     image: engIcon,
  //     discription: `Here is a list of the *English* concepts that ${selectedGrade} acquire. These concepts are categorised into micro level subtopics. Simply click on any link to begin practising, then enjoy responding to challenging inquiries while improving all your skills and learning techniques.`,
  //     key: uuid(),
  //   },
  //   {
  //     title: 'Mathematics',
  //     image: mathIcon,
  //     discription: `Here is a list of the *Mathematics* concepts that ${selectedGrade} acquire. These concepts are categorised into micro level subtopics. Simply click on any link to begin practising, then enjoy responding to challenging inquiries while improving all your skills and learning techniques`,
  //     key: uuid(),
  //   },
  //   {
  //     title: 'Science',
  //     image: scienceIcon,
  //     discription:
  //       'Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tem por incididunt ut',
  //     key: uuid(),
  //   },
  //   {
  //     title: 'Physics',
  //     image: physicsIcon,
  //     discription:
  //       'Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tem por incididunt ut',
  //     key: uuid(),
  //   },
  //   {
  //     title: 'Chemistry',
  //     image: chemIcon,
  //     discription:
  //       'Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tem por incididunt ut',
  //     key: uuid(),
  //   },
  //   {
  //     title: 'Biology',
  //     image: biologyIcon,
  //     discription:
  //       'Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tem por incididunt ut',
  //     key: uuid(),
  //   },
  //   // {
  //   //   title: 'Brain-gym',
  //   //   image: brainIcon,
  //   //   key: uuid(),
  //   // },
  //   // {
  //   //   title: 'Co-curricular',
  //   //   image: coIcon,
  //   //   key: uuid(),
  //   // },
  //   // {
  //   //   title: 'Exams',
  //   //   image: examIcon,
  //   //   key: uuid(),
  //   // },
  // ];

  const getSyllabusData = async () => {
    let newSyllabus = { ...syllabusData };
    let updatingCountryIndex = newSyllabus?.country?.findIndex(
      (each) => each?.id === selectedCountry?.id
    );
    // console.log('updatingCountry', updatingCountryIndex);
    let updatingSubjectIndex = newSyllabus?.country[
      updatingCountryIndex
    ]?.subject?.findIndex((each) => each.title === subdata);
    // console.log('updatingSubject', updatingSubjectIndex);

    let updatingGradeIndex = newSyllabus?.country[
      updatingCountryIndex
    ]?.subject[updatingSubjectIndex]?.grade?.findIndex((each) => each.selected);
    // console.log('updatingGrade', updatingGradeIndex);
    const syll =
      newSyllabus?.country[updatingCountryIndex]?.subject[updatingSubjectIndex]
        ?.grade[updatingGradeIndex]?.syllabus;

    if (syll) {
      if (syll.length > 0) {
        setSyllabus(syll);
      }
      setErrorMessage('No syllabus found');
    } else {
      setLoader(true);
      try {
        const params = {
          class: selectedLink?.class,
          subjectName: subdata,
          country: selectedCountry?.id,
        };
        const res = await getSyllabusHierarchyWithoutLogin(params);
        if (res.status === 200) {
          setSyllabus(res?.data?.data?.chapters);

          newSyllabus.country[updatingCountryIndex].subject[
            updatingSubjectIndex
          ].grade[updatingGradeIndex].syllabus = res?.data?.data?.chapters;

          updateSyllabus(newSyllabus);

          if (!res?.data?.data?.chapters.length) {
            setErrorMessage('No syllabus found');
          }
          setLoader(false);
        }
      } catch (error) {
        if (error.response.status === 404) {
          setSyllabus([]);
          setLoader(false);
          setErrorMessage(error.response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    if (!cookies?.practice) {
      var tomorrow = new Date();
      var today = new Date();
      tomorrow.setDate(today?.getDate() + 1);
      setCookie('practice', uuid(), {
        path: '/',
        expires: tomorrow,
      });
    }
  }, []);

  useEffect(() => {
    if (selectedLink) {
      let newSyllabus = { ...syllabusData };
      let updatingCountryIndex = newSyllabus?.country?.findIndex(
        (each) => each?.id === selectedCountry?.id
      );
      let updatingSubjectIndex = newSyllabus?.country[
        updatingCountryIndex
      ]?.subject?.findIndex((each) => each.title === subdata);
      newSyllabus.country[updatingCountryIndex].subject[
        updatingSubjectIndex
      ].grade = newSyllabus?.country[updatingCountryIndex]?.subject[
        updatingSubjectIndex
      ]?.grade?.map((each) => ({
        ...each,
        selected: each.value === selectedLink?.class,
      }));
      updateSyllabus(newSyllabus);
      getSyllabusData();
    }
  }, [selectedLink, subdata]);

  const handleClick = (color) => {
    setSelectedColor(color);
  };

  const handleliColours = (color) => {
    setLiColours(color);
  };

  function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  const renderSubjectList = (syllabus) => {
    let chunkSize = Math.ceil(syllabus.length / 3);
    let renderList = sliceIntoChunks(syllabus, chunkSize);

    return renderList.map((EachChunk, ind) => (
      <div className='chapters-container'>
        {EachChunk.map((chapter, idx) => (
          <div
            style={
              selectedColor !== null
                ? { '--color': selectedColor }
                : { '--color': '#7D93F2' }
            }
            className='chapter-name'
            key={`Chapter-${idx + chunkSize * ind + 1}`}
          >
            <h2>{`Chapter ${idx + chunkSize * ind + 1} - ${
              chapter.chapterName
            }`}</h2>
            <ul>
              {chapter.concepts.map((topic, topicIdx) => (
                <Topiclist
                  topic={topic}
                  topicIdx={topicIdx}
                  viewMoreTopic={viewMoreTopic}
                  idx={idx + chunkSize * ind}
                  descriptionAnchor={descriptionAnchor}
                  setDescriptionAnchor={setDescriptionAnchor}
                  descRef={descRef}
                  shouldToggleStyle={shouldToggleStyle}
                  setViewMoreTopic={setViewMoreTopic}
                  key={`topic${topicIdx}`}
                  subject={match.params.subject}
                />
              ))}
            </ul>
          </div>
        ))}
      </div>
    ));
  };

  return (
    <Layout>
      <Subjectlist
        subdata={subdata}
        setSubdata={setSubdata}
        isChapterPage={true}
        setSelectedLink={setSelectedLink}
        gradeValue={gradeValue}
        setSelectedGrade={setSelectedGrade}
        selectedGrade={selectedGrade}
        handleClick={handleClick}
        colors={colors}
        selectedColor={selectedColor}
        handleliColours={handleliColours}
        setLiColours={setLiColours}
        liColours={liColours}
        licolors={licolors}
        setSelectedColor={setSelectedColor}
        // uiInfo={uiInfo}
        // setUiInfo={setUiInfo}
      />
      <div className='chapters-main-container p-0'>
        <div className='chapters-links'>
          <h1>Grade</h1>
          {gradeLoader ? (
            <div className='col-12 ' style={{ marginLeft: '100px' }}>
              <Spinner animation='border' variant='dark' />
            </div>
          ) : gradeValue ? (
            <Chapterslink
              gradeValue={gradeValue}
              selectedLink={selectedLink}
              setSelectedLink={setSelectedLink}
              setSelectedGrade={setSelectedGrade}
              handleClick={handleClick}
              colors={colors}
              selectedColor={selectedColor}
              handleliColours={handleliColours}
              setLiColours={setLiColours}
              liColours={liColours}
              licolors={licolors}
            />
          ) : (
            <ul className='links-list'>
              <li>
                <button>No Grades found</button>
              </li>
            </ul>
          )}
        </div>

        <div
          style={
            liColours === null
              ? { '--bg-color': '#F0F3FF' }
              : { '--bg-color': liColours }
          }
          className='chapters-details'
        >
          <div className='chapters-subject-header'>
            <h1>
              {/* {subdata === 'Mathematics' && <img src={mathIcon} alt='Math_img' />}
            {subdata === 'English' && <img src={engIcon} alt='English_img' />}
            {subdata === 'Physics' && (
              <img src={physicsIcon} alt='Physics_img' />
            )}
            {subdata === 'Chemistry' && (
              <img src={chemIcon} alt='Chemistry_img' />
            )}
            {subdata === 'Biology' && ( */}
              {selectedSubject?.image && (
                <img src={selectedSubject?.image} alt='Biology_img' />
              )}
              {/* )} */}
              {selectedSubject?.title}
            </h1>
            <div className=''>
              <div className='course-detail-select'>
                <div className='label-div'> Select Country*</div>
                {selectedCountry ? (
                  <DropdownCountry
                    options={countryList}
                    prompt='Select country'
                    onChange={(val) => {
                      onCountrySelect(val);
                    }}
                    // defaultvalue={countryList[0]?""}
                    value={selectedCountry}
                    id='id'
                    name='name'
                    label='name'
                    selectedFlag={
                      selectedCountry?.flag ? selectedCountry?.flag : ''
                    }
                  />
                ) : (
                  <Spinner animation='border' variant='dark' />
                )}
              </div>
            </div>

            {/* <p>
            Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do
            eiusmod tem por incididunt ut
          </p> */}

            {/* <span className='sub-next'>Next {errowRight}</span> */}
          </div>
          <div className='chapters-subject-discription'>
            {selectedSubject?.discription}
          </div>
          {loader ? (
            <div className='col-12 text-center my-4'>
              <Spinner animation='border' variant='dark' />
            </div>
          ) : (
            <div className='chapters-container-wrapper'>
              {syllabus.length > 0 ? (
                renderSubjectList(syllabus)
              ) : (
                // <>
                //   <div className='chapters-container'>
                //     {syllabus.map((chapter, idx) => (
                //       <div
                //         style={
                //           selectedColor !== null
                //             ? { '--color': selectedColor }
                //             : { '--color': '#7D93F2' }
                //         }
                //         className='chapter-name'
                //         key={`Chapter-${idx + 1}`}
                //       >
                //         <h2>{`Chapter ${idx + 1} - ${chapter.chapterName}`}</h2>
                //         <ul>
                //           {chapter.concepts.map((topic, topicIdx) => (
                //             <Topiclist
                //               topic={topic}
                //               topicIdx={topicIdx}
                //               viewMoreTopic={viewMoreTopic}
                //               idx={idx}
                //               descriptionAnchor={descriptionAnchor}
                //               setDescriptionAnchor={setDescriptionAnchor}
                //               descRef={descRef}
                //               shouldToggleStyle={shouldToggleStyle}
                //               setViewMoreTopic={setViewMoreTopic}
                //               key={`topic${topicIdx}`}
                //               subject={match.params.subject}
                //             />
                //           ))}
                //         </ul>
                //       </div>
                //     ))}
                //   </div>
                //   <div className='chapters-container'>
                //     {syllabus.map((chapter, idx) => (
                //       <div
                //         style={
                //           selectedColor !== null
                //             ? { '--color': selectedColor }
                //             : { '--color': '#7D93F2' }
                //         }
                //         className='chapter-name'
                //         key={`Chapter-${idx + 1}`}
                //       >
                //         <h2>{`Chapter ${idx + 1} - ${chapter.chapterName}`}</h2>
                //         <ul>
                //           {chapter.concepts.map((topic, topicIdx) => (
                //             <Topiclist
                //               topic={topic}
                //               topicIdx={topicIdx}
                //               viewMoreTopic={viewMoreTopic}
                //               idx={idx}
                //               descriptionAnchor={descriptionAnchor}
                //               setDescriptionAnchor={setDescriptionAnchor}
                //               descRef={descRef}
                //               shouldToggleStyle={shouldToggleStyle}
                //               setViewMoreTopic={setViewMoreTopic}
                //               key={`topic${topicIdx}`}
                //               subject={match.params.subject}
                //             />
                //           ))}
                //         </ul>
                //       </div>
                //     ))}
                //   </div>
                // </>
                <div
                  style={{ '--color': selectedColor }}
                  className='chapter-name'
                >
                  <h2>{errorMessage}</h2>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

Chapters.propTypes = {
  match: object.isRequired,
  getAllGrades: func.isRequired,
  allGrades: array.isRequired,
  classListByCountry: object.isRequired,
  // getSyllabusData: func.isRequired,
  // allSyllabus: array.isRequired,
};

const mapStateToProps = (state) => ({
  allGrades: state.Auth.AllGrades,
  classListByCountry: state.Auth.classListByCountry,
  countries: state.Auth.openPracticeCountryList,
  newCountryList: state.Syllabus.countryList,
  syllabusData: state.Syllabus.syllabusData,
  ipCountryData: state.Syllabus.ipCountryData,
  // allSyllabus: state.Auth.syllabusHierarchy,
});

const mapDispatchToProps = (dispatch) => ({
  getAllGrades: () =>
    dispatch(getAllGradesAction({ boardId: '61c33124b6b2aa0009e0ddcf' })),
  getAllCountriesAction: () => dispatch(getOpenPracticeCountryList()),
  getAllClassByCountry: (data) => dispatch(getAllClassByCountry(data)),

  getNewCountryList: () => dispatch(getAllCountryList()),
  updateSyllabus: (data) => dispatch(updateSyllabus(data)),
  getCountryBasedOnIP: () => dispatch(getCountryBasedOnIP()),
  // getSyllabusData: (data) => dispatch(getSyllabusHierarchy(data)),
});
// export default Chapters;
export default connect(mapStateToProps, mapDispatchToProps)(Chapters);
