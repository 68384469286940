import React, { useState } from 'react';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input';
import { object, string, func, array, bool } from 'prop-types';
import {
  verifyOtpParentAction,
  verifyOtpTeacherAction,
} from '../../../../stores/Auth/AuthAction';

const OtpInputBox = ({
  label, //
  phoneNumVal, //
  verifyOtpParent, //
  verifyOtpParentData, //
  isDisabled,
  type,
  verifyOtpTeacher,
  verifyOtpTeacherRes,
}) => {
  const [otpVal, setOtpVal] = useState('');

  const handeInput = (data) => {
    const str = data.toString();
    setOtpVal(data);

    if (str.length === 6) {
      const reqData = {
        phone: phoneNumVal,
        otp: data,
      };
      if (type === 'teacher') verifyOtpTeacher(reqData);
      else verifyOtpParent(reqData);
    }
  };

  return (
    <div className='col-md-6 otp-block'>
      <label htmlFor='mydetail-input'>{label}</label>
      <OtpInput
        value={otpVal}
        onChange={(otp) => handeInput(otp)}
        numInputs={6}
        separator={<span> </span>}
        isDisabled={isDisabled}
        isInputNum={true}
      />
      {type === 'teacher' ? (
        verifyOtpTeacherRes?.statusCode === 401 ? (
          <span className='error-msg'>{verifyOtpTeacherRes?.message}</span>
        ) : (
          ''
        )
      ) : verifyOtpParentData?.statusCode === 401 ? (
        <span className='error-msg'>{verifyOtpParentData?.message}</span>
      ) : (
        ''
      )}
      {type === 'teacher' ? (
        verifyOtpTeacherRes?.statusCode === 403 ? (
          <span className='error-msg'>{verifyOtpTeacherRes?.message}</span>
        ) : (
          ''
        )
      ) : verifyOtpParentData?.statusCode === 403 ? (
        <span className='error-msg'>{verifyOtpParentData?.message}</span>
      ) : (
        ''
      )}
    </div>
  );
};

OtpInputBox.propTypes = {
  label: string.isRequired,
  phoneNumVal: string.isRequired,
  verifyOtpParent: func.isRequired, //
  verifyOtpParentData: object.isRequired, //
  isDisabled: bool.isRequired,
};
const mapStateToProps = (state) => ({
  verifyOtpParentData: state.Auth.verifyOtpParent,
  verifyOtpTeacherRes: state.Auth.verifyOtpTeacher,
});

const mapDispatchToProps = (dispatch) => ({
  verifyOtpParent: (data) => dispatch(verifyOtpParentAction(data)),
  verifyOtpTeacher: (data) => dispatch(verifyOtpTeacherAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtpInputBox);
