import * as actionTypes from './StudentDashboardTypes';

const initialState = {
  studentDashboardData: [],
  myReportAnalytics: [],
  cumulativeReport: [],
  leaderboardByAccuracy: [],
  leaderboardByAccuracyOlympiad: [],
  leaderboardBySpeed: [],
  leaderboardBySpeedOlympiad: [],
  braingymStartTime: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_STUDENT_DASHBOARD:
      return {
        ...state,
        studentDashboardData: action.payload,
      };
    case actionTypes.UPDATE_MY_REPORT_ANALYTICS:
      return {
        ...state,
        myReportAnalytics: action.payload,
      };
    case actionTypes.UPDATE_CUMULATIVE_REPORT:
      return {
        ...state,
        cumulativeReport: action.payload,
      };
    case actionTypes.UPDATE_LEADERBOARD_BY_ACCURACY:
      return {
        ...state,
        leaderboardByAccuracy: action.payload,
      };
    case actionTypes.UPDATE_LEADERBOARD_BY_ACCURACY_OLYMPIAD:
      return {
        ...state,
        leaderboardByAccuracyOlympiad: action.payload,
      };
    case actionTypes.UPDATE_LEADERBOARD_BY_SPEED:
      return {
        ...state,
        leaderboardBySpeed: action.payload,
      };
    case actionTypes.UPDATE_LEADERBOARD_BY_SPEED_OLYMPIAD:
      return {
        ...state,
        leaderboardBySpeedOlympiad: action.payload,
      };
    case actionTypes.UPDATE_BRAINGYM_START_TIME:
      return {
        ...state,
        braingymStartTime: action.payload,
      };

    default:
      return { ...state };
  }
}
