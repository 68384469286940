import React from 'react';
import { array, func, number, object } from 'prop-types';
import { useEffect, useState } from 'react';
import { startListening } from '../startLearning';
import { readText, renderText } from '../../BrainGym/QueAnswer/textHelper';
import speaker from '../../../../../../assets/images/speaker.png';
import { findKeyByValue } from '../../../Rightsidepart/components/QuestionTypes/questionHelper';

const FillInTheBlanksQuestion = ({
  questionInChest,
  setSelect,
  selectedOption,
  setIsSubmitValid,
}) => {
  const [finalQuesText, setFinalQuesText] = useState('');
  const [fillInTheBlankAnswer, setFillInTheBlankAnswer] = useState([]);
  const inputString = questionInChest.description;
  // const handleFillInTheBlanksChange = (e, index) => {
  //   e.preventDefault();
  //   let newAns = [...fillInTheBlankAnswer];
  //   const spaceReg = new RegExp(/[\s][\s]+/);
  //   let val;
  //   val = e.target.value.replace(/\s\s+/g, ' ');
  //   if (val.trim() && !spaceReg.test(val.trim())) {
  //     newAns[index] = val.trim();
  //     setFillInTheBlankAnswer([...newAns]);
  //   } else {
  //     newAns[index] = '';
  //     setFillInTheBlankAnswer([...newAns]);
  //     setIsSubmitValid(false);
  //     setSelect([]);
  //   }
  // };

  // useEffect(() => {
  //   let isAllQuestionsAnswerd = true;
  //   if (fillInTheBlankAnswer.length > 0) {
  //     fillInTheBlankAnswer.map((ans) => {
  //       if (ans === '') {
  //         isAllQuestionsAnswerd = false;
  //       }
  //     });
  //     if (isAllQuestionsAnswerd) {
  //       setSelect(fillInTheBlankAnswer);
  //     }
  //   } else {
  //     setSelect([]);
  //   }
  // }, [fillInTheBlankAnswer]);

  // useEffect(() => {
  //   if (
  //     selectedOption?.length == questionInChest?.fillInTheBlankSolution?.length
  //   )
  //     setIsSubmitValid(true);
  //   else setIsSubmitValid(false);
  // }, [selectedOption]);

  // const RenderQuestion = (splitString) => {
  //   let newStr;
  //   if (splitString) {
  //     splitString.map((subStr, i) => {
  //       if (
  //         i < splitString.length - 1 &&
  //         questionInChest?.fillInTheBlankSolution?.includes(-1) &&
  //         fillInTheBlankAnswer[i] !== '' &&
  //         !fillInTheBlankAnswer[i]?.length > 0
  //       ) {
  //         let ans = [...fillInTheBlankAnswer];
  //         ans.push('');
  //         setFillInTheBlankAnswer([...ans]);
  //       }
  //       newStr = (
  //         <>
  //           <span>{newStr}</span>
  //           <span>{subStr}</span>
  //           {splitString.length - 1 === i ? null : (
  //             <input
  //               key={i}
  //               placeholder=''
  //               defaultValue={fillInTheBlankAnswer[i]}
  //               onBlur={(e) => handleFillInTheBlanksChange(e, i)}
  //             />
  //           )}
  //         </>
  //       );
  //     });
  //   }
  //   return <div>{newStr}</div>;
  // };

  const countOccurrences = (statement) => {
    const regex = new RegExp('insert_input', 'g');
    const s = statement.match(regex);
    const count = (statement.match(regex) || []).length;
    return count;
  };

  // useEffect(() => {
  // const quoteadd = questionInChest.description.replace(
  //   /(&rsquo;)/gi,
  //   '’'
  // );

  // const string = quoteadd.replace(/(&nbsp;|<([^>]+)>)/gi, '');
  // const splitString = string.split('insert_input');
  // const finalFormate = RenderQuestion(splitString);

  // setFinalQuesText(finalFormate);

  //   const question = questionInChest.description;
  //   const
  //   question.replace
  // }, [questionInChest, fillInTheBlankAnswer]);

  const [answers, setAnswers] = useState([]);
  // useEffect(() => {
  //   console.log('the anserss', answers);
  // }, [answers]);

  const checkInputValidation = (value) => {
    const noSpacesPattern = RegExp(/[\s][\s]+/);
    if (value) {
      return !noSpacesPattern.test(value);
    } else return true;
  };

  const checkAllBlanksAnswerd = (ans) => {
    const numberOfOccurence = countOccurrences(inputString);
    if (numberOfOccurence === ans.length) {
      if (ans.some((e) => e === ' ')) {
        return false;
      }
      return !ans.some((e) => !!!e);
    }
    return false;
  };

  const handleAnswerSubmit = (event) => {
    const inputIndex = event.target.dataset.index;
    const answer = event.target.value;
    const isinputValid = !!checkInputValidation(answer);
    if (isinputValid) {
      setAnswers((prevAnswers) => {
        const newAnswers = [...prevAnswers];
        newAnswers[inputIndex] = answer;
        const isAnswerdComplete = checkAllBlanksAnswerd(newAnswers);
        setIsSubmitValid(isAnswerdComplete);
        if (isAnswerdComplete) {
          setSelect(newAnswers);
        } else {
          setSelect([]);
        }
        return newAnswers;
      });
    }
  };

  const renderTextWithBlanks = () => {
    const regex = /insert_input/g;
    // questionInChest.description;

    const parts = inputString.split(regex);
    const inputCount = parts.length - 1;
    const result = parts.map((part, index) => {
      if (index < inputCount) {
        return (
          <React.Fragment key={index}>
            {renderText(part)}
            <input
              type='text'
              // placeholder='insert_input'
              // pattern='^[^\s]+$'
              value={answers[index]}
              data-index={index}
              onChange={handleAnswerSubmit}
            />
          </React.Fragment>
        );
      }
      return renderText(part);
    });
    // console.log('the lllooo', result);
    return result;
  };

  return (
    <div className='fill-the-blank'>
      <div className='question-box mb-4'>
        <div className='d-flex justify-content-between gap-3'>
          <h4 className='title'>
            &nbsp;
            {questionInChest?.title == '' || questionInChest?.title == null
              ? findKeyByValue(questionInChest?.solutionType)
              : renderText(questionInChest?.title)}
          </h4>
          <button
            type='button'
            className='speaker-btn-wrapper'
            onClick={() =>
              startListening(
                readText(
                  (questionInChest.title ? questionInChest.title + '. ' : '') +
                    questionInChest.description
                )
              )
            }
          >
            {' '}
            <img
              className='span-one speaker-btn'
              src={speaker}
              alt='speaker icon'
              // style={{ width: '40px', height: '40px' }}
            />
          </button>
        </div>
        <div className='col-12 d-flex align-items-center justify-content-between'>
          <div className='fill-in-the-blanks col-12 '>
            {renderTextWithBlanks()}
            {/* {renderText(renderTextWithBlanks().join(''))}
            <div dangerouslySetInnerHTML={{ __html: renderTextWithBlanks() }} /> */}
            {/* {finalQuesText} */}
          </div>
        </div>
      </div>
    </div>
  );
};
FillInTheBlanksQuestion.propTypes = {
  questionInChest: object.isRequired,
  setSelect: func.isRequired,
  selectedOption: array.isRequired,
};

export default FillInTheBlanksQuestion;
