export const OLYMPIAD_UPCOMING_EXAM = 'OLYMPIAD_UPCOMING_EXAM';
export const UPDATE_OLYMPIAD_UPCOMING_EXAM = 'UPDATE_OLYMPIAD_UPCOMING_EXAM';
export const PREVIOUS_OLYMPIAD_EXAM = 'PREVIOUS_OLYMPIAD_EXAM';
export const UNATTEEMPTED_OLYMPIAD_EXAM = 'UNATTEEMPTED_OLYMPIAD_EXAM';
export const UPDATE_PREVIOUS_OLYMPIAD_EXAM = 'UPDATE_PREVIOUS_OLYMPIAD_EXAM';
export const START_OLYMPIAD_EXAM = 'START_OLYMPIAD_EXAM';
export const UPDATE_START_OLYMPIAD_EXAM = 'UPDATE_START_OLYMPIAD_EXAM';
export const FINISH_OLYMPIAD_EXAM = 'FINISH_OLYMPIAD_EXAM';
export const UPDATE_FINISH_OLYMPIAD_EXAM = 'UPDATE_FINISH_OLYMPIAD_EXAM';

export const GET_QUESTION_IN_OLYMPIAD_EXAM = 'GET_QUESTION_IN_OLYMPIAD_EXAM';
export const UPDATE_GET_QUESTION_IN_OLYMPIAD_EXAM =
  'UPDATE_GET_QUESTION_IN_OLYMPIAD_EXAM';
export const GET_MY_OLYMPIAD_REPORT = 'GET_MY_OLYMPIAD_REPORT';
export const UPDATE_MY_OLYMPIAD_REPORT = 'UPDATE_MY_OLYMPIAD_REPORT';
export const MY_OLYMPIAD_REPORT_ANALYTICS = 'MY_OLYMPIAD_REPORT_ANALYTICS';
export const UPDATE_MY_OLYMPIAD_REPORT_ANALYTICS =
  'UPDATE_MY_OLYMPIAD_REPORT_ANALYTICS';
export const ALL_OLYMPIAD_QUESTION_ANSWER = 'ALL_OLYMPIAD_QUESTION_ANSWER';
export const UPDATE_ALL_OLYMPIAD_QUESTION_ANSWER =
  'UPDATE_ALL_OLYMPIAD_QUESTION_ANSWER';
export const ADD_ALL_OLYMPIAD_QUESTION_ANSWER =
  'ADD_ALL_OLYMPIAD_QUESTION_ANSWER';
