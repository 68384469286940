import React, { useSelector } from 'react-redux';
import axios from 'axios';
import config from './config';
import { connect } from 'react-redux';

const baseURL = config.apiBaseUrl;
const Auth = JSON.parse(localStorage.getItem('taut-student'));
const accessToken = Auth?.data?.accessToken;

const createClient = () => {
  // const accessToken = useSelector(
  //   (state) => state.Auth.Login?.data?.accessToken
  // );
  const client = axios.create({
    baseURL,
  });
  client.defaults.headers.common['Authorization'] = accessToken;
  return client;
};
// const mapStateToProps = (state) => ({
//   token: state.Auth.Login?.data?.accessToken,
// });

// connect(mapStateToProps)(createClient);

const http = createClient();

export default http;
