import React, { useState, useEffect } from 'react';
import { array } from 'prop-types';

const SubjectTabNew = ({ data, classesSubject, setSelectedActivity }) => {
  const [activebtn, setActivebtn] = useState('');
  useEffect(() => {
    if (data?.length > 0) {
      setActivebtn(`${data?.[0]}`);
    }
  }, [data?.length > 0]);
  useEffect(() => {
    if (activebtn && setSelectedActivity) {
      setSelectedActivity(activebtn?.split('-')?.[1]);
    }
  }, [activebtn]);

  const renderMapFunction = () =>
    data?.length > 0
      ? data.map((val) => (
          <div className='toggle-maths-div me-md-2'>
            <button
              className={`toggle-maths-btn ${
                activebtn === `${val}` ? 'mathbtn-act' : 'mathbtn-un'
              }`}
              type='button'
              onClick={() => setActivebtn(`${val}`)}
            >
              {val}
            </button>
          </div>
        ))
      : null;

  return (
    <div className='row myexam-tab'>
      <div className='col-md-9 col-sm-12 myexam-tab-left'>
        <div className='dtoggle-bar'>
          {classesSubject
            ? renderMapFunctionOnConditions()
            : renderMapFunction()}
        </div>
      </div>
    </div>
  );
};

SubjectTabNew.propTypes = {
  data: array.isRequired,
};

export default SubjectTabNew;
