import React, { useEffect } from 'react';
import success from '../../assets/images/success.svg';
import { useHistory, withRouter } from 'react-router-dom';
const Success = ({ demo, location, setModal }) => {
  const query = new URLSearchParams(location.search);
  const type = query.get('type');
  const history = useHistory();
  const handlelogin = () => {
    setModal();
    history.push('/login');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={`register-main ${'success'}`}>
      <div className='success_width'>
        {/* <div className='register-main'> */}
        <div className={`${'step-body'}`}>
          <div
            className={`text-center success-part ${
              type == 'teacher' ? 'teacher-success-part' : ''
            }`}
          >
            {/* {demo? <p>Book a free exciting session</p>:''} */}
            <div className='margin_top_success'>
              <img className='flip' src={success} alt='success' />
            </div>
            <h1 className=''>Welcome!</h1>
            {/* <p>{'Your details have been submitted successfully.'}</p> */}
            <p>
              We appreciate your decision of starting your journey with the top
              2% of educators at TautMore. Our team will contact you to help
              decide on the course, schedules and educators, to explore the best
              learning path and a fruitful journey together. Lets experience the
              ultimate joy of learning.
            </p>
            <div className='success-button'>
              <button
                type='button'
                className='renew-btn'
                onClick={() => handlelogin()}
              >
                {'Okay'}
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default withRouter(Success);
