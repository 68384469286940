import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { func, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import './changedetails.scss';
import PhoneInput from 'react-phone-input-2';

import Countdown, { zeroPad } from 'react-countdown';
import OtpInputBox from '../components/OtpInputBox';
import Loading from '../components/Loading';

import { teacherDashboardServices } from '../../../../../../services';

import '../../../../../components/auth/register/phoneStyle.css';

import { getProfileAction } from '../../../../../../stores/TeacherDashboard/TeacherDashboardAction';

const ChangePhoneNumPopUp = ({
  model,
  handleModel,
  getProfile,
  otp,
  teacherId,
  token,
}) => {
  const [otpButtonText, setOtpButtonText] = useState('Send OTP');
  const [phoneNumVal, setPhoneNumVal] = useState();
  const [isOtpSentSuccessfully, setIsOtpSentSuccessfully] = useState(false);
  const [verifyOtpError, setverifyOtpError] = useState('');

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setOtpButtonText('Resend OTP');
    }
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  const sendOtp = async () => {
    try {
      const sendOtpResponse =
        await teacherDashboardServices.teacherUpdatePhoneNumberSendOtp({
          teacherId,
          phoneNumber: phoneNumVal,
          token,
        });

      if (sendOtpResponse) {
        if (sendOtpResponse.statusCode === 200) {
          setOtpButtonText('Timer');
          setIsOtpSentSuccessfully(true);
        } else {
          swal(`${sendOtpResponse.message}`);
          setOtpButtonText('Send OTP');
        }
      } else {
        swal('Something went wrong.');
        setOtpButtonText('Send OTP');
      }
    } catch (error) {
      console.error('Send Otp failed', error);
    }
  };

  const handleOtpClick = () => {
    if (otpButtonText !== 'Timer') {
      setOtpButtonText('loading');
      sendOtp();
    }
  };

  const getOtpText = () => {
    if (otpButtonText === 'Send OTP') {
      return 'Send OTP';
    }
    if (otpButtonText === 'Timer') {
      return (
        <Countdown
          renderer={renderer}
          date={Date.now() + 30000}
          zeroPadTime={3}
        />
      );
    }
    if (otpButtonText === 'loading') {
      return <Loading variant={'light'} />;
    }
    return 'Resend OTP';
  };

  const handleSendOtpButtonDisable = ({ phoneNumVal, otpButtonText }) => {
    if (!phoneNumVal) {
      return true;
    }
    if (otpButtonText === 'Timer' || otpButtonText === 'loading') {
      return true;
    }
    if (phoneNumVal.slice(0, 2) === '91') {
      return phoneNumVal?.length < 12;
    } else {
      return phoneNumVal?.length < 11;
    }
  };
  const verifyOtp = async (otp) => {
    try {
      const verifyOtpResponse =
        await teacherDashboardServices.teacherUpdatePhoneNumberVerifyOtp({
          teacherId,
          phoneNumber: phoneNumVal,
          otp,
          token,
        });

      if (verifyOtpResponse) {
        if (verifyOtpResponse.statusCode === 200) {
          const sucess = await swal(
            `You have successfully updated your phone number.`,
            '',
            'success'
          );
          if (sucess) {
            handleClose();
          }
        } else {
          setverifyOtpError(verifyOtpResponse.message);
        }
      } else {
        swal('Something went wrong.');
      }
    } catch (error) {
      console.error('Verify Otp failed', error);
    }
  };
  const handleOtp = (otp) => {
    setverifyOtpError('');
    const str = otp.toString();
    if (str.length === 6) {
      verifyOtp(otp);
    }
  };

  const handleClose = () => {
    setPhoneNumVal('');
    setOtpButtonText('Send OTP');
    setIsOtpSentSuccessfully(false);
    getProfile();
    handleModel(false);
  };

  return (
    <Modal
      show={model}
      onHide={() => handleClose()}
      className='change-name-pop-up'
    >
      <Modal.Header closeButton>
        <Modal.Title className='update-username'>
          <h3>Update phone number</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='change-phone-body'>
        <div className='row change-phone-wrapper'>
          <div className='col-12 mydetail-input'>
            <div className='mydetail-input-part'>
              <label htmlFor='detail-label'>New mobile number*</label>
              <>
                <div className='phone-input-custom'>
                  <PhoneInput
                    specialLabel=''
                    country='in'
                    countryCodeEditable={false}
                    className='phone-input-box'
                    value={phoneNumVal}
                    inputStyle={{
                      fontSize: 20,
                      buttonStyle: {
                        display: 'none',
                      },
                    }}
                    placeholder='Enter your mobile number'
                    onChange={(e) => {
                      setverifyOtpError('');
                      setOtpButtonText('Send OTP');
                      setIsOtpSentSuccessfully(false);
                      setPhoneNumVal(e);
                    }}
                  />
                  <button
                    type='button'
                    className='send-otp-button'
                    onClick={handleOtpClick}
                    disabled={handleSendOtpButtonDisable({
                      phoneNumVal,
                      otpButtonText,
                    })}
                  >
                    {getOtpText()}
                  </button>
                </div>
              </>
            </div>
          </div>
          {isOtpSentSuccessfully ? (
            <div className='col-12 mt-4'>
              <OtpInputBox
                errorMessage={verifyOtpError}
                handleOtp={handleOtp}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

ChangePhoneNumPopUp.propTypes = {
  handleModel: func.isRequired,
  model: bool.isRequired,
  changePhoneNumber: func.isRequired,
  id: string.isRequired,
  getProfile: func.isRequired,
};

const mapStateToProps = (state) => ({
  teacherId: state.Auth.Login.data._id,
  token: state.Auth.Login.data.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(getProfileAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePhoneNumPopUp);
