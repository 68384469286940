export const GET_STUDENT_CLASSES = 'GET_STUDENT_CLASSES';
export const UPDATE_GET_STUDENT_CLASSES = 'UPDATE_GET_STUDENT_CLASSES';

export const GET_STUDENT_CLASSES_HISTORY = 'GET_STUDENT_CLASSES_HISTORY';
export const GET_STUDENT_UNATTENDED_CLASSES_HISTORY =
  'GET_STUDENT_UNATTENDED_CLASSES_HISTORY';
export const UPDATE_GET_STUDENT_CLASSES_HISTORY =
  'UPDATE_GET_STUDENT_CLASSES_HISTORY';

export const GET_STUDENT_COCURRICULAR_CLASSES =
  'GET_STUDENT_COCURRICULAR_CLASSES';
export const UPDATE_GET_STUDENT_COCURRICULAR_CLASSES =
  'UPDATE_GET_STUDENT_COCURRICULAR_CLASSES';

export const GET_STUDENT_COCURRICULAR_CLASSES_HISTORY =
  'GET_STUDENT_COCURRICULAR_CLASSES_HISTORY';
export const GET_STUDENT_UNATTENDED_COCURRICULAR_CLASSES_HISTORY =
  'GET_STUDENT_UNATTENDED_COCURRICULAR_CLASSES_HISTORY';
export const UPDATE_GET_STUDENT_COCURRICULAR_CLASSES_HISTORY =
  'UPDATE_GET_STUDENT_COCURRICULAR_CLASSES_HISTORY';

export const GET_STUDENT_LIST = 'GET_STUDENT_LIST';
export const GET_STUDENT_LIST_SUCCESS = 'GET_STUDENT_LIST_SUCCESS';
export const GET_STUDENT_LIST_ERROR = 'GET_STUDENT_LIST_ERROR';

export const GET_STUDENT_LIST_COCURRICULAR = 'GET_STUDENT_LIST_COCURRICULAR';
export const GET_STUDENT_LIST_SUCCESS_COCURRICULAR =
  'GET_STUDENT_LIST_SUCCESS_COCURRICULAR';
export const GET_STUDENT_LIST_ERROR_COCURRICULAR =
  'GET_STUDENT_LIST_ERROR_COCURRICULAR';

export const STUDENT_ATTENDED_ONLINE_CLASS = 'STUDENT_ATTENDED_ONLINE_CLASS';
export const STUDENT_ATTENDED_COCURRICULAR_CLASS =
  'STUDENT_ATTENDED_COCURRICULAR_CLASS';

export const GET_STUDENTS_EMI_DETAILS_BY_EMI_ID =
  'GET_STUDENTS_EMI_DETAILS_BY_EMI_ID';

export const UPDATE_STUDENTS_EMI_DETAILS_BY_EMI_ID =
  'UPDATE_STUDENTS_EMI_DETAILS_BY_EMI_ID';
