import React from 'react';
import { bool } from 'prop-types';
import notification from '../mockData/notificationmenudata';
import moment from 'moment';
import { display } from '../../../../assets/icons/IconList';
import { Link, useHistory } from 'react-router-dom';
import PaginationComponent from './components/pagination/Pagination';
import { studentDataServices } from '../../../../services/studentData.service';
import { connect } from 'react-redux';
import NoNotificationmenu from './NoNotificationmenu';

const Notificationmenu = ({
  className,
  lists,
  handleClose,
  upcomingExamCount,
  setUpcomingPage,
  upcomingPage,
  getNotificationCount,
  studentData,
}) => {
  const history = useHistory();
  // const ref = useRef();

  // useEffect(() => {
  //   const checkIfClickedOutside = (e) => {
  //     // If the menu is open and the clicked target is not within the menu,
  //     // then close the menu
  //     if (className && ref.current && !ref.current.contains(e.target)) {
  //       // handleClose();
  //     }
  //   };

  //   document.addEventListener('mousedown', checkIfClickedOutside);

  //   return () => {
  //     // Cleanup the event listener
  //     document.removeEventListener('mousedown', checkIfClickedOutside);
  //   };
  // }, [className]);

  const readNotify = async (id) => {
    const data = {
      notificationId: id,
    };
    try {
      let res = await studentDataServices.readNotifications(
        data,
        studentData?.accessToken
      );
      if (res.statusCode === 200) {
        getNotificationCount();
      }
    } catch (er) {}
  };

  const handleRoute = (note) => {
    //   module
    // :
    // "exam"
    // redirect
    // :
    // "true"
    // userType
    // :
    // "student"
    const { data } = note;
    if (data?.userType == 'student' && data?.redirect == 'true') {
      switch (data?.module) {
        case 'brief-case':
          history.push('/dashboard/briefcase');
          handleClose();
          break;
        case 'brain-gym':
          history.push('/dashboard/brain-gym');
          handleClose();
          break;
        case 'my-assignments':
          history.push('/dashboard/assignments');
          handleClose();
          break;
        case 'exam':
          history.push('/dashboard/exams');
          handleClose();
          break;
        case 'olympiad-exams':
          history.push('/dashboard/olympiad');
          handleClose();
          break;
        case 'classes':
          history.push('/dashboard/classes');
          handleClose();
          break;
        case 'assignments':
          history.push('/dashboard/assignments');
          handleClose();
          break;
        case 'help-desk':
          history.push('/dashboard/helpdesk');
          handleClose();
          break;
        case 'cocurricular-classes':
          history.push('/dashboard/co-curricular');
          handleClose();
          break;
        case 'practice-test':
          history.push('/dashboard/subject');
          handleClose();
          break;
        case 'subscription':
          history.push('/dashboard/subscriptions');
          handleClose();
          break;

        default:
        // code block
      }
    } else if (data?.userType == 'teggacher' && data?.redirect == 'true') {
      switch (data?.module) {
        case 'subjects':
          history.push('/teacher/subjects');
          handleClose();
          break;
        case 'students':
          history.push('/teacher/students');
          handleClose();
        case 'classes':
          history.push('/teacher/classes');
          handleClose();
          break;
        case 'assignments':
          history.push('/teacher/assignments');
          handleClose();
          break;
        case 'co-curricular':
          history.push('/teacher/co-curricular');
          handleClose();
          break;
        default:
        // code block
      }
    }
  };
  return (
    <div>
      {lists.length > 0 ? (
        <div className={`notification-menu ${className} `}>
          <div>
            <div
              className='row d-flex align-items-center'
              style={{ borderBottom: '1px solid #eaeaea', padding: '20px' }}
            >
              <div className='col-4'>
                <h4 className='border-0 p-0 m-0'>{'Notifications'}</h4>
              </div>

              <div className='col-8'>
                {upcomingExamCount > 10 && (
                  <div>
                    <PaginationComponent
                      handleCurrentPage={(num) => {
                        document.body.scrollTo(0, 0);
                        document.documentElement.scrollTop = 0;
                        setUpcomingPage(num);
                      }}
                      length={upcomingExamCount}
                      perPage={10}
                      currentPage={upcomingPage}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className='notification-menu-wrapper'>
              <div className='notificationMenuInside'>
                {lists?.length
                  ? lists.map((note) => (
                      <div
                        className={`row ${
                          note?.data?.userType !== 'parent' &&
                          note?.data?.redirect == 'true'
                            ? 'cursor-pointer'
                            : 'cursor-pointer'
                        } ${
                          !note?.isRead
                            ? 'notificationActive'
                            : 'notificationInactive'
                        }`}
                        key={note._id}
                        onClick={() => {
                          handleRoute(note);
                          {
                            note?.isRead ? '' : readNotify(note._id);
                          }
                        }}
                      >
                        <div className='col-sm-2 col-12'>
                          {/* <div
                            className='icon'
                            // style={{ backgroundColor: '#FFE7D9' }}
                          > */}
                          {note?.data?.imageUrl && (
                            <img
                              className='icon'
                              src={note?.data?.imageUrl}
                              alt='notification images'
                            />
                          )}
                          {/* </div> */}
                        </div>
                        <div className='col-sm-7 col-12'>
                          <h5>{note.title}</h5>
                          <p>{note.body}</p>
                        </div>
                        <div className='col-sm-3 col-12 notification-menu-right'>
                          <span>{moment(note?.createdAt).fromNow()}</span>
                        </div>
                      </div>
                    ))
                  : ''}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoNotificationmenu className={className} />
      )}
    </div>
  );
};

Notificationmenu.propTypes = {
  className: bool.isRequired,
};

const mapStateToProps = (state) => ({
  studentData: state.Auth.Login?.data,
});

export default connect(mapStateToProps)(Notificationmenu);
