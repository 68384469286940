import React, { useState } from 'react';
import { object } from 'prop-types';
import moment from 'moment';
import assignmentlistcalendar from '../../../../../assets/images/calendar.png';
import assignmetlistcalendernormal from '../../../../../assets/images/calendar_normal.png';
import assignmentlistclock from '../../../../../assets/images/wall-clock.png';
import assignmentlistclocknormal from '../../../../../assets/images/wall-clock_normal.png';
import assignmentlistduration from '../../../../../assets/images/examlisthover4.png';
import assignmentlistdurationnormal from '../../../../../assets/images/examlistglass.png';
const CoCurricularHistory = (props) => {
  const { val } = props;
  const [calendar, setCalendar] = useState(assignmetlistcalendernormal);
  const [target, setTarget] = useState(assignmentlistclocknormal);
  const [idea, setIdea] = useState(assignmentlistdurationnormal);
  return (
    <div
      className='examlist-common'
      onMouseEnter={() => {
        setCalendar(assignmentlistcalendar);
        setTarget(assignmentlistclock);
        setIdea(assignmentlistduration);
      }}
      onMouseLeave={() => {
        setCalendar(assignmetlistcalendernormal);
        setTarget(assignmentlistclocknormal);
        setIdea(assignmentlistdurationnormal);
      }}
      aria-hidden='true'
    >
      <div className='row examlist-common-one'>
        <div className='col-md-3 col-sm-4'>
          <p>
            <span>Grade - </span>
            <span> {val?.gradeInfo?.name} </span>
          </p>
        </div>
        <div className='col-md-4 col-sm-4'>
          <p>
            <span>Subject</span> -
            <span className='span-bold'>
              {val?.activityInfo?.activityName}
            </span>
          </p>
        </div>
      </div>
      <div className='row examlist-common-two'>
        <div className='col-md-3 col-sm-6 col-12 examlist-common-bottom'>
          <div className='examlisthand left-icon'>
            <img src={calendar} alt='examlisthand' />
          </div>
          <div className='right-content'>
            <p>Date</p>
            <h5> {moment(val?.date).format('dddd, MMMM Do')} </h5>
          </div>
        </div>
        <div className='col-md-4 col-sm-6 col-12 examlist-common-bottom'>
          <div className='examlistidea left-icon'>
            <img src={target} alt='examlistidea' />
          </div>
          <div className='right-content'>
            <p>Time</p>
            <h5> {val?.timeSlot} </h5>
          </div>
        </div>
        <div className='col-md-2 col-sm-6 col-12 examlist-common-bottom'>
          <div className='examlistclipboard left-icon'>
            <img src={idea} alt='examlisthand' />
          </div>
          <div className='right-content'>
            <p>Duration</p>
            <h5> {val?.duration} minutes </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

CoCurricularHistory.propTypes = {
  val: object.isRequired,
};

export default CoCurricularHistory;
