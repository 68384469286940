import { bool, func, object } from 'prop-types';
import React from 'react';
import { Modal, ModalBody, Button, ModalFooter, ModalTitle } from 'react-bootstrap';
import { closeIcon } from '../../../../../../../assets/icons/IconList';
import QuestionPreview from './QuestionPreview';

const PreviewPopup = ({ modal, setModal, questionDetailsData }) => (
  <Modal
    show={modal}
    size='lg'
    aria-labelledby='example-modal-sizes-title-lg'
    centered
    className='assignmentConQue preview-popup'
  >
    <button className='assignmentBtnClose' onClick={() => setModal(!modal)}>{closeIcon}</button>
    <ModalBody>
      <QuestionPreview queDetails={questionDetailsData} />
    </ModalBody>
    <ModalFooter>
      <Button color='secondary' onClick={() => setModal(!modal)}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
);

PreviewPopup.propTypes = {
  modal: bool.isRequired,
  setModal: func.isRequired,
  questionDetailsData: object.isRequired,
};

export default PreviewPopup;
