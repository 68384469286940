import { fork, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './MyOlympiadExamTypes';
import { myOlympiadExamStudentServices } from '../../services';

function* workerOlympiadUpcomingExam(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield myOlympiadExamStudentServices.upcomingOlympiadExam(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_OLYMPIAD_UPCOMING_EXAM,
      payload: response,
    });
  }
}
function* workerPreviousExam(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield myOlympiadExamStudentServices.previousExam(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_PREVIOUS_OLYMPIAD_EXAM,
      payload: response,
    });
  }
}
function* workerUnattemptedExam(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield myOlympiadExamStudentServices.getUnattemptedExam(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_PREVIOUS_OLYMPIAD_EXAM,
      payload: response,
    });
  }
}
function* workerMyReport(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield myOlympiadExamStudentServices.myReport(value, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_MY_OLYMPIAD_REPORT,
      payload: response,
    });
  }
}
function* workerStartExam(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield myOlympiadExamStudentServices.startExam(value, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_START_OLYMPIAD_EXAM,
      payload: response,
    });
  }
}
function* workerFinishExam(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield myOlympiadExamStudentServices.finishExam(value, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_FINISH_OLYMPIAD_EXAM,
      payload: response,
    });
  }
}

function* workerGetQuestionInExam(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield myOlympiadExamStudentServices.questionInExam(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_QUESTION_IN_OLYMPIAD_EXAM,
      payload: response?.data,
    });
  }
}

function* watcherMyExam() {
  yield takeLatest(
    actionTypes.OLYMPIAD_UPCOMING_EXAM,
    workerOlympiadUpcomingExam
  );
  yield takeLatest(actionTypes.PREVIOUS_OLYMPIAD_EXAM, workerPreviousExam);
  yield takeLatest(
    actionTypes.UNATTEEMPTED_OLYMPIAD_EXAM,
    workerUnattemptedExam
  );
  yield takeLatest(actionTypes.START_OLYMPIAD_EXAM, workerStartExam);
  yield takeLatest(actionTypes.FINISH_OLYMPIAD_EXAM, workerFinishExam);
  yield takeLatest(
    actionTypes.GET_QUESTION_IN_OLYMPIAD_EXAM,
    workerGetQuestionInExam
  );
  yield takeLatest(actionTypes.GET_MY_OLYMPIAD_REPORT, workerMyReport);
}

function* fetchAll() {
  yield fork(watcherMyExam);
}

export default fetchAll();
