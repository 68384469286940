import React from 'react';
import { renderText } from '../../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/textHelper';
import { findKeyByValue } from '../../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/questionHelper';

const NewTypeQuest = ({ queDetails, description, qIndex }) => {
  const renderTextwithReplace = (desc) => {
    const strDesc = desc.replace(/insert_input/g, '______');

    const finalDesc = renderText(strDesc);
    return finalDesc;
  };
  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <div className='question-box'>
            {(findKeyByValue(queDetails?.solutionType) ===
              'fill-in-the-blanks' ||
              findKeyByValue(queDetails?.solutionType) ===
              'drag-and-match-the-correct-answer') && (
                <div>
                  <h5>{queDetails.title ? renderText(queDetails.title) : ''}</h5>
                  <h2>
                    <span>{renderTextwithReplace(description)}</span>
                  </h2>
                </div>
              )}

            {findKeyByValue(queDetails?.solutionType) ===
              'select-the-suitable-words' && (
                <div>
                  <h5>{queDetails.title ? renderText(queDetails.title) : ''}</h5>
                  <h2>
                    <span>{renderText(description)}</span>
                  </h2>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTypeQuest;
