import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import {
  updatePasswordAction,
  getProfileAction,
} from '../../../../../../stores/TeacherDashboard/TeacherDashboardAction';
import './changedetails.scss';
import { chevRight } from '../../../../../../assets/icons/IconList';
import { useHistory } from 'react-router';

const ChangePasswordPopUp = ({
  model,
  handleModel,
  changePassword,
  updatePasswordRes,
}) => {
  const history = useHistory();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setnewPassword] = useState('');
  const [ConfirmPassword, setConfirmPassword] = useState('');

  const [ErrBool, setErrBool] = React.useState(false);
  const [lowErr, setLowErr] = React.useState(false);
  const [ErrMsg, setErrMsg] = React.useState('');

  const [oldPasswordError, setOldPAsswordError] = useState(false);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);

  const [successModal, setSuccessModal] = useState(false);
  const checkForWhiteSpace = RegExp('[\\s]+');

  const handleNewPasswordChange = (e) => {
    if (checkForWhiteSpace.test(e.target.value)) {
      setErrMsg("'Please Do Not Enter Whitespace'")
      setErrBool(true);
    } else {
      setErrBool("")
    }
    setnewPassword(e.target.value)
  }
  const handleConfirmPasswordChange = (e) => {
    if (checkForWhiteSpace.test(e.target.value)) {
      setErrMsg('Please Do Not Enter Whitespace');
      setErrBool(true);
    } else {
      setLowErr('');
    }
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    if (updatePasswordRes.statusCode === 200) {
      setIsLoadingSpinner(false);
      setSuccessModal(true);
    }

    if (updatePasswordRes.statusCode === 410) {
      setIsLoadingSpinner(false);
      setOldPAsswordError(true);
    }

    if (updatePasswordRes.statusCode === 404) {
      setOldPAsswordError(true);
    }
  }, [updatePasswordRes]);

  const onSaveClick = (e) => {
    e.preventDefault();
    if (checkForWhiteSpace.test(newPassword)) {
      setErrMsg('Please Do Not Enter Whitespace');
      setErrBool(true);
    } else if (checkForWhiteSpace.test(ConfirmPassword)) {
      setErrMsg('Please Do Not Enter Whitespace');
      setErrBool(true);
    }
    else if (newPassword === '') {
      setErrMsg('Password field cant be empty.');
      setErrBool(true);
      setLowErr(true);
    } else if (!/[A-Z]/.test(newPassword)) {
      setErrMsg('Password must include an upper case alphabet');
      setErrBool(true);
    } else if (!/\d/.test(newPassword)) {
      setErrMsg('Password must include one number');
      setErrBool(true);
    } else if (newPassword.length <= 8) {
      setErrMsg('Password too short, Enter Minimum 8 char.');
      setErrBool(true);
    } else if (ConfirmPassword !== newPassword) {
      setErrMsg('Provided Password do not match');
      setLowErr(true);
    } else if (!ErrBool && ConfirmPassword === newPassword) {
      changePassword({ oldPassword, newPassword });
      setIsLoadingSpinner(true);
    }
  };

  return (
    <>
      <Modal
        show={model}
        onHide={() => {handleModel(false);
        history.push('/teacher/profile');
        }}
        className={successModal ? 'success-modal-popup' : 'change-name-pop-up'}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center'>
          </Modal.Title>
        </Modal.Header>

        <div>
          <h3 className='text-center'>
            {successModal ? 'Password changed successfully' : 'Update Password'}
          </h3>
        </div>

        {successModal ? (
          <div className='text-center'>
            <button
              style={{ marginTop: successModal ? "" : "" }}
              type='submit'
              className='button-common'
              onClick={() => {
                handleModel(false);
                setSuccessModal(false);
              }}
            >
              Continue
              {/* <span>{chevRight}</span> */}
            </button>
          </div>
        ) : (
          <Modal.Body>
            <form onSubmit={onSaveClick}>
              <div className='text-start'>
                <h6>Current Password</h6>
                <input
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                    setOldPAsswordError(false);
                  }}
                  className='form-control'
                  type='text'
                  name='currentPass'
                  required
                />
              </div>

              <div className='mt-3'>
                <h6>New Password</h6>
                <input
                  onFocus={() => {
                    setErrBool(false);
                    setLowErr(false);
                  }}
                  onChange={(e) => handleNewPasswordChange(e)}
                  className='form-control'
                  type='text'
                  name='newPass'
                  style={{ borderColor: ErrBool || lowErr ? 'red' : null }}
                />
              </div>

              {ErrBool ? (
                <h6
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    fontSize: '11px',
                  }}
                >
                  {ErrMsg}
                </h6>
              ) : null}

              <div className='mt-3'>
                <h6>Confirm New Password</h6>
                <input
                  style={{ borderColor: lowErr ? 'red' : null }}
                  onFocus={() => {
                    setLowErr(false);
                  }}
                  onChange={(e) => handleConfirmPasswordChange(e)}
                  className='form-control'
                  type='text'
                  name='ConfirmNewPass'
                />
              </div>

              {lowErr ? (
                <h6
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    fontSize: '12px',
                  }}
                >
                  {ErrMsg}
                </h6>
              ) : null}
              {oldPasswordError ? (
                <h6
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    fontSize: '12px',
                  }}
                >
                  Old Password does not match
                </h6>
              ) : null}

              <div className='text-center'>
                <button type='submit' className='button-common'>
                  {isLoadingSpinner ? (
                    <Spinner animation='border' variant='light' />
                  ) : (
                    'Change Password'
                  )}
                  {/* <span>{chevRight}</span> */}
                </button>
              </div>
            </form>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

ChangePasswordPopUp.propTypes = {
  handleModel: func.isRequired,
  model: bool.isRequired,
  changePassword: func.isRequired,
  updatePasswordRes: func.isRequired,
};

const mapStateToProps = (state) => ({
  updatePasswordRes: state.TeacherDashboard.updatePassword,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (data) => dispatch(updatePasswordAction(data)),
  getProfile: () => dispatch(getProfileAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordPopUp);
