import React, { useState, useEffect } from 'react';
import { object } from 'prop-types';
import '../../components/dashboard/dashboard.scss';
import Sidemenu from './Sidemenu';
import DashSearch from '../../components/dashboard/Rightsidepart/DashSearch';
import './teachers.scss';
import Home from './Rightsidepart/Home/Home';
import MyClass from './Rightsidepart/MyClass/MyClass';
import MySubjects from './Rightsidepart/MySubjects/MySubjects';
import MyStudents from './Rightsidepart/MyStudents/MyStudents';
import MyPayments from './Rightsidepart/MyPayments/MyPayments';
import Calendar from './Rightsidepart/Calendar/Calendar';
import MyProfile from './Rightsidepart/MyProfile/MyProfile';
import Myconcept from './Rightsidepart/MyStudents/StudentConcepts/MyConcepts';
import AssignmentsAndHomeWork from './Rightsidepart/MyStudents/Assignments/AssignmentsAndHomework';
import Revenue from './Rightsidepart/MyRevenue/RevenueSwitch';
import CoCurricularActivities from './Rightsidepart/CoCurricular/CoCurricularActivities';
import { useHistory } from 'react-router-dom';
import {
  getFireBaseToken,
  onMessageListener,
} from '../../../firebase/Firebase';
import { authServices } from '../../../services';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { studentDataServices } from '../../../services/studentData.service';

import { checkIsTokenExpired } from '../../../helpers/utility';

const Teachers = ({ match, teacherData }) => {
  const sw = navigator.serviceWorker;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const viewType = match.url;
  const caseType = match.params.viewtype ? match.params.viewtype : '';

  const [fireBaseToken, setFireBaseToken] = useState('');
  const [notificationData, setNotification] = useState('');
  const [notificationCount, setNotificationCount] = useState('');
  const [notificationsList, setNotificationsList] = useState([]);
  const [upcomingPage, setUpcomingPage] = useState(0);
  const [upcomingExamCount, setUpcomingExamCount] = useState(0);

  useEffect(() => {
    if (teacherData?.accessToken) {
      const expired = checkIsTokenExpired(teacherData?.accessToken);
      if (expired) {
        localStorage.clear();
        window.location.href = '/login';
        //   // Perform actions when token is expired, e.g., logout user or request a new token
      }
      const intervalId = setInterval(() => {
        const expired = checkIsTokenExpired(teacherData?.accessToken);
        if (expired) {
          localStorage.clear();
          clearInterval(intervalId); // Stop the interval

          window.location.href = '/login';
          //   // Perform actions when token is expired, e.g., logout user or request a new token
        }
      }, 1000); // Interval check every second

      return () => {
        clearInterval(intervalId); // Clear the interval on component unmount
      };
    } else {
      localStorage.clear();
      window.location.href = '/login';
    }
  }, [teacherData]);

  const renderLayouts = () => {
    let view = <Home lgShow={lgShow} setLgShow={setLgShow} />;

    switch (caseType) {
      case 'home':
        view = <Home lgShow={lgShow} setLgShow={setLgShow} />;
        break;

      case 'subjects':
        view = <MySubjects />;
        break;

      case 'students':
        view = <MyStudents lgShow={lgShow} setLgShow={setLgShow} />;
        break;

      case 'payments':
        view = <MyPayments lgShow={lgShow} setLgShow={setLgShow} />;
        break;

      case 'classes':
        view = <MyClass lgShow={lgShow} setLgShow={setLgShow} />;
        break;

      case 'calendar':
        view = <Calendar />;
        break;

      case 'profile':
        view = <MyProfile />;
        break;

      case 'concepts':
        view = <Myconcept />;
        break;

      case 'assignments':
        view = <AssignmentsAndHomeWork />;
        break;

      case 'revenue':
        view = <Revenue />;
        // history.push('/teacher/classes');

        break;

      case 'co-curricular':
        view = <CoCurricularActivities lgShow={lgShow} setLgShow={setLgShow} />;
        break;

      default:
        view = <Home lgShow={lgShow} setLgShow={setLgShow} />;
        break;
    }
    return view;
  };

  return (
    <div className={`dashboard-main ${open ? 'open' : 'close'}`}>
      <div className='row row-main'>
        <Sidemenu open={open} setOpen={setOpen} viewType={viewType} />
        <div className='col-sm-9 dashboard-right'>
          <DashSearch
            count={notificationCount}
            lists={notificationsList}
            upcomingExamCount={upcomingExamCount}
            setUpcomingPage={setUpcomingPage}
            upcomingPage={upcomingPage}
          />
          {renderLayouts()}
        </div>
      </div>
    </div>
  );
};

Teachers.propTypes = {
  match: object.isRequired,
};

const mapStateToProps = (state) => ({
  teacherData: state.Auth.Login?.data,
  Loginparent: state.Auth.LoginParent,
  userData: state.Auth.Login,
});

export default connect(mapStateToProps)(Teachers);
