import React from 'react';
import { array, func, number, object } from 'prop-types';
import bookmarkimg from '../../../../../../../assets/images/bookmark.png';
import bookmarkfilledimg from '../../../../../../../assets/images/bookmarkfilled2.png';
import { useEffect, useState } from 'react';
import { renderText, readText } from '../textHelper';
import speaker from '../../../../../../../assets/images/speaker.png';
import { startListening } from '../../Exam/startLearning';

const FillInTheBlanksDragAndDrop = ({
  questionInChest,
  setSelect,
  selectedQuestion,
  allQuesAns,
  addBookmark,
}) => {
  const [finalQuesText, setFinalQuesText] = useState('');
  const [finalAnsArray, setFinalAnsArray] = useState([]); //options to drag
  const [fillInTheBlankAnswer, setFillInTheBlankAnswer] = useState([]); //answerd ansers

  const [dragSource, setDragSource] = useState(-1);
  const [dragDestination, setDragDestination] = useState(-1);

  useEffect(() => {
    //FORMATING THE QUESTION AND ANSWERS
    const quoteadd = questionInChest.descImageElement.replace(
      /(&rsquo;)/gi,
      '’'
    );
    const string = quoteadd.replace(/(&nbsp;|<([^>]+)>)/gi, ''); //REMOVING ALL TAGS
    const splitString = string.split('insert_input');
    setFinalQuesText(splitString);

    //FORMATING ANSWERS
    let shuffeldAns = questionInChest.dragTheTextShuffledElement;
    shuffeldAns = shuffeldAns.replace(/(&rsquo;)/gi, '’');
    var cleanText = shuffeldAns.replace(/(&nbsp;|<([^>]+)>)/gi, '');
    setFinalAnsArray([...cleanText.split(',')]);
  }, [questionInChest]);

  useEffect(() => {
    let isAllQuestionsAnswerd = true;
    if (fillInTheBlankAnswer.length > 0) {
      fillInTheBlankAnswer.map((ans) => {
        if (ans.name === '') {
          isAllQuestionsAnswerd = false;
        }
      });
      if (isAllQuestionsAnswerd) {
        setSelect([...fillInTheBlankAnswer]);
      }
    }
  }, [fillInTheBlankAnswer]);

  useEffect(() => {
    if (
      allQuesAns[selectedQuestion]?.solutionIndex?.length > 0 &&
      !allQuesAns[selectedQuestion]?.solutionIndex?.includes(-1) &&
      finalAnsArray?.length > 0
    ) {
      var oldAnswers = allQuesAns[selectedQuestion]?.solutionIndex.map(
        (eachIndex) => ({ name: finalAnsArray[eachIndex], index: eachIndex })
      );
      setFillInTheBlankAnswer([...oldAnswers]);
    }
  }, [selectedQuestion, finalAnsArray]);

  const RenderQuestion = (splitString) => {
    let newStr;
    if (splitString) {
      splitString.map((subStr, i) => {
        if (
          i < splitString.length - 1 &&
          allQuesAns[selectedQuestion]?.solutionIndex?.includes(-1) &&
          !fillInTheBlankAnswer[i] &&
          !fillInTheBlankAnswer[i]?.name?.length > 0
        ) {
          let ans = [...fillInTheBlankAnswer];
          ans.push({ name: '', index: '' });
          setFillInTheBlankAnswer([...ans]);
        }
        newStr = (
          <>
            <span>{newStr}</span>
            <span>{subStr}</span>
            {splitString.length - 1 === i ? null : (
              <input
                key={i}
                placeholder=''
                defaultValue={fillInTheBlankAnswer[i]?.name}
                onChange={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(i)}
                onDragOver={(e) => e.preventDefault()}
                disabled={true}
              />
            )}
          </>
        );
      });
    }
    return <div>{newStr}</div>;
  };

  const renderTextWithBlanks = (inputString) => {
    const regex = /insert_input/g;
    // questionInChest.description;

    const parts = inputString.split(regex);
    const inputCount = parts.length - 1;
    const result = parts.map((part, index) => {
      if (index < inputCount) {
        if (
          allQuesAns[selectedQuestion]?.solutionIndex?.includes(-1) &&
          !fillInTheBlankAnswer[index] &&
          !fillInTheBlankAnswer[index]?.name?.length > 0
        ) {
          let ans = [...fillInTheBlankAnswer];
          ans.push({ name: '', index: '' });
          setFillInTheBlankAnswer([...ans]);
        }
        return (
          <React.Fragment key={index}>
            {renderText(part)}
            <input
              key={index}
              id={index}
              className='dropbox'
              placeholder=''
              defaultValue={fillInTheBlankAnswer[index]?.name}
              onChange={(e) => e.preventDefault()}
              onDrop={(e) => handleDrop(index)}
              onDragOver={(e) => e.preventDefault()}
              disabled={true}
            />
            {/* <input
              type='text'
              // placeholder='insert_input'
              // pattern='^[^\s]+$'
              value={answers[index]}
              data-index={index}
              onChange={handleAnswerSubmit}
            /> */}
          </React.Fragment>
        );
      }
      return renderText(part);
    });
    // console.log('the lllooo', result);
    return result;
  };

  const allquedata = allQuesAns[selectedQuestion];

  //DRAG AND DROP FUNCTIONALITY
  const handleDrag = (index) => {
    setDragSource(index);
  };

  const handleDrop = (ind) => {
    setDragDestination(ind);
    if (dragSource !== -1) {
      var newFinalAns = [...fillInTheBlankAnswer];
      newFinalAns[ind].name = finalAnsArray[dragSource];
      newFinalAns[ind].index = dragSource;
      setFillInTheBlankAnswer([...newFinalAns]);
    }
  };
  //END DRAG AND DROP FUNCTIONALITY

  return (
    <div>
      <div className='question-box mb-4'>
        <div className='d-flex justify-content-between'>
          <h4>Question - {selectedQuestion + 1}</h4>
          <span>
            <button
              type='button'
              id={selectedQuestion}
              onClick={() =>
                startListening(
                  readText(
                    (questionInChest.title
                      ? questionInChest.title + '. '
                      : '') + questionInChest.description
                  )
                )
              }
            >
              {' '}
              <img
                className='span-one speaker-btn'
                src={speaker}
                alt='speaker icon'
              />
            </button>
            <button
              className='text-right'
              onClick={() => addBookmark(selectedQuestion)}
              type='button'
            >
              <img
                className={
                  allquedata?.Bookmarked ? 'bookmarkfilled-img' : 'bookmark-img'
                }
                src={allquedata?.Bookmarked ? bookmarkfilledimg : bookmarkimg}
                alt='bookmark'
              />
            </button>
          </span>
        </div>
        <h4 className='title'>
          {questionInChest.title ? renderText(questionInChest.title) : ''}
        </h4>
        <div className='fill-in-the-blanks'>
          {/* {RenderQuestion(finalQuesText)} */}
          {renderTextWithBlanks(questionInChest.description)}
        </div>
        <div className='fill-drag-option'>
          {/* RENDER ANSWER */}
          {finalAnsArray.map((ele, i) => (
            <div className='fill-in-the-blanks-shuffled' key={i + 30}>
              <div
                className='drag-item'
                draggable
                onDragStart={() => handleDrag(i)}
                onDragOver={(e) => e.preventDefault()}
              >
                <span className='m-5'>{ele}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

FillInTheBlanksDragAndDrop.propTypes = {
  questionInChest: object.isRequired,
  setSelect: func.isRequired,
  selectedOption: array.isRequired,
  selectedQuestion: number.isRequired,
  allQuesAns: array.isRequired,

  addBookmark: func.isRequired,
};

export default FillInTheBlanksDragAndDrop;
