import { fork, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as actionTypes from './MySubscriptionTypes';
import { studentSubscription } from '../../services';

function* workerGetStudentSubscription(data) {
  const value = data?.payload;
  const response = yield studentSubscription.getstudentSubcription(value);
  localStorage.setItem('stuSubscriptionData', JSON.stringify(response));
  if (localStorage.getItem('subIds')) {
    localStorage.setItem(
      'subIds',
      JSON.stringify([
        ...JSON.parse(localStorage.getItem('subIds')),
        response?.data?.subscription_id,
      ])
    );
  } else {
    localStorage.setItem(
      'subIds',
      JSON.stringify([response?.data?.subscription_id])
    );
  }
  if (response) {
    yield put({
      type: actionTypes.UPDATE_STUDENT_SUBSCRIPTION,
      payload: response,
    });
  }
}
function* workerGetCurrentStudentSubscription(data) {
  // const value = data?.payload?.student;
  const response = yield studentSubscription.getCurrentSubscription(
    data?.payload
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_STUDENT_CURRENT_SUBSCRIPTION,
      payload: response,
    });
  }
}
function* workerGetStudentSubscriptionList(data) {
  // const value = data?.payload?.student;
  const response = yield studentSubscription.subscriptionList(data?.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_STUDENT_SUBSCRIPTION_LIST,
      payload: response,
    });
  }
}
function* workerStudentRenewSubscription(data) {
  const value = data?.payload;
  const response = yield studentSubscription.renewSubscription(value);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_STUDENT_RENEW_SUBSCRIPTION,
      payload: response,
    });
  }
}
function* workerStudentUpdateSubscription(data) {
  const value = data?.payload;
  const response = yield studentSubscription.updateSubscription(value);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_STUDENT_UPDATE_SUBSCRIPTION,
      payload: response,
    });
  }
}
function* workerCreateSubscriptionOrder(data) {
  const value = data?.payload;
  const response = yield studentSubscription.CreateSubscriptionOrder(value);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_CREATE_SUBSCRIPTION_ORDER,
      payload: response,
    });
  }
}
function* workerStartSubscriptionPayment(data) {
  const value = data?.payload;
  const response = yield studentSubscription.startSubscriptionPayment(value);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_START_SUBSCRIPTION_PAYMENT,
      payload: response,
    });
  }
}
function* workerApplyDiscount(data) {
  const value = data?.payload;
  const response = yield studentSubscription.applyDiscount(value);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_APPLY_DISCOUNT,
      payload: response,
    });
  }
}

function* workerApplyCuponDiscount(data) {
  const value = data?.payload;
  const response = yield studentSubscription.applyCuponCodeDiscount(value);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_NEW_APPLY_DISCOUNT,
      payload: response,
    });
  }
}

function* workerRenewApplyCuponDiscount(data) {
  const value = data?.payload;
  const response = yield studentSubscription.applyRenewCuponCodeDiscount(value);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_NEW_RENEW_APPLY_DISCOUNT,
      payload: response,
    });
  }
}

function* workerGetPArentStudentSubscirption(data) {
  const value = data?.payload;
  const response = yield studentSubscription.getParentStudentSubscription(
    value
  );
  if (response) {
    yield put({
      type: actionTypes.UPDATE_PARENT_STUDENT_SUBSCRIPTION,
      payload: response,
    });
  }
}

function* workerGetPArentStudentDetails(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;

  const value = data?.payload;
  const response = yield studentSubscription.getParentStudentDetails(
    value,
    token
  );
  if (response) {
    yield put({
      type: actionTypes.UPDATE_PARENT_STUDENT_DETAILS,
      payload: response,
    });
  }
}

function* workerRemoveStudentSubscription(data) {
  const value = data?.payload;
  const response = yield studentSubscription.removeStudentSubscription(value);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_REMOVE_STUDENT_SUBSCRIPTION,
      payload: response,
    });
  }
}

function* watcherStudentSubscription() {
  yield takeEvery(
    actionTypes.STUDENT_SUBSCRIPTION,
    workerGetStudentSubscription
  );
  yield takeLatest(
    actionTypes.STUDENT_CURRENT_SUBSCRIPTION,
    workerGetCurrentStudentSubscription
  );
  yield takeLatest(
    actionTypes.STUDENT_SUBSCRIPTION_LIST,
    workerGetStudentSubscriptionList
  );
  yield takeLatest(
    actionTypes.STUDENT_RENEW_SUBSCRIPTION,
    workerStudentRenewSubscription
  );
  yield takeLatest(
    actionTypes.STUDENT_UPDATE_SUBSCRIPTION,
    workerStudentUpdateSubscription
  );
  yield takeLatest(
    actionTypes.CREATE_SUBSCRIPTION_ORDER,
    workerCreateSubscriptionOrder
  );
  yield takeLatest(
    actionTypes.START_SUBSCRIPTION_PAYMENT,
    workerStartSubscriptionPayment
  );
  yield takeLatest(actionTypes.APPLY_DISCOUNT, workerApplyDiscount);

  yield takeLatest(actionTypes.NEW_APPLY_DISCOUNT, workerApplyCuponDiscount);
  yield takeLatest(
    actionTypes.NEW_RENEW_APPLY_DISCOUNT,
    workerRenewApplyCuponDiscount
  );
  yield takeLatest(
    actionTypes.REMOVE_STUDENT_SUBSCRIPTION,
    workerRemoveStudentSubscription
  );
  yield takeLatest(
    actionTypes.PARENT_STUDENT_SUBSCRIPTION,
    workerGetPArentStudentSubscirption
  );
  yield takeLatest(
    actionTypes.PARENT_STUDENT_DETAILS,
    workerGetPArentStudentDetails
  );
}

function* fetchAll() {
  yield fork(watcherStudentSubscription);
}

export default fetchAll();
