import React, { useEffect } from 'react';
import success from '../../../assets/images/success.svg';
import { chevRight } from '../../../assets/icons/IconList';
import {
  useHistory,
  Link,
  NavLink,
  useParams,
  useNavigate,
  createSearchParams,
  withRouter,
} from 'react-router-dom';
const Success = ({ demo, location, setModal }) => {
  const query = new URLSearchParams(location.search);
  const type = query.get('type');
  const history = useHistory();
  const handlelogin = () => {
    localStorage.removeItem('registraiondata');
    if (!type && !demo) window.location = '/login';
    else {
      if (demo) setModal();
      history.push('/');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={`register-main ${'success'}`}>
      <div className='success_width'>
        {/* <div className='register-main'> */}
        <div className={`${'step-body'}`}>
          <div
            className={`text-center success-part ${
              type == 'teacher' ? 'teacher-success-part' : ''
            }`}
          >
            {demo ? <p>Book a free exciting session</p> : ''}
            <div className='margin_top_success'>
              <img className='flip' src={success} alt='success' />
            </div>
            <h1 className=''>{demo ? 'Thank You!' : 'SUCCESS!'}</h1>
            <p>
              {type == 'teacher'
                ? 'Thankyou for your interest to join the top 2% group of educators.TautMore team will contact you soon.'
                : demo
                ? 'Your details have been submitted successfully.'
                : 'Welcome to TautMore. Your child can login with the email & password sent to your registered email.'}
            </p>
            <p>
              {demo
                ? 'We will schedule a demo as per your convenient time.'
                : ''}
            </p>
            <div className='success-button'>
              <button
                type='button'
                className='renew-btn'
                onClick={() => handlelogin()}
              >
                {!type && !demo ? 'Login now' : 'Okay'}
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default withRouter(Success);
