import React, { useState, useEffect } from 'react';
import './login.scss';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { object } from 'prop-types';

import UserTypeSelectionScreen from './components/UserTypeSelection';
import ParentLogin from './components/ParentLogin';
import TeacherLogin from './components/TeacherLogin';

const Login = ({ userData }) => {
  const [modal, setModal] = useState(false);
  const history = useHistory();
  const [userType, setUserType] = useState();

  useEffect(() => {
    if (userType) {
      const searchQuery = new URLSearchParams(window.location.search);
      const searchParams = { userType: searchQuery?.get('userType') };
      setUserType(
        searchParams?.userType ? searchParams?.userType : 'not-selected'
      );
    } else {
      const searchQuery = new URLSearchParams(window.location.search);
      const searchParams = { userType: searchQuery?.get('userType') };
      setUserType(
        searchParams?.userType ? searchParams?.userType : 'not-selected'
      );
    }
  }, [window.location.search]);

  useEffect(() => {
    if (userData?.data?.userType === 'teacher') {
      if (userData?.data?.co_curricular?.length > 0) {
        return history.push('/teacher/co-curricular');
      } else {
        return history.push('/teacher/classes');
      }
    }
    if (userData?.data?.userType === 'student') {
      history.push('/dashboard');
    }
    if (userData?.data?.userType === 'parent') {
      history.push('/dashboard');
    }
    if (userData?.statusCode === 402) {
      setModal(!modal);
    }
  }, [userData]);

  switch (userType) {
    case 'Parent/Student':
      return <ParentLogin userData={userData} />;
    case 'Teacher':
      return <TeacherLogin />;
    default:
      return <UserTypeSelectionScreen />;
  }
};

const mapStateToProps = (state) => ({
  userData: state.Auth.Login,
});

Login.propTypes = {
  userData: object.isRequired,
};

export default connect(mapStateToProps)(Login);
