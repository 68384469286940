import http from '../helpers/Axios';

export const getStudentAssignmentList = async (params) =>
  http.get('/assignment/students/pending-assignments', { params });

export const getHistoryAssignments = async (params) =>
  http.get('/assignment/students/assignment-history', { params });

export const intelliReport = async (params) =>
  http.get(`/assignment/students/my-report?assignment=${params.assignment}`);

export const viewAssignment = async (data) =>
  http.get(
    `/assignment/teachers/view-student-assignment?assignment=${data.assignment}`
  );

export const getEmiCalculator = async (data) =>
  http.post(`/emi/v2/calculate-emi`, data);

export const createEmiPlan = async (data) =>
  http.post(`/emi/v2/create-plan`, data);

export const createEmiSuccess = async (data) =>
  http.post(`/emi/v2/razorpay/verify-transaction`, data);

export const getEmiDetails = async (id) =>
  http.get(`/emi/v2/emi-details/${id}`);

export const payInstallmentService = async (payload) =>
  http.post('/emi/v2/pay-installment', payload, {
    headers: { Authorization: payload?.token },
  });
