import React, { useState } from 'react';
import './styles/home.scss';
import plusimg from '../../../assets/images/plusimagetermsanduse.svg';
import minusimg from '../../../assets/images/minusimgtermsanduse.svg';
import { Circle } from '../../../assets/icons/IconList';

const Tautmorequestions = () => {
  const [showInfo, setShowInfo] = useState(1);

  const tautmoreConyent = [
    {
      id: 1,
      title: 'What is TautMore?',
      content: [
        `TautMore is the first-ever truly global k-12 EdTech organization built for your kid’s wholesome development. Launched in 2022, TautMore exploits artificial intelligence, machine learning, virtual reality, and learner psychology to deliver genuinely joyful, and personalized learning experiences for your kids.`,
      ],
    },

    {
      id: 2,
      title: 'How is TautMore different from other educational platforms?',
      content: [
        `TautMore’s core DNA is about delivering joyful learning experiences, making learning fun, and a lifelong habit. We exploit the best pedagogy techniques, and platform specific offerings to bring the best out of every kid -`,
      ],
      content3:
        'Also, TautMore employs only the top 2% of the teachers, and harnesses the capabilities of artificial intelligence, machine learning, and virtual reality to deliver personalized learning journeys for your kid.',
      content2: [
        'Mindmaps,',
        'Treasure shells,',
        'Brain Gym,',
        'Conceptual videos,',
        '1-to-1 sessions,',
        'Extracurricular activities',
      ],
    },

    {
      id: 3,
      title: 'Will TautMore tutors help with school homework?',
      content: [
        `TautMore teachers educate students based on the curriculum a learner opts for. As of now, we don’t help a learner with school assigned homework, but we train our students to independently solve highly complex assignments on their own.`,
      ],
    },

    {
      id: 4,
      title: 'Are TautMore teachers available on demand?',
      content: [
        `For solving doubts, students can participate in 1-to-1 teacher-student sessions.`,
      ],
    },

    {
      id: 5,
      title: 'Who can teach on TautMore?',
      content: [
        `Educators who are in the teaching profession by choice, and not by chance can apply for becoming a teacher at TautMore. If an educator is among the top 2% of the teachers who apply to our program, they can definitely become a teacher at TautMore.`,
      ],
    },

    {
      id: 6,
      title: 'What are the criteria for choosing teachers at TautMore?',
      content: [
        `Applicants go through our assessment process which is designed to identify best of the breed passionate teachers. If an educator is among the top 2% of the teachers who apply to our program, they can definitely become a teacher at TautMore.`,
      ],
    },

    {
      id: 7,
      title: 'Does TautMore have a trial version?',
      content: [
        `We do have demo sessions available to experience our platform.`,
      ],
    },

    {
      id: 8,
      title: 'Will TautMore provide a smartphone for the kid?',
      content: [
        `As of now, NO. We are device agnostic. TautMore is available on mobile, web, and soon will be available on TV apps as well. Your kid can literally learn anytime, anywhere.`,
      ],
    },

    {
      id: 9,
      title: 'Is TautMore accessible on a laptop?',
      content: [`Yes, of-course.`],
    },
  ];

  return (
    <div className='px-md-3 px-md-3 container-fluid tautmore_content'>
      <h3 className='tcontent_heading'>
        Frequently Asked <span style={{ color: '#f3722c' }}>Questions</span>{' '}
      </h3>
      <section>
        {tautmoreConyent.map((data, id) => {
          return (
            <article className='tautmore_info' key={id}>
              <div className='tautmore_flex'>
                <p className='tautmore_text mb-0'>{data.title}</p>
                <button
                  type=''
                  onClick={() =>
                    setShowInfo(showInfo === data.id ? 0 : data.id)
                  }
                >
                  {showInfo === data.id ? (
                    <img src={minusimg} alt='' />
                  ) : (
                    <img src={plusimg} alt='' />
                  )}
                </button>
              </div>
              {showInfo === data.id && (
                <p className='tautmore_text2 mb-0'>
                  {data.content?.map((dataContent, i) => {
                    return (
                      <div>
                        <p className='tautmore_text3 mb-0'>
                          {/* {i+1}{"."} */}
                          {dataContent}
                        </p>
                      </div>
                    );
                  })}
                  {data?.content2?.map((data) => (
                    <p>
                      {' '}
                      <span className='list-style-accordian'></span> {data}
                    </p>
                  ))}
                  <p>{data.content3}</p>
                </p>
              )}
            </article>
          );
        })}
      </section>
    </div>
  );
};

export default Tautmorequestions;
