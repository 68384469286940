import { func, string } from 'prop-types';
import React from 'react';
import logo from '../../../../../../assets/images/Logo.png';
import stopwatch from '../../../../../../assets/images/clock2.png';

const ExamHeader = ({
  currenttime,
  setFinishTest,
  setModal,
  modal,
  examName,
  setLoading,
}) => {
  const handlefinishTest = () => {
    setFinishTest('step2');
    setModal(!modal);
  };
  React.useEffect(() => {
    if (currenttime) {
      setLoading(false);
    }
  }, [currenttime]);
  return (
    <div className='row olympiad-head'>
      <div className='col-md-3  olympiad-head-left'>
        <img src={logo} alt='logo' />
      </div>
      <div className='col-md-9 olympiad-head-right'>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <h2>{examName}</h2>
          </div>
          <div className='col-md-3 col-sm-12 time'>
            <div className='row'>
              <div className='col-md-3 time-left'>
                <img src={stopwatch} alt='clock' />
              </div>
              <div className='col-md-9 time-right'>
                <p>Time elapsed</p>
                <h5>{currenttime}</h5>
              </div>
            </div>
          </div>
          <div className='col-md-3 finish-test'>
            <button type='button' onClick={handlefinishTest}>
              Finish test
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
ExamHeader.propTypes = {
  currenttime: string.isRequired,
  setFinishTest: func.isRequired,
  setModal: func.isRequired,
  modal: string.isRequired,
  examName: string.isRequired,
  setLoading: func.isRequired,
};
export default ExamHeader;
