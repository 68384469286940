import React from 'react';
import RevenueMain from './RevenueMain';
import CocurricularRevenue from './CocurricularRevenue';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import newTeacherRevenue from '../../../../../assets/icons/teacherIcon/revenue.svg';

const RevenueSwith = ({ Login }) => {
  return (
    <div>
      <div className='row mt-4 mb-4'>
        <div className='col-sm-5 d-flex align-items-center'>
          <div className='me-2'>
            <img src={newTeacherRevenue} width='60px' alt='student'></img>
          </div>

          <h1 className='heading'>My Revenue</h1>
        </div>
        {/* <div className='col-sm-7'>
          <p className='totalstudents'>{studentsCount || '0'} Total students</p>
        </div> */}
      </div>
      {Login?.subject?.length > 0 && <RevenueMain />}
      {Login?.co_curricular?.length > 0 && <CocurricularRevenue />}
    </div>
  );
};

RevenueSwith.propTypes = {
  Login: object.isRequired,
};

const mapStateToProps = (state) => ({
  Login: state?.Auth?.Login?.data,
});

export default connect(mapStateToProps)(RevenueSwith);
