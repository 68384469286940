import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export const WaitingLoader = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }}
  >
    <Spinner
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
      }}
      animation='border'
      role='status'
      variant='warning'
    />
  </div>
);
