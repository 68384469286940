import React from 'react';
import { func, object, string, number, bool } from 'prop-types';
import ExamHeader from './ExamHeader';
import './styles/olympiad.scss';
import ExamBody from './ExamBody';
import Spinner from 'react-bootstrap/Spinner';

const ExamSection = ({
  questionInExamData,
  currenttime,
  setEachTimeOn,
  eachcurrenttime,
  setEachtime,
  finishTest,
  setFinishTest,
  setModal,
  modal,
  setTime,
  examName,
  selectedQuestion,
  setSelectedQuestion,
  allQuesAns,
  updateQueAns,
  addQueAns,
  isOlympiad,
}) => {
  const [isLoading, setLoading] = React.useState(false);

  return (
    <div className='Olympiad-main'>
      {isLoading ? (
        <Spinner
          style={{
            textAlign: 'center',
            position: 'fixed',
            top: '50%',
            left: '50%',
          }}
          animation='border'
          role='status'
          variant='warning'
        />
      ) : (
        <>
          <ExamHeader
            questionInExamData={questionInExamData}
            currenttime={currenttime}
            setTime={setTime}
            finishTest={finishTest}
            setFinishTest={setFinishTest}
            setModal={setModal}
            modal={modal}
            examName={examName}
            setLoading={setLoading}
          />
          <ExamBody
            questionInExamData={questionInExamData}
            setEachTimeOn={setEachTimeOn}
            eachcurrenttime={eachcurrenttime}
            setEachtime={setEachtime}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            allQuesAns={allQuesAns}
            addQueAns={addQueAns}
            updateQueAns={updateQueAns}
            isOlympiad={isOlympiad}
          />
        </>
      )}
    </div>
  );
};
ExamSection.propTypes = {
  questionInExamData: object.isRequired,
  currenttime: string.isRequired,
  setEachTimeOn: func.isRequired,
  eachcurrenttime: string.isRequired,
  setEachtime: func.isRequired,
  setFinishTest: func.isRequired,
  finishTest: string.isRequired,
  setModal: func.isRequired,
  modal: string.isRequired,
  setTime: string.isRequired,
  examName: string.isRequired,
  selectedQuestion: number.isRequired,
  setSelectedQuestion: func.isRequired,
  allQuesAns: object.isRequired,
  updateQueAns: func.isRequired,
  addQueAns: func.isRequired,
  isOlympiad: bool,
};

export default ExamSection;
