import { func, array, string } from 'prop-types';

import React, { useEffect, useState } from 'react';

const Mysubjectlisttop = ({
  subjects,
  initailSubName,
  setInitialSubject,
  setInitialGrade,
  setInitalGradeId,
  initialGrade,
}) => {
  const [activebtn, setActivebtn] = useState();
  useEffect(() => {
    setActivebtn(initailSubName?.concat(initialGrade));
  }, [initailSubName, initialGrade]);
  const handleMathsColor = (data) => {
    setActivebtn(data?.name?.concat(data?.class?.name));
    setInitialSubject(data._id);
    setInitalGradeId(data.class._id);
    setInitialGrade(data.class.name);
  };

  return (
    <div className='mysubject-list'>
      <div className='dtoggle-bar w-100 flex-wrap'>
        {subjects?.map((data) => (
          <div className='toggle-maths-div  toggle-custom-maths-div w-auto m-2'>
            <button
              className={`toggle-maths-btn new-toggle-maths-btn  ${
                activebtn === data?.name?.concat(data?.class?.name)
                  ? 'mathbtn-act'
                  : 'mathbtn-un'
              }`}
              type='button'
              onClick={() => handleMathsColor(data)}
            >
              Grade {data?.class?.name} - {data?.name}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

Mysubjectlisttop.propTypes = {
  subjects: array.isRequired,
  initailSubName: string.isRequired,
  setInitialSubject: func.isRequired,
  setInitialGrade: string.isRequired,
  setInitalGradeId: string.isRequired,
  initialGrade: string.isRequired,
};

export default Mysubjectlisttop;
