import config from '../helpers/config';
import { fetchWrapper } from '../helpers/fetch-wrapper';

const baseUrl = `${config.apiBaseUrl}`;

function getMyProfile(id, token) {
  return fetchWrapper.get(`${baseUrl}/teachers/my-profile/${id}`, token);
}

function myLeavesList(data, token) {
  return fetchWrapper.get(
    `${baseUrl}/teachers/my-leaves-list/${data?.teacherId}?status=${data?.status}&pageNumber=${data?.pageNumber}&limit=${data?.limit}`,
    token
  );
}

function applyLeave(params, token) {
  return fetchWrapper.post(`${baseUrl}/teachers/apply-leave`, params, token);
}
function editLeave(params, token) {
  return fetchWrapper.put(`${baseUrl}/teachers/edit-leave`, params, token);
}

function cancelLeave(id, token) {
  return fetchWrapper.remove(`${baseUrl}/teachers/cancel-leave/${id}`, token);
}

function changeTeacherName(id, params, token) {
  return fetchWrapper.put(
    `${baseUrl}/teachers/update-record/${id}`,
    params,
    token
  );
}

function changeTeacherEmail(id, params, token) {
  return fetchWrapper.put(
    `${baseUrl}/teachers/update-record/${id}`,
    params,
    token
  );
}

function changeTeacherPhoneNumber(id, params, token) {
  return fetchWrapper.put(
    `${baseUrl}/teachers/update-record/${id}`,
    params,
    token
  );
}

function changeTeacherQualification(id, params, token) {
  return fetchWrapper.put(
    `${baseUrl}/teachers/update-record/${id}`,
    params,
    token
  );
}

function changeTeacherUniversity(id, params, token) {
  return fetchWrapper.put(
    `${baseUrl}/teachers/update-record/${id}`,
    params,
    token
  );
}

function getMyClasses(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/online-class/teachers/upcoming-classes`,
    body,
    token
  );
}

function getMyCoCurricularClasses(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/cocurricular-online-class/teachers/upcoming-classes`,
    body,
    token
  );
}

function getMyClassesHistory(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/online-class/teachers/history`,
    body,
    token
  );
}

function teacherSlotsPerDate(id, date, token) {
  return fetchWrapper.get(
    `${baseUrl}/online-class/teachers/slots-per-date?teacher=${id}&date=${date}`,
    token
  );
}

function teacherSlotsCoCurricularPerDate(id, date, token) {
  return fetchWrapper.get(
    `${baseUrl}/cocurricular-online-class/teachers/slots-per-date?teacher=${id}&date=${date}`,
    token
  );
}

function rescheduleClass(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/online-class/teachers/reschedule`,
    body,
    token
  );
}

function rescheduleCoCurricularClass(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/cocurricular-online-class/teachers/reschedule`,
    body,
    token
  );
}

function getChapterBySubjectId(body) {
  return fetchWrapper.post(`${baseUrl}/chapters/chapters-by-subject`, body);
}

function getConceptsByChapterId(body) {
  return fetchWrapper.post(`${baseUrl}/concepts/concepts-by-chapter`, body);
}

function getSubConceptsByConceptId(body) {
  return fetchWrapper.post(
    `${baseUrl}/sub-concepts/subconcepts-by-concept`,
    body
  );
}

function addTeachingHistory(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/online-class/teachers/add-history`,
    body,
    token
  );
}

function updateProfilePic(id, body, token) {
  return fetchWrapper.put(
    `${baseUrl}/teachers/update-profile-pic/${id}`,
    body,
    token
  );
}

function sendPassword(id) {
  return fetchWrapper.post(`${baseUrl}/teachers/update-password/${id}`);
}

function getPaymentHistory(id) {
  return fetchWrapper.get(`${baseUrl}/teachers/salary-history/${id}`);
}

function updatePassword(id, body, token) {
  return fetchWrapper.put(
    `${baseUrl}/teachers/update-password/${id}`,
    body,
    token
  );
}
function teacherUpdatePhoneNumberSendOtp({ teacherId, phoneNumber, token }) {
  return fetchWrapper.post(
    `${baseUrl}/teachers/update-phone-number/send-otp/${teacherId}`,
    {
      phone: phoneNumber,
    },
    token
  );
}

function teacherUpdatePhoneNumberVerifyOtp({
  teacherId,
  phoneNumber,
  otp,
  token,
}) {
  return fetchWrapper.post(
    `${baseUrl}/teachers/update-phone-number/verify-otp/${teacherId}`,
    {
      phone: phoneNumber,
      otp: otp,
    },
    token
  );
}

export const teacherDashboardServices = {
  getMyProfile,
  myLeavesList,
  applyLeave,
  editLeave,
  cancelLeave,
  changeTeacherName,
  changeTeacherEmail,
  changeTeacherPhoneNumber,
  changeTeacherQualification,
  changeTeacherUniversity,
  getMyClasses,
  getMyCoCurricularClasses,
  getMyClassesHistory,
  teacherSlotsPerDate,
  teacherSlotsCoCurricularPerDate,
  rescheduleClass,
  rescheduleCoCurricularClass,
  getChapterBySubjectId,
  getConceptsByChapterId,
  getSubConceptsByConceptId,
  addTeachingHistory,
  updateProfilePic,
  sendPassword,
  getPaymentHistory,
  updatePassword,
  teacherUpdatePhoneNumberSendOtp,
  teacherUpdatePhoneNumberVerifyOtp,
};

export default {};
