import config from '../helpers/config';
import { fetchWrapper } from '../helpers/fetch-wrapper';

const baseUrl = `${config.apiBaseUrl}`;

function startExam(params, token) {
  return fetchWrapper.post(`${baseUrl}/exam-results/start-exam`, params, token);
}
function questionInExam(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/exam-results/questions-in-exam`,
    params,
    token
  );
}
function finishExam(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/exam-results/finish-exam`,
    params,
    token
  );
}
function myReport(params, token) {
  return fetchWrapper.post(`${baseUrl}/exam-results/my-report`, params, token);
}
function previousExam(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/exam-results/previous-exams-history`,
    params,
    token
  );
}
function getUnattemptedExam(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/exam-results/unattempted-exams-history`,
    params,
    token
  );
}
function upcomingExam(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/exam-results/upcoming-exams`,
    params,
    token
  );
}
function myReportAnalytics(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/exam-results/my-report-analytics`,
    params,
    token
  );
}

export const myexamStudentServices = {
  startExam,
  questionInExam,
  finishExam,
  myReport,
  previousExam,
  upcomingExam,
  myReportAnalytics,
  getUnattemptedExam,
};

export default {};
