import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';
import Logo from '../../../../assets/tempimages/Logo.svg';
import {
  chevRight,
  eye,
  noteye,
  backArrow,
} from '../../../../assets/icons/IconList';
import { forgotPassword } from '../../../../stores/Auth/AuthAction';
import './Forgot.scss';
import { encodeString } from '../../../../helpers/utility';

const ForgotPasswordOtp = ({ emailval, setStep, userType, user }) => {
  const content = useSelector((state) => state?.Auth?.forgotPassword);
  const [sendingNewPass, setSendingNewPass] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({
    otp: '',
    newPassword: '',
    confirmPassword: '',
    all: '',
  });
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    otp: '',
    newPassword: '',
    confirmPassword: '',
  });
  const handleInput = (value, id) => {
    const updatedData = { ...formData };
    updatedData[id] = value;
    setFormData({ ...formData, ...updatedData });
  };

  const reg = new RegExp(
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$'
  );
  const checkForWhiteSpace = RegExp('[\\s]+');
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData?.otp?.length === 0 &&
      formData?.newPassword?.length === 0 &&
      formData?.confirmPassword?.length === 0
    ) {
      setErrors({
        otp: '',
        newPassword: '',
        confirmPassword: '',
        all: 'Please fill all the fields',
      });
    } else if (!formData?.otp) {
      setErrors({
        newPassword: '',
        confirmPassword: '',
        all: '',
        otp: 'Please enter OTP',
      });
    } else if (!(formData?.otp?.length === 6)) {
      setErrors({
        newPassword: '',
        confirmPassword: '',
        all: '',
        otp: 'Incorrect OTP',
      });
    } else if (checkForWhiteSpace.test(formData?.newPassword)) {
      setErrors({
        otp: '',
        confirmPassword: '',
        all: '',
        newPassword: 'Whitespace is not accepted in passwords.',
      });
    } else if (!formData?.newPassword) {
      setErrors({
        otp: '',

        confirmPassword: '',
        all: '',
        newPassword: 'Please enter new Password',
      });
    } else if (!reg.test(formData?.newPassword)) {
      setErrors({
        otp: '',
        confirmPassword: '',
        all: '',
        newPassword:
          'Password must include minimum 8 and maximum 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character',
      });
    } else if (!formData?.confirmPassword) {
      setErrors({
        otp: '',
        newPassword: '',

        all: '',
        confirmPassword: 'Please enter confirm Password',
      });
    } else if (formData?.newPassword !== formData?.confirmPassword) {
      setErrors({
        otp: '',
        all: '',
        newPassword: '',
        confirmPassword: 'Password do not match',
      });
    } else {
      setSendingNewPass(true);
      dispatch(
        forgotPassword({
          formData: {
            otp: formData?.otp,
            newPassword: encodeString(formData?.newPassword),
            confirmPassword: encodeString(formData?.confirmPassword),
            input: emailval,
          },
          userType: userType,
          onSuccess: () => {
            setErrors({
              otp: '',
              newPassword: '',
              confirmPassword: '',
              all: '',
            });
            setSendingNewPass(false);
            setSuccessPopUp(true);
            setStep(3);
          },
          onError: () => {
            setSendingNewPass(false);
            setErrors({
              otp: '',
              newPassword: '',
              confirmPassword: '',
              all: '',
            });
          },
          onError1: () => {
            setSendingNewPass(false);
            setErrors({
              otp: 'Incorrect OTP',
              newPassword: '',
              confirmPassword: '',
              all: '',
            });
          },
        })
      );
    }
  };

  useEffect(() => {
    if (content?.statusCode === 200) {
      setSendingNewPass(false);
    }

    if (content?.statusCode !== 200) {
      setSendingNewPass(false);
    }
  }, [content]);

  return (
    <div className='login-page login_main_otp'>
      <div className='login-main'>
        <div className='login-head text-center'>
          <Link to='/'>
            <img src={Logo} alt='logo' />
          </Link>
          <h3 className='mt-3 mb-0'>Create New Password</h3>
          <h4 className='mt-3 mb-5'>Set the new password for your account</h4>
        </div>
        <form onSubmit={handleSubmit}>
          <label htmlFor='otp'>
            Email/Phone verification OTP
            <input
              className={`${errors?.otp?.length === 6 && 'validationError'}`}
              placeholder='Enter the OTP sent to your email/phone'
              type='text'
              name='otp'
              id='otp'
              value={formData.otp}
              maxlength='6'
              onChange={(e) => handleInput(e.target.value, 'otp')}
            />
          </label>
          {errors?.otp && (
            <p className='error-msg'>
              <span>
                <b>!</b>
              </span>
              {errors?.otp}
            </p>
          )}
          <label htmlFor='newPassword' className='password-label'>
            New Password*
            <input
              className={`${errors?.newPassword && 'validationError'}`}
              placeholder='Enter new Password'
              type={showPassword ? 'text' : 'password'}
              name='newPassword'
              id='newPassword'
              value={formData.newPassword}
              onChange={(e) => handleInput(e.target.value, 'newPassword')}
            />
            <button
              type='button'
              onClick={() => setshowPassword(!showPassword)}
            >
              {showPassword ? eye : noteye}
            </button>
          </label>
          {errors?.newPassword && (
            <p className='error-msg'>
              <span>
                <b>!</b>
              </span>
              {errors?.newPassword}
            </p>
          )}

          <label htmlFor='confirmPassword' className='password-label'>
            Confirm Password*
            <input
              className={`${errors?.confirmPassword && 'validationError'}`}
              type={showConfirmPassword ? 'text' : 'password'}
              name='confirmPassword'
              id='confirmPassword'
              placeholder='Re-enter new password'
              value={formData.confirmPassword}
              onChange={(e) => handleInput(e.target.value, 'confirmPassword')}
            />
            <button
              className='forgotPasswordIconBtn'
              type='button'
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? eye : noteye}
            </button>
          </label>
          {errors?.confirmPassword && (
            <p className='error-msg'>
              <span>
                <b>!</b>
              </span>
              {errors?.confirmPassword}
            </p>
          )}

          {errors?.all && (
            <p className='error-msg'>
              <span>
                <b>!</b>
              </span>
              {errors?.all}
            </p>
          )}

          <div className='text-center'>
            <button type='submit' value='Login' className='submit-btn'>
              {sendingNewPass ? (
                <Spinner animation='border' variant='light' />
              ) : (
                'Reset Password'
              )}
              {/* <span>{chevRight}</span> */}
            </button>

            <p className='not-register '>
              <Link
                to={`/forgotPasswordConfirmation/${user}`}
                className='green'
              >
                <span style={{ marginRight: '4px' }}>{backArrow}</span>
                <span>Back</span>
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};
ForgotPasswordOtp.propTypes = {
  emailval: string.isRequired,
};
export default ForgotPasswordOtp;
