import { bool, object, string } from 'prop-types';
import React from 'react';
import { convertSecsToMin } from '../BrainGym/QueAnswer/textHelper';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation } from 'react-router-dom';
import clock from '../../../../../assets/images/clock2.png';
import intelligence from '../../../../../assets/images/intelligence.png';
import clipboard from '../../../../../assets/images/clipboard.png';
import { chevRightIcon } from './icons';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

const TestHeader = ({
  takeTestData,
  statusVal,
  topBar,
  timeTakenToAnswer,
  stat,
  takeTestResponse,
}) => {
  // useEffect(() => {
  //   console.log(
  //     'the data',
  //     takeTestResponse,
  //     takeTestData,
  //     statusVal,
  //     topBar,
  //     timeTakenToAnswer
  //   );
  // }, [takeTestResponse, takeTestData]);

  let history = useHistory();
  const search = useLocation().search;
  const chap = new URLSearchParams(search).get('chapter');
  const subCon = new URLSearchParams(search).get('subtopic');
  return (
    <>
      <div className='row  pl-6 pl-lg-auto'>
        {/* <div className='col-12 d-flex align-items-center justify-content-flex-start'> */}
        {takeTestData === '' ? (
          // <div
          //   className={`${topBar ? 'col-7' : 'col-12'} d-flex align-items-start`}
          // >
          <>
            <div
              className={`${
                !topBar ? 'col-lg-7' : 'col-lg-7'
              } col-12 mt-3 mt-lg-4 mb-2 mb-lg-4 test-header-left`}
            >
              <h3 className='test-header'>Learn with an example</h3>
              <div className='myconcept-head-main'>
                <div className='bradcrumWrapper'>
                  <Breadcrumb>
                    <Breadcrumb.Item
                      onClick={() =>
                        history.push(
                          `/chapters/${takeTestResponse?.data?.data?.subjectName}`
                        )
                      }
                    >
                      {takeTestResponse?.data?.data?.subjectName}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => history.goBack()}>
                      {chap}
                    </Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{conc}</Breadcrumb.Item> */}
                    <Breadcrumb.Item active>
                      <a href='#'>{subCon}</a>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
            {!topBar ? (
              <>
                <div
                  className='d-lg-flex d-none  align-items-end justify-content-between col-12 col-lg-5  mb-lg-4 time'
                  // style={{ marginTop: '82px' }}
                >
                  <div className='col-md-3  align-items-center'>
                    <div className='congrats-detail-sub-part custom-congrats-detail-sub-part-wrapper'>
                      <div className='congrats-detail-sub-part-right '>
                        <p className='m-0 text-center'>Time taken </p>
                        <h5 className='text-center'>
                          {convertSecsToMin(timeTakenToAnswer)}
                        </h5>
                      </div>
                    </div>

                    {/* {stat !== 'COMPLETED' ? (
                        <button
                          type='button'
                          className='submit-answer-button ml-2 mt-0'
                          onClick={() => history.push('/dashboard/subject')}
                          style={{ marginLeft: '1.5rem' }}
                          disabled={false}
                        >
                          <div
                            className={`button-wrapper-active d-flex align-items-center justify-content-between`}
                            style={{ width: '229px' }}
                          >
                            <span>End Test</span>
                            <div className='icon-wrapper d-flex align-items-center justify-content-center'>
                              {chevRightIcon()}
                            </div>
                          </div>
                        </button>
                      ) : (
                        ''
                      )} */}
                  </div>
                  <div className='col-md-4'>
                    <div className='congrats-detail-sub-part custom-congrats-detail-sub-part-wrapper'>
                      {/* <img src={intelligence} alt='stopwatch' /> */}
                      <div className='congrats-detail-sub-part-right'>
                        <p className='m-0 text-center'>Questions answered</p>
                        <h5 className='text-center'>
                          {statusVal?.rightAnswers
                            ? statusVal?.rightAnswers
                            : takeTestResponse.data?.data?.rightAnswers}{' '}
                          out of{' '}
                          {statusVal?.answeredQuestions
                            ? statusVal?.answeredQuestions
                            : takeTestResponse.data?.data?.result?.length}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='congrats-detail-sub-part custom-congrats-detail-sub-part-wrapper'>
                      {/* <img src={clipboard} alt='stopwatch' /> */}
                      <div className='congrats-detail-sub-part-right'>
                        <p className='m-0 text-center'>Smart score</p>
                        <h5 className='text-center'>
                          {' '}
                          {statusVal?.totalMarksObtained
                            ? statusVal?.totalMarksObtained
                            : takeTestResponse.data?.data?.totalMarksObtained}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            {/* </div> */}
          </>
        ) : (
          <h3 className='test-header' style={{ textAlign: 'center' }}>
            {takeTestData}
          </h3>
        )}
        {topBar && takeTestData === '' && (
          <div
            className={`d-lg-flex d-none  align-items-end justify-content-between col-12 col-lg-5  mb-lg-4 time`}
            // style={{ marginTop: '82px' }}
          >
            {/* <div className='row'> */}
            <div className='col-md-3  align-items-center'>
              <div className='congrats-detail-sub-part custom-congrats-detail-sub-part-wrapper'>
                <div className='congrats-detail-sub-part-right '>
                  <p className='m-0 text-center'>Time taken </p>
                  <h5 className='text-center'>
                    {convertSecsToMin(statusVal?.timeTakenInSecs)}
                  </h5>
                </div>
              </div>

              {/* {stat !== 'COMPLETED' ? (
                        <button
                          type='button'
                          className='submit-answer-button ml-2 mt-0'
                          onClick={() => history.push('/dashboard/subject')}
                          style={{ marginLeft: '1.5rem' }}
                          disabled={false}
                        >
                          <div
                            className={`button-wrapper-active d-flex align-items-center justify-content-between`}
                            style={{ width: '229px' }}
                          >
                            <span>End Test</span>
                            <div className='icon-wrapper d-flex align-items-center justify-content-center'>
                              {chevRightIcon()}
                            </div>
                          </div>
                        </button>
                      ) : (
                        ''
                      )} */}
            </div>
            <div className='col-md-4'>
              <div className='congrats-detail-sub-part custom-congrats-detail-sub-part-wrapper'>
                {/* <img src={intelligence} alt='stopwatch' /> */}
                <div className='congrats-detail-sub-part-right'>
                  <p className='m-0 text-center'>Questions answered</p>
                  <h5 className='text-center'>
                    {statusVal?.rightAnswers ? statusVal?.rightAnswers : 0} out
                    of{' '}
                    {statusVal?.answeredQuestions
                      ? statusVal?.answeredQuestions
                      : 0}
                  </h5>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='congrats-detail-sub-part custom-congrats-detail-sub-part-wrapper'>
                {/* <img src={clipboard} alt='stopwatch' /> */}
                <div className='congrats-detail-sub-part-right'>
                  <p className='m-0 text-center'>Smart score</p>
                  <h5 className='text-center'>
                    {' '}
                    {statusVal?.totalMarksObtained
                      ? statusVal?.totalMarksObtained
                      : 0}
                  </h5>
                </div>
              </div>
            </div>
            {/* <div className='col-md-3'>
                    <div className='congrats-detail-sub-part congrats-detail-sub-part-wrapper'>
                      <img src={clock} alt='stopwatch' />
                      <div className='congrats-detail-sub-part-right'>
                        <p className='m-0 text-center'>Time taken </p>
                        <h5 className='text-center'>
                          {convertSecsToMin(statusVal?.totalTimeTakenInSecs)}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className='congrats-detail-sub-part congrats-detail-sub-part-wrapper'>
                      <img src={intelligence} alt='stopwatch' />
                      <div className='congrats-detail-sub-part-right'>
                        <p className='m-0 text-center'>Accuracy</p>
                        <h5 className='text-center'>
                          {Math.round(statusVal?.accuracy)} %
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='congrats-detail-sub-part congrats-detail-sub-part-wrapper'>
                      <img src={clipboard} alt='stopwatch' />
                      <div className='congrats-detail-sub-part-right'>
                        <p className='m-0 text-center'>Right answers</p>
                        <h5 className='text-center'>
                          {statusVal?.rightAnswers} out of{' '}
                          {statusVal?.totalQuestions}
                        </h5>
                      </div>
                    </div>
                  </div> */}
            {/* <div className='col-md-3'>
                    {stat !== 'COMPLETED' ? (
                      <button
                        type='button'
                        className='submit-answer-button ml-2 mt-0 w-100'
                        onClick={() => history.push('/dashboard/subject')}
                        disabled={false}
                      >
                        <div
                          className={`button-wrapper-active d-flex align-items-center justify-content-between`}
                          style={{ width: '229px' }}
                        >
                          <span>End Test</span>
                          <div className='icon-wrapper d-flex align-items-center justify-content-center'>
                            {chevRightIcon()}
                          </div>
                        </div>
                      </button>
                    ) : (
                      ''
                    )}
                  </div> */}
            {/* </div> */}
          </div>
        )}
        {/* </div> */}
      </div>
    </>
  );
};

TestHeader.propTypes = {
  takeTestData: string.isRequired,
  statusVal: object.isRequired,
  topBar: bool.isRequired,
  takeTestResponse: object.isRequired,
};

export default TestHeader;
