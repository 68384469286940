import React, { useEffect } from 'react';
import { readText, renderText } from '../../BrainGym/QueAnswer/textHelper';
import { array, func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import OlympiadAnswerOption from './OlympiadAnswerOption';
import {
  checkDragDropSnunscramble,
  findKeyByValue,
} from '../../BrainGym/QueAnswer/questionHelper';
import { startListening } from '../startLearning';
import speaker from '../../../../../../assets/images/speaker.png';

const OlympiadQueAnsWrapper = ({
  selectedOption,
  setSelectedOption,
  qIndex,
  itemId,
  description,
  options,
  questionObject,
  setIsSubmitValid,
}) => {
  useEffect(() => {
    if (selectedOption?.length) setIsSubmitValid(true);
    else setIsSubmitValid(false);
  }, [selectedOption]);

  const multipleSelectWord = (index) => {
    if (selectedOption.includes(index)) {
      const filteredValues = selectedOption.filter((val) => val !== index);
      const sortedOptions = filteredValues.sort(function (a, b) {
        return a - b;
      });
      setSelectedOption(sortedOptions);
    } else {
      const filteredValues = [
        ...selectedOption.filter((i) => i !== -1 && i !== index),
        index,
      ];
      const sortedOptions = filteredValues.sort(function (a, b) {
        return a - b;
      });
      setSelectedOption(sortedOptions);
    }
  };

  const questionType = findKeyByValue(questionObject?.solutionType);
  if (checkDragDropSnunscramble(questionType)) return null;
  const extractContent = (s) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };
  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          {findKeyByValue(questionObject?.solutionType) === 'passage' ? (
            <div>
              <div className='question-box'>
                <h4>
                  Question
                  {findKeyByValue(questionObject?.solutionType) === 'passage' &&
                  qIndex > 0
                    ? `. ${qIndex}`
                    : null}
                </h4>
                <h2>
                  {' '}
                  <span>{renderText(description)}</span>
                </h2>
              </div>
            </div>
          ) : (
            <div className='question-box'>
              <h4 className='d-flex'>
                {questionObject?.title == '' || questionObject?.title == null
                  ? findKeyByValue(questionObject?.solutionType)
                  : renderText(questionObject?.title)}
              </h4>
              <div className='col-12 d-flex align-items-center justify-content-between'>
                <h2 className='col-8'>
                  {findKeyByValue(questionObject?.solutionType) ===
                  'select-the-suitable-words' ? (
                    extractContent(questionObject?.description)
                      ?.split(' ')
                      ?.map((item, index) => {
                        return (
                          <button
                            className={`${
                              selectedOption.includes(index)
                                ? 'olympiad_active_btn'
                                : ''
                            }`}
                            style={{ marginRight: '5px' }}
                            key={item}
                            onClick={() => multipleSelectWord(index)}
                          >
                            {item}
                          </button>
                        );
                      })
                  ) : (
                    <span>{renderText(description)}</span>
                  )}
                </h2>

                <button
                  type='button'
                  onClick={() =>
                    startListening(readText(questionObject.description))
                  }
                >
                  {' '}
                  <img
                    className='span-one speaker-btn'
                    src={speaker}
                    alt='speaker icon'
                  />
                </button>
              </div>
            </div>
          )}

          {options?.length ? (
            <span className='answer-title mt-4'>Select your answer</span>
          ) : (
            ''
          )}
          <div className='question-options-wrapper selectTyleType'>
            {options?.map(
              (item, ind) =>
                (item?.text !== '' || item?.image !== '') && (
                  <OlympiadAnswerOption
                    questionObject={questionObject}
                    item={item}
                    itemId={itemId}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    ind={ind}
                    qIndex={qIndex - 1}
                  />
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
OlympiadQueAnsWrapper.propTypes = {
  questionInExamData: object.isRequired,
  selectedQuestion: string.isRequired,
  selectedOption: string.isRequired,
  setSelectedOption: func.isRequired,
  allQuesAns: array.isRequired,
  qIndex: string.isRequired,
  description: string.isRequired,
  options: array.isRequired,
  itemId: string.isRequired,
  addBookmark: func.isRequired,
};
const mapStateToProps = (state) => ({
  allQuesAns: state.MyExam.allQuesAns,
});

const mapDispatchToProps = (dispatch) => ({
  updateQueAns: (data) => dispatch(updateAllQueAnsAction(data)),
  addQueAns: (data) => dispatch(addAllQueAnsAction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OlympiadQueAnsWrapper);
