import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { func, array } from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import Select from 'react-select';
import {
  myLeavesListAction,
  cancelLeaveAction,
  applyLeaveAction,
} from '../../../../../stores/TeacherDashboard/TeacherDashboardAction';
import LeaveConfirmPopup from './LeaveConfirmPopup';
import './MyLeavesFilter.scss';
import { editIcon } from '../../../../../assets/icons/IconList';
import yellow from '../../../../../assets/images/yellowIcon.png';
import green from '../../../../../assets/images/greenIcon.png';
import red from '../../../../../assets/images/red.png';
import { useHistory } from 'react-router';

const MyLeaves = ({
  myLeavesList1,
  myLeaves,
  applyLeaveResponse,
  handleEdit,
  editLeaveResponse,
}) => {
  const [model, setModel] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState('');
  const [appliedFromDate, setAppliedFromDate] = useState('');
  const [appliedToDate, setAppliedToDate] = useState('');
  const [totalAppliedLeave, setTotalAppliedLeave] = useState('');
  const [hidehead, setHideHead] = useState(false);
  const [tryTrig, setTryTrig] = useState(false);
  const [hideMyLeave, setHideMyLeave] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // if (applyLeaveResponse?.data || editLeaveResponse?.data) {
    const data = {
      status: '',
      pageNumber: 1,
      limit: 10,
    };
    myLeavesList1(data);
    // }
  }, [applyLeaveResponse, editLeaveResponse]);

  useEffect(() => {
    const oneDay = 24 * 60 * 60 * 1000;
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const selectedData = myLeaves?.data?.leaveList?.filter(
      (leave) => leave?._id === selectedLeave
    );

    let frmDate = new Date(selectedData && selectedData[0]?.from);
    const fromLeave = `${
      month[frmDate.getMonth()]
    } ${frmDate.getDate()}, ${frmDate.getFullYear()}`;
    setAppliedFromDate(fromLeave);
    frmDate = `${frmDate.getFullYear()}-${
      frmDate.getMonth() + 1
    }-${frmDate.getDate()}`;

    let toDate = new Date(selectedData && selectedData[0]?.to);
    const toLeave = `${
      month[toDate.getMonth()]
    } ${toDate.getDate()}, ${toDate.getFullYear()}`;
    setAppliedToDate(toLeave);
    toDate = `${toDate.getFullYear()}-${
      toDate.getMonth() + 1
    }-${toDate.getDate()}`;

    const fromLeaveDay = new Date(frmDate.replace(/-/g, ', '));
    const toLeaveDay = new Date(toDate.replace(/-/g, ', '));
    const totalLeaveDay = Math.round(
      Math.abs((fromLeaveDay - toLeaveDay) / oneDay)
    );
    setTotalAppliedLeave(totalLeaveDay);
  }, [selectedLeave]);

  const getStatusIcon = (status) => {
    if (status === 'approved') {
      return green;
    }

    if (status === 'denied') {
      return red;
    }

    return yellow;
  };

  const changeEditleave = (id) => {
    setModel(true);
    setSelectedLeave(id);
  };

  const DropOptions = [
    { value: 'all', label: 'All' },
    { value: 'pending', label: 'Pending' },
    { value: 'approved', label: 'Approved' },
    { value: 'denied', label: 'Denied' },
  ];

  const [filtered, setFiltered] = useState(false);
  const [filteredArray, setFilteredArray] = useState([]);

  const filterLeaves = (value) => {
    setFiltered(true);
    const array1 = myLeaves?.data?.leaveList;

    if (value === 'all') {
      return setFilteredArray(array1);
    }
    const filteredArray1 = array1.filter((item) => item.status === value);

    return setFilteredArray(filteredArray1);
  };

  const handleEditBtn = (data) => {
    handleEdit(data);
    // history.push({
    //   pathname: '/teacher/calendar',
    //   search: '?editleave=true',
    // });
  };

  useEffect(() => {
    if (tryTrig) {
      if (filteredArray.length > 0) {
        setHideHead(false);
      } else {
        setHideHead(true);
      }
    }
  }, [filteredArray]);

  useEffect(() => {
    if (myLeaves?.message === 'No data found') {
      setHideMyLeave(true);
    } else {
      setHideMyLeave(false);
    }
  }, [myLeaves]);

  const renderLeaves = () => {
    if (myLeaves?.message === 'No data found') {
      return <h4>No pending leaves.</h4>;
    }

    if (!myLeaves.data) {
      return (
        <div className='text-center' style={{ width: '75vw' }}>
          <p>Loading</p>
          <Spinner animation='grow' variant='primary' size='sm' />
        </div>
      );
    }

    if (!filtered) {
      return myLeaves?.data?.leaveList?.map((data, index) => {
        const statusColor = data?.status === 'denied' ? 'red' : 'orange';

        return (
          <tr>
            <td>{index + 1}</td>
            <td> {moment(data?.from).format('MMM Do, YYYY')} </td>
            <td>{moment(data?.to).format('MMM Do, YYYY')}</td>
            <td className='leave-reason'>{data?.reason}</td>
            <td className={`calander-customeTd ${data?.status}`}>
              <div className='calander-divwrap'>
                <img
                  style={{ height: '20px', width: '20px', marginRight: '10px' }}
                  src={getStatusIcon(data?.status)}
                  alt='S'
                />
                <p
                  style={{
                    color:
                      data?.status === 'approved' ? '#32CD32' : statusColor,
                    textTransform: 'capitalize',
                  }}
                >
                  {data?.status}
                </p>
              </div>
            </td>
            <td className='edit'>
              {data?.status === 'pending' && (
                <>
                  <button className='' onClick={() => handleEditBtn(data)}>
                    {editIcon}
                  </button>
                  <button
                    type='button'
                    className='edit ml-2'
                    onClick={() => changeEditleave(data?._id)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </td>
          </tr>
        );
      });
    }

    if (filtered && !filteredArray.length) {
      return (
        <h4
          style={{
            width: '100vh',
            margin: '0px auto',
          }}
        >
          No pending leaves
        </h4>
      );
    }

    if (filtered) {
      return filteredArray.map((data, index) => {
        const statusColor = data?.status === 'denied' ? 'red' : 'orange';

        return (
          <tr>
            <td>{index + 1}</td>
            <td>{moment(data?.from).format('MMM Do, YYYY')}</td>
            <td>{moment(data?.to).format('MMM Do, YYYY')}</td>
            <td className='leave-reason'>{data?.reason}</td>
            <td className={`calander-customeTd ${data?.status}`}>
              <div className='calander-divwrap'>
                <img
                  style={{ height: '20px', width: '20px', marginRight: '10px' }}
                  src={getStatusIcon(data?.status)}
                  alt='S'
                />
                <p
                  style={{
                    color:
                      data?.status === 'approved' ? '#32CD32' : statusColor,
                    textTransform: 'capitalize',
                  }}
                >
                  {data?.status}
                </p>
              </div>
            </td>
            <td className='edit'>
              {data?.status === 'pending' && (
                <>
                  <button className='' onClick={() => handleEditBtn(data)}>
                    {editIcon}
                  </button>
                  <button
                    type='button'
                    className='edit ml-2'
                    onClick={() => changeEditleave(data?._id)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </td>
          </tr>
        );
      });
    }
    return '';
  };

  return (
    <>
      <div className='row leave-main'>
        <div className='row'>
          <div className='col-9'>
            <h3>My leaves</h3>
          </div>
          {!hideMyLeave && (
            <div className='col-3'>
              <Select
                placeholder='Status'
                options={DropOptions}
                // value={leaveStatus}
                onChange={(value) => {
                  filterLeaves(value.value);
                  setTryTrig(true);
                }}
                className='width-size font-font'
              />
            </div>
          )}
        </div>
        <div className='leave-table'>
          {!hidehead && !hideMyLeave ? (
            <table>
              <thead>
                <tr>
                  <td />
                  <td>FROM</td>
                  <td>TO</td>
                  <td className='comments'>COMMENTS</td>
                  <td>STATUS</td>
                  <td />
                </tr>
              </thead>
              <tbody>{renderLeaves()}</tbody>
            </table>
          ) : (
            hidehead && (
              <h4
                style={{
                  width: '100vh',
                  margin: '0px auto',
                  // marginTop: '100px',
                  // paddingBottom: '75px',
                }}
              >
                No pending leaves
              </h4>
            )
          )}
          {hideMyLeave && (
            <h4
              style={{
                width: '100vh',
                margin: '0px auto',
              }}
            >
              No pending leaves.
            </h4>
          )}
        </div>
      </div>
      <LeaveConfirmPopup
        model={model}
        handleModel={setModel}
        fromDate={appliedFromDate}
        toDate={appliedToDate}
        totalDay={totalAppliedLeave}
        selectedLeave={selectedLeave}
        myLeavesList1={myLeavesList1}
        totalAppliedLeave={totalAppliedLeave + 1}
      />
    </>
  );
};

MyLeaves.propTypes = {
  myLeavesList1: func.isRequired,
  myLeaves: array.isRequired,
  applyLeaveResponse: array.isRequired,
};

const mapStateToProps = (state) => ({
  myLeaves: state.TeacherDashboard.myLeavesList,
  applyLeaveResponse: state.TeacherDashboard.applyLeave,
  cancelLeaveResponse: state.TeacherDashboard.cancelLeave,
  editLeaveResponse: state.TeacherDashboard.editLeave,
});

const mapDispatchToProps = (dispatch) => ({
  myLeavesList1: (data) => dispatch(myLeavesListAction(data)),
  cancelLeave: (id) => dispatch(cancelLeaveAction(id)),
  applyLeave: (data) => dispatch(applyLeaveAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyLeaves);
