import React, { useState, useEffect } from 'react';
import { string, array } from 'prop-types';
import { useHistory } from 'react-router-dom';
import VideoMap from './temp';
import { connect } from 'react-redux';
const Myconceptdesc = ({
  startQuestions,
  initialSubject,
  chapterId,
  conceptId,
  video,
  content,
  studentId,
  userData,
  initailSubName,
  chapterName,
  conceptName,
  subConName,
  subConcData,
  isTestCompleted,
  testResults,
  setTestResults,
}) => {
  const history = useHistory();
  const [currVideo, setCurrVideo] = useState(null);
  const [playVideo, setPlayVideo] = useState(null);

  const openCurrentVideo = (id) => {
    if (currVideo !== id) {
      setCurrVideo(id);
    }
  };

  const playCurrentVideo = (id) => {
    if (playVideo !== id) {
      setPlayVideo(id);
    }
  };

  useEffect(() => {
    setCurrVideo(video[0]?._id);
  }, [video]);

  return (
    <div className='myconcept-desc'>
      <div className='row desc-top'>
        <div className='col-md-7'>
          <div className='d-flex align-items-center'>
            <h5>{subConName}</h5>
          </div>
        </div>
      </div>
      <VideoMap
        video={video}
        openCurrentVideo={openCurrentVideo}
        currVideo={currVideo}
        playCurrentVideo={playCurrentVideo}
        playVideo={playVideo}
        content={content}
        userData={userData}
        subConcData={subConcData}
      />
    </div>
  );
};

Myconceptdesc.propTypes = {
  startQuestions: string.isRequired,
  initialSubject: string.isRequired,
  chapterId: string.isRequired,
  conceptId: string.isRequired,
  video: array.isRequired,
  content: array.isRequired,
  subConName: string.isRequired,
};
const mapStateToProps = (state) => ({
  studentId: state.Auth.Login?.data?._id,
  userData: state.Auth.Login,
});

export default connect(mapStateToProps)(Myconceptdesc);
