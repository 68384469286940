import React from 'react';
import { object, string } from 'prop-types';
import SubjectPerformanceScore from './SubjectPerformanceScore';
import perform_green_one from '../../../../../../assets/images/performance-green1.svg';
import perform_green_two from '../../../../../../assets/images/performance-green2.svg';
import perform_yellow_one from '../../../../../../assets/images/performance-yellow1.svg';
import perform_yellow_two from '../../../../../../assets/images/performance-yellow2.svg';

const SubjectPerformance = ({ studPerformance, subName, StudentName }) => {
  const topPerform = studPerformance?.topPerformance;
  const worstPerfrom = studPerformance?.worstPerformance;
  return (
    <div className='sub-performance'>
      <div className='sub-performance-head'>
        <h3>Subject Performance</h3>
        <p>{subName}</p>
      </div>
      {!worstPerfrom.length > 0 && !topPerform.length > 0 && (
        <div
          className='sub-performance-common sub-performance-left d-flex justify-content-center align-items-center'
          style={{ width: '100%' }}
        >
          <div className=''>
            <span
              style={{
                height: '100px',

                color: '#fca834',
                fontWeight: '500',
              }}
            >
              {StudentName}
            </span>{' '}
            has not completed any assignments. Once submitted, a performance
            report will be generated.
          </div>
        </div>
      )}

      {(worstPerfrom.length > 0 || topPerform.length > 0) && (
        <div>
          <div className='sub-performance-common sub-performance-left'>
            {worstPerfrom.length > 0 && (
              <SubjectPerformanceScore
                heading={worstPerfrom[0]?.subconceptName}
                score={worstPerfrom[0]?.accuracy}
                desc='You need to focus more on this topic. You’ll get there!'
                className='error error-left'
                type='worst'
              />
            )}

            {topPerform.length > 0 && (
              <SubjectPerformanceScore
                heading={topPerform[0]?.subconceptName}
                score={topPerform[0]?.accuracy}
                desc='You have improved in this topic! Good work!'
                className='success'
              />
            )}
          </div>
          <div className='sub-performance-center'>
            {topPerform.length > 0 && (
              <div className='one-line common-line'>
                <img src={perform_green_one} alt='performance' />
              </div>
            )}
            {worstPerfrom.length > 0 && (
              <div className='two-line common-line'>
                <img src={perform_yellow_one} alt='performance' />
              </div>
            )}
            {worstPerfrom.length > 1 && (
              <div className='three-line common-line'>
                <img src={perform_yellow_two} alt='performance' />
              </div>
            )}
            {topPerform.length > 1 && (
              <div className='four-line common-line'>
                <img src={perform_green_two} alt='performance' />
              </div>
            )}
          </div>
          <div className='sub-performance-common sub-performance-right'>
            {worstPerfrom.length > 1 && (
              <SubjectPerformanceScore
                heading={worstPerfrom[1]?.subconceptName}
                score={worstPerfrom[1]?.accuracy}
                desc='You need to focus more on this topic. You’ll get there!'
                className={`error error-right ${
                  !topPerform.length === 1 ? 'moveup' : ''
                }`}
              />
            )}
            {topPerform.length > 1 && (
              <SubjectPerformanceScore
                heading={topPerform[1]?.subconceptName}
                score={topPerform[1]?.accuracy}
                desc='You have improved in this topic! Good work!'
                className='success'
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

SubjectPerformance.propTypes = {
  studPerformance: object.isRequired,
  subName: string.isRequired,
};

export default SubjectPerformance;
