import React, { useEffect } from 'react';
import { array } from 'prop-types';
import moment from 'moment';
import Countdown from 'react-countdown';
import Warning from '../../../../../assets/images/warning.svg';
import stopwatch from '../../../../../assets/images/clock2.png';
import Gradeicon from '../../../../../assets/images/gradeicon.svg';
import Studenticon from '../../../../../assets/images/students.svg';
import Testaudiovideo from '../../../../../assets/images/testaudiovideo.svg';
import { useCookies } from 'react-cookie';

import {
  addCoCurrTeacherHistory,
  coCurrStudentJoinClass,
} from '../../../../../services/teacherAssignment';
import { toast } from 'react-toastify';
const OnlineClass = (props) => {
  const { classList } = props;
  // const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  const studentData = JSON.parse(localStorage.getItem('taut-student'));

  // useEffect(() => {
  //   if (classList) {
  //     addTeacherHistory(classList[0]);
  //   }
  // }, []);

  const addTeacherHistory = async (classData) => {
    const data = {
      schedule: classData.scheduleId,
      activity: classData.subjectId,
    };
    try {
      const res = await addCoCurrTeacherHistory(data);
      if (res.status === 200) {
        joinClassCoCurr(classData);
      }
    } catch (error) {}
  };

  const now = moment();
  const classTime = moment(classList?.[0]?.startTime);

  const joinClassCoCurr = async (data) => {
    const obj = {
      schedule: data.scheduleId,
    };
    try {
      const res = await coCurrStudentJoinClass(obj);
      if (res.status === 200) {
        window.open(res?.data?.data?.meetingInfo?.startUrl, '_blank');
        // window.open(
        //   `https://elearn.tautmore.com/?param1=${window.btoa(
        //     1
        //   )}&param2=${window.btoa(
        //     studentData?.data?.accessToken
        //   )}&param3=${window.btoa(
        //     res?.data?.data?.meetingInfo?.meetingId
        //   )}&param4=${window.btoa(
        //     studentData?.data?.fullName
        //   )}&param5=${window.btoa(
        //     res?.data?.data?.meetingInfo?.encryptedPassword
        //   )}`,
        //   '_blank'
        // );
      }
    } catch (error) {}
  };

  const handleJoin = async (data) => {
    const targetDateTimeString = data?.startTime; // Set your target date and time here
    const targetDateTime = new Date(targetDateTimeString);
    const targetTime = targetDateTime.getTime();
    const currentTime = new Date().getTime(); // Get current time in milliseconds
    const twoMinutesInMilliseconds = 2 * 60 * 1000; // 2 minutes in milliseconds
    if (targetTime - currentTime <= twoMinutesInMilliseconds) {
      addTeacherHistory(data);
    } else {
      toast.error('You can join 2 minutes before');
    }
  };

  const renderer = ({ hours, minutes, seconds, days }) => {
    let dayNumber = '';
    if (days > 1) {
      dayNumber = 'days';
    } else if (days === 1) {
      dayNumber = 'day';
    }
    return (
      <span>
        {days > 0 && days} {days > 0 && dayNumber} {hours} hrs {minutes} mins{' '}
        {seconds} s{' '}
      </span>
    );
  };

  return (
    <div className='main1'>
      <div className='main2'>
        <div className='onlineclassmain'>
          <div>
            <p className='onlineHeading d-flex align-items-center justify-content-center'>
              <img src={Warning} alt='warning' className='rawning me-md-2' />
              Online class alert!
            </p>
          </div>
          <div>
            <p className='onlineTime'>
              <img className='rawnimg me-md-2' src={stopwatch} alt='clock' />
              Starts in
              <Countdown
                date={Date.now() + classTime.diff(now)}
                renderer={renderer}
              />
            </p>
          </div>
        </div>
        <div className='col-md-12 col-sm-12 onlineclasssubject'>
          <div className='row'>
            <div className='col-sm-3 col-md-3 floatleft subdivmain align-items-center'>
              <div className='col-sm-4 subimgdiv me-md-2'>
                <img
                  src='https://tm-admin-images.s3.amazonaws.com/subjects/dance.png'
                  alt='LapIcon'
                />
              </div>
              <div className='col-sm-8 subtopicsec'>
                <p className='onlineSubTitle'>{classList?.[0]?.subject}</p>
              </div>
            </div>
            <div className='col-md-6 col-sm-6'>
              <div className='row middlediv'>
                <div className='col-sm-4 d-flex align-items-center justify-content-center'>
                  <img className='me-md-2' src={Gradeicon} alt='Gradeicon' />
                  <span>Grade {classList?.[0]?.grade}</span>
                </div>
                <div className='col-sm-4 d-flex align-items-center justify-content-center'>
                  <img
                    className='me-md-2'
                    src={Studenticon}
                    alt='Studenticon'
                  />
                  <span>{classList?.[0]?.numberOfStudents}</span>
                </div>
                <div className='col-sm-4 d-flex align-items-center justify-content-center'>
                  <img
                    style={{ height: '30px', width: '30px' }}
                    src={stopwatch}
                    alt='Clock'
                  />
                  <span>{classList?.[0]?.duration} minutes</span>
                </div>
              </div>
            </div>
            <div className='col-sm-3 col-md-3 floatleft'>
              <div className='jionlink'>
                <button type='button' onClick={() => handleJoin(classList[0])}>
                  Join Link
                </button>
              </div>
              {/* <div className='audioLinkdiv'>
                <a href='/' className='audioLink'>
                  <img className='testicon' src={Testaudiovideo} alt='clock' />
                  Test audio & video
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OnlineClass.propTypes = {
  classList: array.isRequired,
};

export default OnlineClass;
