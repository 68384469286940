import { array, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import ExamQuestionSection from './ExamQuestionSection';

const ExamRightsidepart = ({
  questionInExamData,
  selectedQuestion,
  setSelectedQuestion,
  selectedOption,
  setSelectedOption,
  allQuesAns,
  setEachTimeOn,
  eachcurrenttime,
  setEachtime,
  updateQueAns,
  addQueAns,
}) => {
  const [show, setShow] = useState(false);
  const [Bookmark, setBookmark] = useState(false);
  const [showtwo, setShowtwo] = useState(false);
  const [showthree, setShowthree] = useState(false);
  const [answedQueCount, setAnswedQueCount] = useState([]);
  const [boolmarkedQuesCount, setboolmarkedQuesCount] = useState([]);
  const [notanswedQueCount, setNotanswedQueCount] = useState(allQuesAns);
  const dropDownone = () => {
    setShow(!show);
    setShowtwo(false);
    setShowthree(false);
  };

  const dropDowntwo = () => {
    setShowtwo(!showtwo);
    setShow(false);
    setShowthree(false);
  };
  const dropDownthree = () => {
    setShowthree(!showthree);
    setShowtwo(false);
    setShow(false);
  };

  useEffect(() => {
    const ansQ = [];
    const noAnsQ = [];
    if (allQuesAns?.length) {
      allQuesAns.forEach((element, index) => {
        const copyOfElement = element;
        copyOfElement.index = index;
        if ('passageQuestions' in element) {
          let allPasQueAnswered = true;
          if (element?.passageQuestions?.length) {
            element?.passageQuestions?.map((item) => {
              if (
                allPasQueAnswered &&
                item?.solutionIndex?.length &&
                item?.solutionIndex?.includes(-1)
              ) {
                allPasQueAnswered = true;
              } else {
                allPasQueAnswered = false;
              }
            });
          }
          if (allPasQueAnswered) {
            noAnsQ.push(copyOfElement);
          } else {
            ansQ.push(copyOfElement);
          }
        } else if (
          element?.solutionIndex?.includes(-1) ||
          element?.fillInTheBlankSolution?.length === 0
        ) {
          noAnsQ.push(copyOfElement);
        } else {
          ansQ.push(copyOfElement);
        }
      });
    }
    setAnswedQueCount(ansQ);
    setNotanswedQueCount(noAnsQ);
  }, [selectedOption, allQuesAns]);

  useEffect(() => {
    const bookmarkedQues = [];
    allQuesAns.forEach((element, index) => {
      const copyOfElement = element;
      copyOfElement.index = index;
      if (element?.Bookmarked) {
        bookmarkedQues.push(copyOfElement);
      }
    });
    setboolmarkedQuesCount(bookmarkedQues);
  }, [Bookmark, allQuesAns?.Bookmarked]);

  return (
    <div className='olympiad-rightside-part'>
      <div className='rightsidepart-top row'>
        <div className='col-md-4 top-left'>
          <div className='current-que'>
            <h5>
              Current question :
              <span>
                {selectedQuestion + 1} / {questionInExamData?.length}
              </span>
            </h5>
          </div>
        </div>
        <div className='col-md-8 top-right'>
          <div className='row'>
            <div className='col-md-6 top-right-common'>
              <div className='dropdown-main'>
                <div
                  className={`select ${show ? 'active' : ''}`}
                  onClick={dropDownone}
                  aria-hidden='true'
                >
                  Answered Questions :{' '}
                  <span className='number green'>{answedQueCount?.length}</span>{' '}
                  <span className='arrow-down' />
                </div>
                <div className={`dropdown ${show ? 'active' : ''}`}>
                  {answedQueCount?.map((val) => (
                    <div
                      key={Math.random()}
                      onClick={() => {
                        setSelectedQuestion(val?.index);
                        setShow(false);
                      }}
                      aria-hidden='true'
                    >
                      Question {val?.index + 1}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='col-md-6 top-right-common'>
              <div className='dropdown-main'>
                <div
                  className={`select ${showtwo ? 'active' : ''}`}
                  onClick={dropDowntwo}
                  aria-hidden='true'
                >
                  Questions not Answered :
                  <span className='number orange'>
                    {notanswedQueCount?.length}
                  </span>{' '}
                  <span className='arrow-down' />
                </div>
                <div className={`dropdown ${showtwo ? 'active' : ''}`}>
                  {notanswedQueCount?.map((val) => (
                    <div
                      key={Math.random()}
                      onClick={() => {
                        setSelectedQuestion(val?.index);
                        setShowtwo(false);
                      }}
                      aria-hidden='true'
                    >
                      Question {val?.index + 1}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='col-md-6 top-right-common'>
              <div className='dropdown-main'>
                <div
                  className={`select ${showthree ? 'active' : ''}`}
                  onClick={dropDownthree}
                  aria-hidden='true'
                >
                  Review Questions :
                  <span className='number orange'>
                    {boolmarkedQuesCount?.length}
                  </span>{' '}
                  <span className='arrow-down' />
                </div>
                <div className={`dropdown ${showthree ? 'active' : ''}`}>
                  {boolmarkedQuesCount?.map((val) => (
                    <div
                      key={Math.random()}
                      onClick={() => {
                        setSelectedQuestion(val?.index);
                        setShowthree(false);
                      }}
                      aria-hidden='true'
                    >
                      Question {val?.index + 1}
                    </div>
                    // )
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='olympiad-practice-test'>
        <ExamQuestionSection
          questionInExamData={questionInExamData}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setEachTimeOn={setEachTimeOn}
          eachcurrenttime={eachcurrenttime}
          setEachtime={setEachtime}
          Bookmark={Bookmark}
          setBookmark={setBookmark}
          allQuesAns={allQuesAns}
          addQueAns={addQueAns}
          updateQueAns={updateQueAns}
        />
      </div>
    </div>
  );
};
ExamRightsidepart.propTypes = {
  questionInExamData: object.isRequired,
  selectedQuestion: string.isRequired,
  setSelectedQuestion: func.isRequired,
  selectedOption: string.isRequired,
  setSelectedOption: func.isRequired,
  allQuesAns: array.isRequired,
  setEachTimeOn: func.isRequired,
  eachcurrenttime: string.isRequired,
  setEachtime: func.isRequired,
  updateQueAns: func.isRequired,
  addQueAns: func.isRequired,
};

export default ExamRightsidepart;
