import * as actionTypes from './sullabusTypes';

export const getAllCountryList = () => {
  return { type: actionTypes.GET_ALL_COUNTRY_LIST };
};

export const updateSyllabus = (data) => {
  return { type: actionTypes.UPDATE_SYLLABUS, payload: data };
};

// export const getAllGradesList = (data) => {};

// export const getSyllabus = (data) => {};
export const getCountryBasedOnIP = () => {
  return { type: actionTypes.GET_COUNTRY_BASED_ON_IP };
};

export default {};
