import React, { useEffect, useState } from 'react';
import { func, string, object } from 'prop-types';
import StudentDetailHead from './StudentDetailHead';
import SubjectPerformance from './SubjectPerformance';
import Assignments from './Assignments';
import MyassignmentAnswer from './MyassignmentAnswer';

const StudentDetail = ({
  setStudents,
  subjectParam,
  studentId,
  studentIndData,
  studPerformance,
  gradeName,
  subName,
}) => {
  const [assignment, setAssignment] = useState(false);
  const [assignmentId, setAssignmentId] = useState('');
  useEffect(() => {
    localStorage.setItem('TeachStudentName', studentIndData.studentName);
    localStorage.setItem('TeachStudent', JSON.stringify(studentIndData));
    localStorage.setItem('TeachStudentgrade', gradeName);
    localStorage.setItem('TeachStudentSub', subName);
  }, []);
  return (
    <div>
      <div className='student-detail-main'>
        <StudentDetailHead
          setStudents={setStudents}
          studentIndData={studentIndData}
          studPerformance={studPerformance}
          gradeName={gradeName}
        />
        <SubjectPerformance
          studPerformance={studPerformance}
          subName={subName}
          StudentName={studentIndData?.studentName}
        />
        <Assignments
          subjectParam={subjectParam}
          studentId={studentId}
          setAssignment={setAssignment}
          setAssignmentId={setAssignmentId}
          StudentName={studentIndData?.studentName}
        />
      </div>
      {assignment === true ? (
        <MyassignmentAnswer
          assignmentId={assignmentId}
          setAssignment={setAssignment}
        />
      ) : (
        ''
      )}
    </div>
  );
};
StudentDetail.propTypes = {
  setStudents: func.isRequired,
  subjectParam: string.isRequired,
  studentId: string.isRequired,
  studentIndData: object.isRequired,
  studPerformance: object.isRequired,
  gradeName: string.isRequired,
  subName: string.isRequired,
};

export default StudentDetail;
