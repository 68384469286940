import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import '../../../../components/dashboard/dashboard.scss';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';
import { RevenueTimeDat } from '../../../../components/dashboard/mockData/reportData';
import Spinner from 'react-bootstrap/Spinner';
import PaginationComponent from '../../../../components/dashboard/Rightsidepart/components/pagination/Pagination';

const customStyles = {
  rows: {
    style: {
      minHeight: '60px', // override the row height
    },
  },
};

const columns = [
  {
    name: 'Date',
    selector: (row) => row.date,
  },
  {
    name: 'Time slot',
    width: '250px',
    selector: (row) => row.timeSlot,
  },
  {
    name: 'Duration',
    selector: (row) => row.duration,
  },
  {
    name: 'Grade',
    selector: (row) => row.gradeName,
  },
  {
    name: 'Subject',
    selector: (row) => row.subjectName,
  },
  {
    name: 'Status',
    selector: (row) =>
      row.isTeacherAttended === 1 ? (
        <span style={{ color: '#43AA8B' }}>Present</span>
      ) : (
        <span style={{ color: '#E9415F' }}>Absent</span>
      ),
  },
  {
    name: 'Earned',
    selector: (row) =>
      row.hasOwnProperty('onlineclasshistory') ? (
        <span>₹ {row?.onlineclasshistory?.costPerSession}</span>
      ) : (
        '-- --'
      ),
  },
];

const RevenueFilter = ({
  subjectList,
  setSubdata,
  subdata,
  gradeList,
  gradeData,
  setGradeData,
  setTimedata,
  timedata,
  setStartDateRange,
  startDateRange,
  endDateRange,
  setEndDateRange,
  classCompleted,
  totalRevenue,
  revenueLength,
  loading,
  classesCompCount,
  setUpcomingPage,
  upcomingPage,
}) => {
  const changeSubject = (val) => {
    setSubdata(val);
  };

  const changeGrade = (val) => {
    setGradeData(val);
  };

  const changeTimeFrame = (val) => {
    setTimedata(val);
    setStartDateRange('');
    setEndDateRange('');
  };

  return (
    <div>
      <div className='row myexam-title d-flex align-items-center '>
        {/* <div className={`col-12 myexam-title-center d-flex align-items-center `}> */}
        <div className='col-sm-12 col-12 myexam-title-last justify-content-start'>
          <Dropdown className=''>
            <Dropdown.Toggle>{gradeData?.name}</Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                key='All'
                onClick={() => changeGrade({ _id: '', name: 'All Grades' })}
              >
                All Grades
              </Dropdown.Item>
              {gradeList?.map((data) => (
                <Dropdown.Item
                  key={data?._id}
                  onClick={() => changeGrade(data)}
                >
                  {data?.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className=''>
            <Dropdown.Toggle>{subdata?.name}</Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                key='All'
                onClick={() => changeSubject({ _id: '', name: 'All Subjects' })}
              >
                All Subject
              </Dropdown.Item>
              {subjectList?.map((data) => (
                <Dropdown.Item
                  key={data?._id}
                  onClick={() => changeSubject(data)}
                >
                  {data?.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className=''>
            <Dropdown.Toggle>{timedata?.name}</Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                key='All'
                onClick={() =>
                  changeTimeFrame({ value: '', name: 'Date range' })
                }
              >
                Date range
              </Dropdown.Item>

              {RevenueTimeDat.map((data) => (
                <Dropdown.Item
                  key={data.name}
                  onClick={() => changeTimeFrame(data)}
                >
                  {data.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {timedata.name === 'Date range' && (
            <DatePicker
              className='dropdown-toggle'
              dateFormat='yyyy-MM-dd'
              selected={startDateRange}
              onChange={(date) => setStartDateRange(date)}
              placeholderText='YYYY-MM-DD'
            />
          )}

          {timedata.name === 'Date range' && <span className='dateTo'>to</span>}

          {timedata.name === 'Date range' && (
            <DatePicker
              className='dropdown-toggle'
              dateFormat='dd-MM-yyyy'
              selected={endDateRange}
              onChange={(date) => setEndDateRange(date)}
              placeholderText='DD-MM-YYYY'
            />
          )}
        </div>
      </div>
      {!loading ? (
        <div>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='cardRevenue'>
                <div className='cardRevenueBody'>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <div className='col-sm-3 floatData colorCard1'>
                        <div className='PaymentsTotalStu'>
                          <h4 className='revenueTitle1'>
                            {revenueLength?.length > 0 ? (
                              <span>₹ {totalRevenue?.totalRevenue}</span>
                            ) : (
                              <span>₹ 0</span>
                            )}
                          </h4>
                          <h2 className='mb-0 revenueInfo revenueInfoWhite'>
                            Revenue
                          </h2>
                        </div>
                      </div>

                      <div className='col-sm-3 floatData colorCard2'>
                        <div className='PaymentsTotalStu'>
                          <h4 className='revenueTitle2'>
                            {revenueLength?.length > 0 ? (
                              <span>
                                {totalRevenue?.attended +
                                  totalRevenue?.unAttended}
                              </span>
                            ) : (
                              0
                            )}
                          </h4>
                          <h2 className='mb-0 revenueInfo'>
                            Classes Scheduled
                          </h2>
                        </div>
                      </div>

                      <div className='col-sm-3 floatData colorCard3'>
                        <div className='PaymentsTotalStu'>
                          <h4 className='revenueTitle3'>
                            {revenueLength?.length > 0 ? (
                              <span>{totalRevenue?.attended}</span>
                            ) : (
                              0
                            )}
                          </h4>
                          <h2 className='mb-0 revenueInfo'>Classes Attended</h2>
                        </div>
                      </div>

                      <div className='col-sm-3 floatData colorCard4'>
                        <div className='PaymentsTotalStu'>
                          <h4 className='revenueTitle4'>
                            {revenueLength?.length > 0 ? (
                              <span>{totalRevenue?.unAttended}</span>
                            ) : (
                              0
                            )}
                          </h4>
                          <h2 className='mb-0 revenueInfo'>Classes Absent</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='cardRevenue'>
                  <div className='cardRevenueBody'>
                    <DataTable
                      columns={columns}
                      data={classCompleted}
                      customStyles={customStyles}
                    />
                  </div>
                </div>
              </div>
            </div>
            <PaginationComponent
              handleCurrentPage={(num) => {
                // document.body.scrollTo(0, 0);
                // document.documentElement.scrollTop = 0;
                setUpcomingPage(num);
              }}
              length={classesCompCount}
              perPage={10}
              currentPage={upcomingPage}
            />
          </div>
        </div>
      ) : (
        <div className='spinner_cls'>
          <Spinner animation='border' variant='#f3722c' />
        </div>
      )}
    </div>
  );
};

export default RevenueFilter;
