import { combineReducers } from 'redux';
import BrainGym from './BrainGym/BrainGymReducer';
import Auth from './Auth/AuthReducer';
import TeacherDashboard from './TeacherDashboard/TeacherDashboardReducer';
import MyExam from './MyExam/MyExamReducer';
import StudentSubscription from './MySubscription/MySubscriptionReducer';
import StudentData from './StudentData/StudentDataReducer';
import MyAssignment from './StudentAssignment/StudentAssignmentReducer';
import StudentDashboard from './StudentDashboard/StudentDashboardReducer';
import MyOlympiad from './MyOlympiad/MyOlympiadExamReducer';
import studentAssignment from './MyAssignments/MyAssignmentExamReducer';
import Syllabus from './Syllabus/syllabusReducer';

const rootReducer = combineReducers({
  BrainGym,
  Auth,
  TeacherDashboard,
  MyExam,
  StudentSubscription,
  StudentData,
  MyAssignment,
  StudentDashboard,
  MyOlympiad,
  studentAssignment,
  Syllabus,
});

export default rootReducer;
