import React from 'react';
import raisekid from '../../../assets/tempimages/contactbanner.png';

const Faqbanner = () => {
  return (
    <div className='banner_main_faq'>
      <div className='row'>
        <div className='col-md-6 col-sm-12 banner_left_faq color_image_group'>
          <h4>
            Frequently Asked<span className=''> Question</span>
          </h4>

          <p>
            Not just answers, but questions take us forward in life. Here we
            tried answering common questions that parents, teachers, and
            learners might have for TautMore. If you do not find your question,
            contact our support team.
          </p>

          <div className='banner_left_bottom_icon_parent' />
        </div>
        <div className='col-md-6 col-sm-12 banner-right'>
          <img src={raisekid} alt='banner_img' />
        </div>
      </div>
    </div>
  );
};

export default Faqbanner;
