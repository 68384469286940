// // helper functions

function handleResponse(response) {
  if (
    (response?.status == 401 || response?.statusCode == 401) &&
    response?.message === 'Unauthorised request'
  ) {
    window.history.pushState(null, null, '/login');
    localStorage.clear();
    window.location.reload();
    return;
  }
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    return data;
  });
}
function get(url, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body, token) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body, token) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function remove(url, token) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return fetch(url, requestOptions).then(handleResponse);
}

export const fetchWrapper = {
  get,
  post,
  put,
  remove,
};
export default {};
