import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import routes from './Routes';
import './App.css';
// import './assets/scss/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import CookieConsent from 'react-cookie-consent';

function App() {
  return (
    <div id='tautmore'>
      <BrowserRouter>
        <Switch>
          <Suspense fallback={<></>}>
            {routes.map((data) => (
              <>
                <Route
                  key={data.key}
                  exact
                  path={data.path}
                  component={data.component}
                />
              </>
            ))}
          </Suspense>
        </Switch>
      </BrowserRouter>

      {/* <CookieConsent
        location='bottom'
        buttonText='Accept'
        cookieName='myAwesomeCookieName2'
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        // expires={150}
        debug={true}
      >
        This website uses cookies to enhance the user experience.{' '}
      </CookieConsent> */}
    </div>
  );
}

export default App;
