import { fork, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './StudentDashboardTypes';
import { studentDashboardServices } from '../../services/studentDashboard.service';

function* workerStudentDashboard(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state?.Auth?.Login?.data?.accessToken;
  if (token) {
    const response = yield studentDashboardServices.studentDashboard(
      value,
      token
    );

    if (response) {
      yield put({
        type: actionTypes.UPDATE_STUDENT_DASHBOARD,
        payload: response,
      });
    }
  }
}
function* workerMyReportAnalytics(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentDashboardServices.myReportAnalytics(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_MY_REPORT_ANALYTICS,
      payload: response,
    });
  }
}
function* workerCumulativeReport(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentDashboardServices.cumulativeReport(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_CUMULATIVE_REPORT,
      payload: response,
    });
  }
}
function* workerLeaderboardByAccuracy(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentDashboardServices.leaderboardByAccuracy(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_LEADERBOARD_BY_ACCURACY,
      payload: response,
    });
  }
}

function* workerLeaderboardByAccuracyOlympiad(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentDashboardServices.leaderboardByAccuracyOlympiad(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_LEADERBOARD_BY_ACCURACY_OLYMPIAD,
      payload: response,
    });
  }
}

function* workerLeaderboardBySpeedAndAccuracy(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentDashboardServices.leaderboardBySpeedAndAccuracy(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_LEADERBOARD_BY_SPEED,
      payload: response,
    });
  }
}

function* workerLeaderboardBySpeedAndAccuracyOlympiad(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response =
    yield studentDashboardServices.leaderboardBySpeedAndAccuracyOlympiad(
      value,
      token
    );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_LEADERBOARD_BY_SPEED_OLYMPIAD,
      payload: response,
    });
  }
}

function* workerBraingymStartTimeAction(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentDashboardServices.brainGymSetTime(value, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_BRAINGYM_START_TIME,
      payload: response,
    });
  }
}

function* watcherStudentDashboard() {
  yield takeLatest(actionTypes.STUDENT_DASHBOARD, workerStudentDashboard);
  yield takeLatest(actionTypes.MY_REPORT_ANALYTICS, workerMyReportAnalytics);
  yield takeLatest(actionTypes.CUMULATIVE_REPORT, workerCumulativeReport);
  yield takeLatest(
    actionTypes.LEADERBOARD_BY_ACCURACY,
    workerLeaderboardByAccuracy
  );
  yield takeLatest(
    actionTypes.LEADERBOARD_BY_ACCURACY_OLYMPIAD,
    workerLeaderboardByAccuracyOlympiad
  );
  yield takeLatest(
    actionTypes.LEADERBOARD_BY_SPEED,
    workerLeaderboardBySpeedAndAccuracy
  );
  yield takeLatest(
    actionTypes.LEADERBOARD_BY_SPEED_OLYMPIAD,
    workerLeaderboardBySpeedAndAccuracyOlympiad
  );
  yield takeLatest(
    actionTypes.BRAINGYM_START_TIME,
    workerBraingymStartTimeAction
  );
}

function* fetchAll() {
  yield fork(watcherStudentDashboard);
}

export default fetchAll();
