import React, { useEffect, useState } from 'react';
import { string, func, object } from 'prop-types';
import { renderText } from '../../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/textHelper';
import {
  findKeyByValue,
  checkDragDropSnunscramble,
  structureDragAndDrop,
} from '../../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/questionHelper';
import './preview.scss';

const AnswerOption = ({
  item,
  solutionType,
  questionType,
  questionDetailsData,
  index,
}) => {
  const [dragData, setDragData] = useState([]);


  useEffect(() => {
    if (checkDragDropSnunscramble(findKeyByValue(solutionType))) {
      setDragData(
        structureDragAndDrop(
          questionDetailsData?.statement,
          questionDetailsData?.options,
          questionDetailsData?.solutionIndex
        )
      );
    }
  }, [questionDetailsData, solutionType]);

  let renderAnswer = null;
  switch (questionType) {
    case 'drag-and-drop':
    case 'select-the-word-to-match-the-correct-option':
      renderAnswer = (
        <table className='drag-detail-table'>
          <thead>
            <th colSpan='2'>Statement</th>
            <th colSpan='2'>Options</th>
          </thead>
          <tbody>
            {dragData?.length > 0 &&
              dragData?.map((option) => (
                <tr>
                  <td>
                    {option?.image ? (
                      <img src={option?.image} alt='option_image' />
                    ) : (
                      <span>No Image</span>
                    )}
                  </td>
                  <td>
                    {option?.text ? (
                      <span>{option?.text}</span>
                    ) : (
                      <span>No text</span>
                    )}
                  </td>
                  <td>
                    {option?.statementImage ? (
                      <img src={option?.statementImage} alt='option_image' />
                    ) : (
                      <span>No Image</span>
                    )}
                  </td>
                  <td>
                    {option?.statementText ? (
                      <span>{option?.statementText}</span>
                    ) : (
                      <span>No text</span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      );
      break;
    default:
      renderAnswer = (
        <div className='question-options-wrapper'>
          <div
            className='option-wrapper-tile-type d-flex align-items-center
                    justify-content-between p-4'
          >
            <div
              className='option-main optionsbtn d-flex align-items-center
 justify-content-between custonmFontOptions'
            >
              {String.fromCharCode(index + 65)}{' '}
              <span className='opti'> . </span>
              {item?.imageElement === null && (
                <div className='questionHtlm-right customQuestionHtml'>
                  <span>{renderText(item?.text)} </span>
                </div>
              )}
              {item?.imageElement !== '' && (
                <div className='questionHtlm-right customQuestionHtml'>
                  <span>{renderText(item?.imageElement)}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      );
  }
  return renderAnswer;
};

AnswerOption.propTypes = {
  item: object.isRequired,
  selectAnswer: string.isRequired,
  handleSelectOption: func.isRequired,
  ansType: string.isRequired,
};
AnswerOption.defaultProps = {
  selectAnswer: '',
};
export default AnswerOption;
