import * as actionTypes from './TeacherDashboardTypes';

export function myLeavesListAction(data) {
  return {
    type: actionTypes.MY_LEAVES_LIST,
    payload: data,
  };
}

export const applyLeaveAction = (data) => ({
  type: actionTypes.APPLY_LEAVE,
  payload: data,
});
export const editLeaveAction = (data) => ({
  type: actionTypes.EDIT_LEAVE,
  payload: data,
});

export const cancelLeaveAction = (data) => ({
  type: actionTypes.CANCEL_LEAVE,
  payload: data,
});

export const getProfileAction = (data) => ({
  type: actionTypes.GET_PROFILE,
  payload: data,
});

export const changeNameAction = (data) => ({
  type: actionTypes.CHANGE_NAME,
  payload: data,
});

export const changeEmailAction = (data) => ({
  type: actionTypes.CHANGE_PASSWORD,
  payload: data,
});

export const changePhoneNumberAction = (data) => ({
  type: actionTypes.CHANGE_PHONE_NUM,
  payload: data,
});

export const changeQualificationAction = (data) => ({
  type: actionTypes.CHANGE_QUALIFICATION,
  payload: data,
});

export const changeUniversityAction = (data) => ({
  type: actionTypes.CHANGE_UNVIERSITY,
  payload: data,
});

export const getMyClassesAction = () => ({
  type: actionTypes.GET_MY_CLASSES,
});

export const getMyCoCurricularClassesAction = (onSuccess) => ({
  type: actionTypes.GET_MY_CO_CURRICULAR_CLASSES,
  payload: onSuccess
});

export const getChapterBySubjectAction = (data) => ({
  type: actionTypes.GET_CHAPTER_BY_SUBJECT,
  payload: data,
});

export const getConceptByChapterAction = (data) => ({
  type: actionTypes.GET_CONCEPT_BY_CHAPTER,
  payload: data,
});

export const getSubconceptByConceptAction = (data) => ({
  type: actionTypes.GET_SUB_CONCEPTS,
  payload: data,
});

export const getMyClassesHistoryAction = () => ({
  type: actionTypes.GET_MY_CLASSES_HISTORY,
});

export const rescheduleClassAction = (data) => ({
  type: actionTypes.RESCHEDULE_CLASS,
  payload: data,
});

export const rescheduleCoCurricularClassAction = (data) => ({
  type: actionTypes.RESCHEDULE_COCURRICULAR_CLASS,
  payload: data,
});

export const teacherSlotsPerDateAction = (data) => ({
  type: actionTypes.TEACHER_SLOTS_PER_DATE,
  payload: data,
});

export const teacherSlotsCoCurricularPerDateAction = (data) => ({
  type: actionTypes.COCURRICULAR_TEACHER_SLOTS_PER_DATE,
  payload: data,
});

export const addTeachingHistoryAction = (data) => ({
  type: actionTypes.ADD_HISTORY,
  payload: data,
});

export const updateProfilePicAction = (data) => ({
  type: actionTypes.SET_PROFILE_PIC,
  payload: data,
});

export const sendPasswordAction = (data) => ({
  type: actionTypes.SEND_PASSWORD_LINK,
  payload: data,
});

export const getPaymentHistoryAction = (data) => ({
  type: actionTypes.GET_PAYMENT_HISTORY,
  payload: data,
});

export const updatePasswordAction = (data) => ({
  type: actionTypes.UPDATE_PASSWORD,
  payload: data,
});

export default {};
