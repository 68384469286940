export const LOGIN = 'LOGIN';
export const UPDATE_LOGIN = 'UPDATE_LOGIN';
export const UPDATE_LOGIN_PARENT = 'UPDATE_LOGIN_PARENT';
export const CLEAR_LOGIN = 'CLEAR_LOGIN';
export const SET_LOGIN_LOADING = 'SET_LOGIN_LOADING';

export const REGISTER = 'REGISTER';
export const UPDATE_REGISTER = 'UPDATE_REGISTER';

export const STUDENT_PROFILE = 'STUDENT_PROFILE';
export const UPDATE_STUDENT_PROFILE = 'UPDATE_STUDENT_PROFILE ';

export const STUDENT_PROFILE_IMG = 'STUDENT_PROFILE_IMG';
export const UPDATE_STUDENT_PROFILE_IMG = 'UPDATE_STUDENT_PROFILE_IMG ';

export const STUDENT_CHANGE_PASS = 'STUDENT_CHANGE_PASS';
export const UPDATE_STUDENT_CHANGE_PASS = 'UPDATE_STUDENT_CHANGE_PASS ';

export const PARENT_PROFILE = 'PARENT_PROFILE';
export const UPDATE_PARENT_PROFILE = 'UPDATE_PARENT_PROFILE';

export const PARENT_PROFILE_IMG = 'PARENT_PROFILE_IMG';
export const UPDATE_PARENT_PROFILE_IMG = 'UPDATE_PARENT_PROFILE_IMG ';

export const PARENT_CHANGE_PASS = 'PARENT_CHANGE_PASS';
export const UPDATE_PARENT_CHANGE_PASS = 'UPDATE_PARENT_CHANGE_PASS ';

export const CO_CURRICULAR_ACTIVITIES = 'CO_CURRICULAR_ACTIVITIES';
export const UPDATE_CO_CURRICULAR_ACTIVITIES =
  'UPDATE_CO_CURRICULAR_ACTIVITIES';

export const SEND_OTP = 'SEND_OTP';
export const UPDATE_SEND_OTP = 'UPDATE_SEND_OTPs';

export const IS_SENDING_OTP = 'IS_SENDING_OTP';
export const IS_SENDING_OTP_TEACHER = 'IS_SENDING_OTP_TEACHER';

export const SEND_OTP_PARENT = 'SEND_OTP_PARENT';
export const UPDATE_SEND_OTP_PARENT = 'UPDATE_SEND_OTP_PARENT';

export const VERIFY_OTP = 'VERIFY_OTP';
export const UPDATE_VERIFY_OTP = 'UPDATE_VERIFY_OTP';

export const VERIFY_OTP_TEACHER = 'VERIFY_OTP_TEACHER';
export const UPDATE_VERIFY_OTP_TEACHER = 'UPDATE_VERIFY_OTP_TEACHER';
export const UPLOAD_TEACHER_DOCUMENT = 'UPLOAD_TEACHER_DOCUMENT';
export const TEACHER_DOCUMENT = 'TEACHER_DOCUMENT';
export const TEACHER_RESET_DOCUMENT = 'TEACHER_RESET_DOCUMENT';
export const TEACHER_RESET = 'TEACHER_RESET';

export const VERIFY_OTP_PARENT = 'VERIFY_OTP_PARENT';
export const UPDATE_VERIFY_OTP_PARENT = 'UPDATE_VERIFY_OTP_PARENT';

export const ADD_STUDENT_PARENT = 'ADD_STUDENT_PARENT';
export const UPDATE_STUDENT_PARENT = 'UPDATE_STUDENT_PARENT';
export const UPDATE_STUDENT_PARENT_SUBSCRIPTION =
  'UPDATE_STUDENT_PARENT_SUBSCRIPTION';
export const UPDATE_PARENT_PAYMENT_SUBSCRIPTION =
  'UPDATE_PARENT_PAYMENT_SUBSCRIPTION';

export const GET_ALL_GRADES = 'GET_ALL_GRADES';
export const UPDATE_GET_ALL_GRADES = 'UPDATE_GET_ALL_GRADES';

export const GET_UNIQUE_SUBJECTS = 'GET_UNIQUE_SUBJECTS';
export const UPDATE_GET_UNIQUE_SUBJECTS = 'UPDATE_GET_UNIQUE_SUBJECTS';

export const GET_SUBJECTS_BY_CLASS = 'GET_SUBJECTS_BY_CLASS';
export const UPDATE_GET_SUBJECTS_BY_CLASS = 'UPDATE_GET_SUBJECTS_BY_CLASS';

export const REGISTER_TEACHER = 'REGISTER_TEACHER';
export const UPDATE_REGISTER_TEACHER = 'UPDATE_REGISTER_TEACHER';

export const REGISTER_PARENT = 'REGISTER_PARENT';
export const UPDATE_REGISTER_PARENT = 'UPDATE_REGISTER_PARENT';

export const ADD_GUARDIAN = 'ADD_GUARDIAN';
export const UPDATE_ADD_GUARDIAN = 'UPDATE_ADD_GUARDIAN';

export const GET_TIMESLOTS = 'GET_TIMESLOTS';
export const UPDATE_GET_TIMESLOTS = 'UPDATE_GET_TIMESLOTS';

export const GET_UNIVERSITIES = 'GET_UNIVERSITIES';
export const UPDATE_GET_UIVERSITIES = 'UPDATE_GET_UIVERSITIES';

export const GET_SUBJECTS = 'GET_SUBJECTS';
export const UPDATE_GET_SUBJECTS = 'UPDATE_GET_SUBJECTS';

export const GET_QUALIFICATIONS = 'GET_QUALIFICATIONS';
export const UPDATE_GET_QUALIFICATIONS = 'UPDATE_GET_QUALIFICATIONS';

export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
export const UPDATE_GET_COUNTRY_LIST = 'UPDATE_GET_COUNTRY_LIST';

export const GET_STATE_LIST = 'GET_STATE_LIST';
export const UPDATE_GET_STATE_LIST = 'UPDATE_GET_STATE_LIST';

export const GET_TIMEZONE_LIST = 'GET_TIMEZONE_LIST';
export const UPDATE_GET_TIMEZONE_LIST = 'UPDATE_GET_TIMEZONE_LIST';

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const UPDATE_DELETE_DOCUMENT = 'UPDATE_DELETE_DOCUMENT';

export const SEND_OTP_TEACHER = 'SEND_OTP_TEACHER';
export const UPDATE_SEND_OTP_TEACHER = 'UPDATE_SEND_OTP_TEACHER';

export const SEND_OTP_BOOK_DEMO = 'SEND_OTP_BOOK_DEMO';
export const UPDATE_SEND_OTP_BOOK_DEMO = 'UPDATE_SEND_OTP_BOOK_DEMO';
export const VERIFY_OTP_BOOK_DEMO = 'VERIFY_OTP_BOOK_DEMO';
export const UPDATE_VERIFY_OTP_BOOK_DEMO = 'UPDATE_VERIFY_OTP_BOOK_DEMO';
export const RESET_OTP_BOOK_DEMO = 'RESET_OTP_BOOK_DEMO';
export const UPDATE_RESET_OTP_BOOK_DEMO = 'UPDATE_RESET_OTP_BOOK_DEMO';
export const CHCEK_EMAIL_TEACHER = 'CHCEK_EMAIL_TEACHER';
export const UPDATE_CHCEK_EMAIL_TEACHER = 'UPDATE_CHCEK_EMAIL_TEACHER';

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const UPDATE_VERIFY_EMAIL_RESPOSE = 'UPDATE_VERIFY_EMAIL_RESPOSE';

export const FORGOT_PASSWORD_TEACHER = 'FORGOT_PASSWORD_TEACHER';
export const UPDATE_FORGOT_PASSWORD_TEACHER = 'UPDATE_FORGOT_PASSWORD_TEACHER';
export const CLEAR_FORGOT_PASSWORD_DETAILS = 'CLEAR_FORGOT_PASSWORD_DETAILS';

export const UPDATE_PARENT_LOGIN_STUDENT = 'UPDATE_PARENT_LOGIN_STUDENT';

export const ADD_STUDENT_GUARDIAN = 'ADD_STUDENT_GUARDIAN';

export const CREATE_ORDER_PAYPAL = 'CREATE_ORDER_PAYPAL';
export const UPDATE_CREATE_ORDER_PAYPAL = 'UPDATE_CREATE_ORDER_PAYPAL';

export const CAPTURE_PAYPAL_PAYMENT = 'CAPTURE_PAYPAL_PAYMENT';
export const UPDATE_CAPTURE_PAYPAL_PAYMENT = 'UPDATE_CAPTURE_PAYPAL_PAYMENT';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

export const UPDATE_STUDENT_DATA = 'UPDATE_STUDENT_DATA';
export const GET_UPDATE_STUDENT_DATA = 'GET_UPDATE_STUDENT_DATA';

export const GET_ALL_EXAM_TYPE_DATA = 'GET_ALL_EXAM_TYPE_DATA';
export const UPDATE_ALL_EXAM_TYPE_DATA = 'UPDATE_ALL_EXAM_TYPE_DATA';

export const UPDATE_REGISTRATION_DATA = ' UPDATE_REGISTRATION_DATA';
export const UPDATE_NEW_STUDENT_REGISTRATION_DATA =
  'UPDATE_NEW_STUDENT_REGISTRATION_DATA';

export const GET_ALL_MONTHS_SLABS = 'GET_ALL_MONTHS_SLABS';
export const UPDATE_ALL_MONTHS_SLABS = 'UPDATE_ALL_MONTHS_SLABS';

export const GET_ALL_BOARDS = 'GET_ALL_BOARDS';
export const UPDATE_ALL_BOARDS = 'UPDATE_ALL_BOARDS';

export const GET_COUNTRIES = 'GET_COUNTRIES';

export const GET_NEW_UPDATED_SUBJECTS = 'GET_NEW_UPDATED_SUBJECTS';

export const GET_ALL_CLASS_BY_COUNTRY = 'GET_ALL_CLASS_BY_COUNTRY';
export const UPDATE_ALL_CLASS_BY_COUNTRY = 'UPDATE_ALL_CLASS_BY_COUNTRY';

export const GET_TOTAL_POINTS = 'GET_TOTAL_POINTS';
export const UPDATE_TOTAL_POINTS = 'UPDATE_TOTAL_POINTS';

export const GET_OPEN_PRACTICE_COUNTRY_LIST = 'GET_OPEN_PRACTICE_COUNTRY_LIST';
export const UPDATE_OPEN_PRACTICE_COUNTRY_LIST =
  'UPDATE_OPEN_PRACTICE_COUNTRY_LIST';

export const GET_GEO_LOCATION = 'GET_GEO_LOCATION';
export const UPDATE_GEO_LOCATION = 'UPDATE_GEO_LOCATION';

// export const UPDATE_GET_COUNTRY_LIST = 'UPDATE_GET_COUNTRY_LIST';

export const SET_SELECTED_GRADE = 'SET_SELECTED_GRADE';
