import { v4 as uuid } from 'uuid';

export const data = [
  {
    name: 'shapes',
    key: uuid(),
    chapters: [
      {
        key: uuid(),
        name: 'Topic',
        topics: [
          'Sub-topic',
          'Sub-topic',
          'Sub-topic',
          'Sub-topic',
          'Sub-topic',
        ],
      },
      {
        key: uuid(),
        name: 'Topic',
        topics: [
          'Sub-topic',
          'Sub-topic',
          'Sub-topic',
          'Sub-topic',
          'Sub-topic',
        ],
      },
      {
        key: uuid(),
        name: 'Topic',
        topics: ['Sub-topic', 'Sub-topic', 'Sub-topic', 'Sub-topic'],
      },
    ],
  },
  {
    name: 'Count to 3',
    key: uuid(),
    chapters: [
      {
        key: uuid(),
        name: 'Topic',
        topics: ['Sub-topic', 'Sub-topic', 'Sub-topic', 'Sub-topic'],
      },
      {
        key: uuid(),
        name: 'Topic',
        topics: [
          'Sub-topic',
          'Sub-topic',
          'Sub-topic',
          'Sub-topic',
          'Sub-topic',
        ],
      },
    ],
  },
  {
    name: 'Count to 5',
    key: uuid(),
    chapters: [
      {
        key: uuid(),
        name: 'Topic',
        topics: ['Sub-topic', 'Sub-topic', 'Sub-topic', 'Sub-topic'],
      },
      {
        key: uuid(),
        name: 'Topic',
        topics: ['Sub-topic', 'Sub-topic', 'Sub-topic', 'Sub-topic'],
      },
      {
        key: uuid(),
        name: 'Topic',
        topics: ['Sub-topic', 'Sub-topic', 'Sub-topic', 'Sub-topic'],
      },
    ],
  },
  {
    name: 'Count to 5',
    key: uuid(),
    chapters: [
      {
        key: uuid(),
        name: 'Topic',
        topics: ['Sub-topic', 'Sub-topic', 'Sub-topic', 'Sub-topic'],
      },
      {
        key: uuid(),
        name: 'Topic',
        topics: [
          'Sub-topic',
          'Sub-topic',
          'Sub-topic',
          'Sub-topic',
          'Sub-topic',
        ],
      },
    ],
  },
  {
    name: 'Count to 10',
    key: uuid(),
    chapters: [
      {
        key: uuid(),
        name: 'Topic',
        topics: ['Sub-topic', 'Sub-topic', 'Sub-topic', 'Sub-topic'],
      },
      {
        key: uuid(),
        name: 'Topic',
        topics: ['Sub-topic', 'Sub-topic', 'Sub-topic', 'Sub-topic'],
      },
      {
        key: uuid(),
        name: 'Topic',
        topics: ['Sub-topic', 'Sub-topic', 'Sub-topic', 'Sub-topic'],
      },
    ],
  },
];

export const gradeLinks = [
  'Pre-kindergarten',
  'Kindergarten',
  'Class 1',
  'Class 2',
  'Class 3',
  'Class 4',
  'Class 5',
  'Class 6',
  'Class 7',
  'Class 8',
  'Class 9',
  'Class 10',
];
