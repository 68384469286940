import React from 'react';
import { string } from 'prop-types';
import { findKeyByType } from '../../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/questionHelper';

const TestHeader = ({ subjectName, solutionType }) => (
  <div className='row'>
    <div className='col-12 col-sm-12 col-md-12 mb-4 test-header-left'>
      <h3 className='test-header'>Question Preview - {subjectName}</h3>
      <div className='row'></div>
    </div>
    <div className='col-12'>
      <div className='questiontype-box'>
        <h4>
          Question Type : <span>{findKeyByType(solutionType)}</span>
        </h4>
      </div>
    </div>
  </div>
);

TestHeader.propTypes = {
  subjectName: string.isRequired,
  solutionType: string.isRequired,
};

export default TestHeader;
