import React, { useState, useEffect } from 'react';
import { array } from 'prop-types';

const SubjectTab = ({
  data,
  setSelectedSub,
  classesSubject,
  setSelectedActivity,
}) => {
  const [activebtn, setActivebtn] = useState('');

  useEffect(() => {
    if (data && setSelectedActivity) {
      setActivebtn(`${data[0]?.name}`);
    }
    if (data) {
      setActivebtn(`${data[0]?.class?.name}${data[0]?.name}`);
    }
  }, [data]);

  useEffect(() => {
    if (activebtn && setSelectedSub) {
      setSelectedSub(activebtn);
    }
  }, [activebtn]);

  useEffect(() => {
    if (activebtn && setSelectedActivity) {
      setSelectedActivity(activebtn);
    }
  }, [activebtn]);

  const renderMapFunction = () =>
    data?.length > 0
      ? data.map((val) => (
          <div className='toggle-maths-div me-md-2'>
            <button
              className={`toggle-maths-btn ${
                activebtn === `${val?.class?.name}${val?.name}`
                  ? 'mathbtn-act'
                  : 'mathbtn-un'
              }`}
              type='button'
              onClick={() => setActivebtn(`${val?.class?.name}${val?.name}`)}
            >
              Grade {val?.class?.name} - {val?.name}
            </button>
          </div>
        ))
      : null;

  const renderMapFunctionOnConditions = () =>
    data?.length > 0 &&
    data.map((val) =>
      classesSubject?.has(val.name) ? (
        <div className='toggle-maths-div me-md-2'>
          <button
            className={`toggle-maths-btn ${
              activebtn === `${val?.class?.name}${val?.name}`
                ? 'mathbtn-act'
                : 'mathbtn-un'
            }`}
            type='button'
            onClick={() => setActivebtn(`${val?.class?.name}${val?.name}`)}
          >
            {val?.name}
          </button>
        </div>
      ) : null
    );

  return (
    <div className='row myexam-tab'>
      <div className='col-md-9 col-sm-12 myexam-tab-left'>
        <div className='dtoggle-bar flex-wrap'>
          {classesSubject
            ? renderMapFunctionOnConditions()
            : renderMapFunction()}
        </div>
      </div>
    </div>
  );
};

SubjectTab.propTypes = {
  data: array.isRequired,
};

export default SubjectTab;
