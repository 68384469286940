import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../assets/tempimages/Logo.svg';
import LeadForm from './LeadForm';
import swal from 'sweetalert';
import {
  getCountries,
  resetBookDemoAction,
} from '../../stores/Auth/AuthAction';
import Loading from '../../views/components/newRegistration/components/Loading';
import { grade } from '../../views/components/dashboard/Rightsidepart/RenewSubscription/mockData/Renewdata';
import { submitBookDemo } from '../../services/auth.services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LeadCaptureFormWrapper = ({
  getCountryList,
  countryList,
  modal,
  setModal,
  setSuccess,
  resetBookDemo,
}) => {
  const [validation, setValidation] = useState({
    fullName: false,
    parentName: false,
    grade: false,
    phoneNumber: false,
    isStudentValidated: false,
    country: false,
  });
  const history = useHistory();
  const [fullnameVal, setFullnameVal] = useState('');
  const [parentnameVal, setParentnameVal] = useState('');
  const [gradeVal, setGradeVal] = useState(null);
  const [countryVal, setCountryVal] = useState(null);
  const [emailVal, setEmailVal] = useState('');
  const [phoneNumVal, setPhoneNumVal] = useState('');
  const [gender, setGender] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileName, setFileName] = useState('');
  const [parentId, setParentId] = useState('');
  const [currencyCode, setCurrencyCode] = useState('');
  const [students, setStudents] = useState([]);
  const [showNextLoading, setShowNextLoading] = useState(false);

  useEffect(() => {
    if (modal) getCountryList();
  }, [modal]);
  const HandleSubmit = async () => {
    try {
      let payload = {
        phone: phoneNumVal,
        grade: null,
        countryName: null,
        country: null,
      };
      setShowNextLoading(true);
      let res = await submitBookDemo(payload);
      if (res?.message) {
        if (res?.statusCode == 200) {
          setValidation({
            fullName: false,
            parentName: false,
            grade: false,
            phoneNumber: false,
            isStudentValidated: false,
            country: false,
          });
          resetBookDemo();
          setSuccess();
        } else {
          let swalRes = await swal(res?.message);
          if (swalRes) setModal();
        }
        setShowNextLoading(false);
      }
    } catch (er) {
      toast.error(res?.message ?? 'Something went wrong');
      setModal();
      setShowNextLoading(false);
    }
  };
  return (
    <div>
      <div className='register-main book-a-demo-main teacher-reg-main'>
        <div className='container'>
          <div className='step-body'>
            <LeadForm
              setFullnameVal={setFullnameVal} //
              fullnameVal={fullnameVal} //
              phoneNumVal={phoneNumVal} //
              setPhoneNumVal={setPhoneNumVal} //
              setValidation={setValidation}
              validation={validation} //
              otpDisable={validation.isStudentValidated}
            />
            <div className='text-center step-btn-part'>
              <button
                type='button'
                disabled={
                  !validation.isStudentValidated ||
                  validation.grade ||
                  validation.country
                }
                className='next-button'
                onClick={HandleSubmit}
              >
                {showNextLoading ? <Loading variant='light' /> : 'Submit'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  otp: state.Auth.sendOtpBookDemo,
  teacherRegData: state.Auth.teacherRegData,
  countryList: state.Auth.countryList,
});

const mapDispatchToProps = (dispatch) => ({
  getCountryList: (data) => dispatch(getCountries(data)),
  resetBookDemo: (data) => dispatch(resetBookDemoAction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadCaptureFormWrapper);
