/*export const startListening = async (speech) => {
  const msg = new SpeechSynthesisUtterance();
  const voices = await speechSynthesis.getVoices();
  console.log('out side the voices', voices);

  if (voices) {
    msg.text = speech;
    msg.rate = 1;
    msg.voice = voices[9];
    console.log('the voices', voices);
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(msg);
  }
};*/
let voices = null;

const getVoices = async () => {
  if (!voices) {
    voices = await new Promise((resolve) => {
      speechSynthesis.onvoiceschanged = () => {
        const availableVoices = speechSynthesis.getVoices();

        resolve(availableVoices);
      };
    });
  }
  return voices;
};

const getDefaultVoice = async () => {
  const defaultVoice = (await getVoices()).find(
    (v) => v.name === 'Google US English'
  );
  return defaultVoice || (await getVoices())[0];
};

export const startListening = async (text) => {
  const utterance = new SpeechSynthesisUtterance(text);
  utterance.voice = await getDefaultVoice();

  utterance.rate = 0.8;
  utterance.text = text;

  window.speechSynthesis.cancel();
  window.speechSynthesis.speak(utterance);
};
export default {};
