import React, { useState } from 'react';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input';
import { object, string, func, array, bool } from 'prop-types';
import { verifyOtpBookDemoAction, verifyOtpParentAction,verifyOtpTeacherAction } from '../../stores/Auth/AuthAction';

const OtpInputBox = ({
  label, //
  phoneNumVal, //
  verifyOtpParent, //
  verifyOtpParentData, //
  isDisabled,
  type,
  verifyOtpTeacher,
  verifyOtpBookDemo,
  verifyOtpBookDemoRes
}) => {
  const [otpVal, setOtpVal] = useState('');

  const handeInput = (data) => {
    const str = data.toString();
    setOtpVal(data);

    if (str.length === 6) {
      const reqData = {
        phone: phoneNumVal,
        otp: data,
      };
      if(type==="teacher")verifyOtpTeacher(reqData)
      if(type==="book-demo")verifyOtpBookDemo(reqData)
     else verifyOtpParent(reqData);
    }
  };

  return (
    <div className='col-md-6 otp-block'>
      <label htmlFor='mydetail-input'>{label}</label>
      <OtpInput
        value={otpVal}
        onChange={(otp) => handeInput(otp)}
        numInputs={6}
        separator={<span> </span>}
        isDisabled={isDisabled}
        isInputNum={true}
      />
      {verifyOtpBookDemoRes?.statusCode === 401 ? (
        <span className='error-msg'>{verifyOtpBookDemoRes?.message}</span>
      ) : (
        ''
      )}
       {verifyOtpBookDemoRes?.statusCode === 403 ? (
        <span className='error-msg'>{verifyOtpBookDemoRes?.message}</span>
      ) : (
        ''
      )}
    </div>
  );
};

OtpInputBox.propTypes = {
  label: string.isRequired,
  phoneNumVal: string.isRequired,
  verifyOtpParent: func.isRequired, //
  verifyOtpParentData: object.isRequired, //
  isDisabled: bool.isRequired,
};
const mapStateToProps = (state) => ({
  verifyOtpParentData: state.Auth.verifyOtpParent,
  verifyOtpBookDemoRes: state.Auth.verifyOtpBookDemo,
});

const mapDispatchToProps = (dispatch) => ({
  verifyOtpParent: (data) => dispatch(verifyOtpParentAction(data)),
  verifyOtpTeacher: (data) => dispatch(verifyOtpTeacherAction(data)),
  verifyOtpBookDemo: (data) => dispatch(verifyOtpBookDemoAction(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(OtpInputBox);
