import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { func, bool, array } from 'prop-types';
import { Formik, Form, Field } from 'formik';
import './calendar.scss';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Spinner from 'react-bootstrap/Spinner';

import enGb from 'date-fns/locale/en-GB';
import 'react-datepicker/dist/react-datepicker.css';
import { leaveValidation } from './mockData/calendar';
import {
  applyLeaveAction,
  editLeaveAction,
} from '../../../../../stores/TeacherDashboard/TeacherDashboardAction';
import { chevRight } from '../../../../../assets/icons/IconList';
import { useHistory } from 'react-router';
const LeavePopup = ({
  model,
  handleModel,
  applyLeaveResponse,
  applyLeave,
  loginDetail,
  fetchLeaves,
  leaveData,
  isLeaveEdit,
  editLeave,
  setIsLeaveEdit,
}) => {
  const history = useHistory();
  const [totalLeave, setTotalLeave] = useState('');
  const [appliedFromDate, setAppliedFromDate] = useState('');
  const [appliedToDate, setAppliedToDate] = useState('');
  const [totalAppliedLeave, setTotalAppliedLeave] = useState('');

  const [applyingLeaveLoading, setApplyingLeaveLoading] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [daysDifference, setDaysDifference] = useState('');
  const [showDays, setShowDays] = useState(false);

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      const startDate1 = moment(startDate);
      const timeEnd = moment(endDate);
      const diff = timeEnd.diff(startDate1);
      const diffDuration = moment.duration(diff);

      setDaysDifference(diffDuration.days());
    }
  }, [startDate, endDate]);

  const fromDateChange = (e, setFieldValue, values) => {
    setStartDate(e);
    setFieldValue('fromDate', e);
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(
      `${e.getFullYear()}, ${e.getMonth() + 1}, ${e.getDate()}`
    );
    const secondDate = new Date(
      `${values?.toDate.getFullYear()}, ${
        values?.toDate.getMonth() + 1
      }, ${values?.toDate.getDate()}`
    );
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (!Number.isNaN(diffDays)) {
      setTotalLeave(diffDays);
    }
  };
  const toDateChange = (e, setFieldValue, values) => {
    setEndDate(e);
    setShowDays(true);
    setFieldValue('toDate', e);
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(
      `${e.getFullYear()}, ${e.getMonth() + 1}, ${e.getDate()}`
    );
    const secondDate = new Date(
      `${values?.fromDate.getFullYear()}, ${
        values?.fromDate.getMonth() + 1
      }, ${values?.fromDate.getDate()}`
    );
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (!Number.isNaN(diffDays)) {
      setTotalLeave(diffDays);
    }
  };
  const handleAddSubmit = (data) => {
    setApplyingLeaveLoading(true);
    setShowDays(false);
    if (!isLeaveEdit) {
      const value = {
        userId: loginDetail?.data?._id,
        userType: 'Teacher',
        from: `${moment(data?.fromDate).format('YYYY-MM-DD')}T00:00:00.000Z`,
        to: `${moment(data?.toDate).format('YYYY-MM-DD')}T01:00:00.000Z`,
        reason: data?.reason,
      };
      applyLeave(value);
      fetchLeaves();
    } else {
      const value = {
        value: {
          _id: leaveData?._id,
          from: `${moment(data?.fromDate).format('YYYY-MM-DD')}T00:00:00.000Z`,
          to: `${moment(data?.toDate).format('YYYY-MM-DD')}T01:00:00.000Z`,
          reason: data?.reason,
        },
        onSuccess: () => {
          setApplyingLeaveLoading(false);
          setShowDays(false);
          handleModel(false);
        },
      };
      editLeave(value);
    }
  };

  useEffect(() => {
    if (applyLeaveResponse?.data) {
      const oneDay = 24 * 60 * 60 * 1000;
      const month = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      let frmDate = new Date(applyLeaveResponse?.data?.from);
      const fromLeave = `${
        month[frmDate.getMonth()]
      } ${frmDate.getDate()}, ${frmDate.getFullYear()}`;
      setAppliedFromDate(fromLeave);
      frmDate = `${frmDate.getFullYear()}-${
        frmDate.getMonth() + 1
      }-${frmDate.getDate()}`;

      let toDate = new Date(applyLeaveResponse?.data?.to);
      const toLeave = `${
        month[toDate.getMonth()]
      } ${toDate.getDate()}, ${toDate.getFullYear()}`;
      setAppliedToDate(toLeave);
      toDate = `${toDate.getFullYear()}-${
        toDate.getMonth() + 1
      }-${toDate.getDate()}`;

      const fromLeaveDay = new Date(frmDate.replace(/-/g, ', '));
      const toLeaveDay = new Date(toDate.replace(/-/g, ', '));
      const totalLeaveDay = Math.round(
        Math.abs((fromLeaveDay - toLeaveDay) / oneDay)
      );
      setTotalAppliedLeave(totalLeaveDay);
    }

    if (applyLeaveResponse.status === 'success') {
      setApplyingLeaveLoading(false);
    }
  }, [applyLeaveResponse]);

  const continueApplyLeave = () => {
    handleModel(false);
    applyLeave();
  };


  return (
    <Modal
      show={model}
      onHide={() => {
        setShowDays(false);
        handleModel(false);
        setIsLeaveEdit(false);
        history.push('/teacher/calendar');
      }}
      className='calendar-leave-popup'
    >
      {!applyLeaveResponse?.data && (
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-lg'>
            <h3>
              {isLeaveEdit ? 'Edit Leave Application' : 'Apply for leave'}
            </h3>
            <p>
              Please make sure you apply for leaves 1-2 days in advance and not
              on the same day
            </p>
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {applyLeaveResponse?.data ? (
          <div className='leave-applied'>
            {applyLeaveResponse?.data?.from && applyLeaveResponse?.data?.to ? (
              <>
                <h3>
                  Leave applied for {daysDifference + 1}{' '}
                  {daysDifference < 1 ? 'day! ' : 'days! '}
                  See you soon.
                </h3>

                {daysDifference + 1 === 1 ? (
                  <p>
                    Leave date :{' '}
                    {moment(startDate).format('dddd, MMMM Do YYYY')}
                  </p>
                ) : (
                  <p>
                    From {moment(startDate).format('dddd, MMMM Do YYYY')} to{' '}
                    {moment(endDate).format('dddd, MMMM Do YYYY')}
                  </p>
                )}
              </>
            ) : (
              <h3>{applyLeaveResponse?.data}</h3>
            )}

            <button
              type='button'
              className='button-common'
              onClick={continueApplyLeave}
            >
              Continue {/* <span>{chevRight}</span> */}
            </button>
          </div>
        ) : (
          <Formik
            enableReinitialize
            initialValues={{ fromDate: '', toDate: '', reason: '' }}
            validationSchema={leaveValidation}
            onSubmit={(data) => handleAddSubmit(data)}
            validator={() => ({})}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
            }) => {
              useEffect(() => {
                if (leaveData) {
                  setStartDate(new Date(leaveData?.from));
                  setFieldValue('fromDate', new Date(leaveData?.from));
                  setEndDate(new Date(leaveData?.to));
                  setFieldValue('toDate', new Date(leaveData?.to));
                  setFieldValue('reason', leaveData?.reason);
                }
              }, [leaveData]);

              return (
                <Form onSubmit={handleSubmit}>
                  <div className='label'>From*</div>
                  <DatePicker
                    minDate={new Date()}
                    name='fromDate'
                    dateFormat='MMMM dd, yyyy'
                    locale={enGb}
                    placeholderText='Select date'
                    formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                    selected={values.fromDate}
                    onChange={(e) => fromDateChange(e, setFieldValue, values)}
                  />
                  <br /> <br />
                  {errors.fromDate && touched.fromDate ? (
                    <div className='mt-25 ml-25 rp-manage-school_error-message'>
                      {errors.fromDate}
                    </div>
                  ) : null}
                  <div className='label'>To*</div>
                  <DatePicker
                    minDate={new Date(values.fromDate)}
                    name='toDate'
                    placeholderText='Select date'
                    dateFormat='MMMM dd, yyyy'
                    locale={enGb}
                    formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                    selected={values.toDate}
                    onChange={(e) => toDateChange(e, setFieldValue, values)}
                    disabled={!values.fromDate}
                  />
                  <br /> <br />
                  {errors.toDate && touched.toDate ? (
                    <div className='mt-25 ml-25 rp-manage-school_error-message'>
                      {errors.toDate}
                    </div>
                  ) : null}
                  <div className='label'>Reason*</div>
                  <Field
                    as='textarea'
                    name='reason'
                    onChange={handleChange}
                    value={values.reason}
                    maxLength={500}
                    placeholder='Please enter a reason.'
                  />
                  <br /> <br />
                  {errors.reason && touched.reason ? (
                    <div className='mt-25 ml-25 rp-manage-school_error-message'>
                      {errors.reason}
                    </div>
                  ) : null}
                  {showDays && (
                    <p
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        color: 'orange',
                        fontSize: '20px',
                        fontWeight: 900,
                      }}
                    >
                      Applying leave for {daysDifference + 1}{' '}
                      {daysDifference < 1 ? 'day' : 'days'}
                    </p>
                  )}
                  <div className='text-center'>
                    <button type='submit' className='button-common'>
                      {applyingLeaveLoading ? (
                        <Spinner animation='border' variant='light' />
                      ) : isLeaveEdit ? (
                        'Update Leave'
                      ) : (
                        'Apply leave'
                      )}
                      {/* <span>{chevRight}</span> */}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};

LeavePopup.propTypes = {
  handleModel: func.isRequired,
  model: bool.isRequired,
  applyLeaveResponse: array.isRequired,
  applyLeave: func.isRequired,
  loginDetail: array.isRequired,
  fetchLeaves: func.isRequired,
};

const mapStateToProps = (state) => ({
  applyLeaveResponse: state.TeacherDashboard.applyLeave,
  loginDetail: state.Auth.Login,
});

const mapDispatchToProps = (dispatch) => ({
  applyLeave: (data) => dispatch(applyLeaveAction(data)),
  editLeave: (data) => dispatch(editLeaveAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeavePopup);
