import http from '../helpers/Axios';

export const getSyllabusMapping = async (data) =>
  http.post('/assignment/map-syllabus', data);

export const getQuestionsInSubConcept = async (params) =>
  http.get('/assignment/teachers/questions-in-subconcept', { params });

export const getStudentsListing = async (params) =>
  http.get('/assignment/teachers/my-students-listing', { params });

export const searchStudentsListing = async (params) =>
  http.get(
    `/assignment/teachers/student-search?grade=${params?.grade}&subject=${params?.subject}&searchText=${params?.searchText}&teacher=${params?.teacher}`
  );

export const assignStudentAssignment = async (data) =>
  http.post('/assignment/assign-student', data);

export const getAssignments = async (params) =>
  http.get('/assignment/assignments-listing', { params });

export const getStudentPerformance = async (params) =>
  http.get('/assignment/teachers/student-performance', { params });

export const getAssignmentView = async (params) =>
  http.get('/assignment/teachers/view-student-assignment', { params });

export const getSyllabusHierarchy = async (params) =>
  http.get('/chapters/syllabus-hierarchy', { params });

export const startClass = async (data, accessToken) =>
  http.post('/online-class/v2/teachers/start-class', data, {
    headers: { Authorization: accessToken },
  });

export const studentJoinClass = async (data, accessToken) =>
  http.post('/online-class/students/join-online-class', data, {
    headers: { Authorization: accessToken },
  });

export const addCoCurrTeacherHistory = async (data) =>
  http.post('/cocurricular-online-class/teachers/add-history', data);

export const coCurrStudentJoinClass = async (data) =>
  http.post('/cocurricular-online-class/teachers/start-class', data);

export const coCurrJoinClassStudents = async (data) =>
  http.post('/cocurricular-online-class/students/join-online-class', data);
export const getSyllabusHierarchyWithoutLogin = async (params) =>
  http.get('/practice-test/syllabus-hierarchy', { params });

export const getCompletedClassListing = async (params, id, accessToken) =>
  http.get(
    `/online-class/teachers/completed-classes-listing/${id}?page_no=${params?.page_no}&grade=${params?.grade}&subject=${params?.subject}&timeFrame=${params?.timeFrame}&startDate=${params?.startDate}&endDate=${params?.endDate}`,
    {
      headers: { Authorization: accessToken },
    }
  );

export const getRevenueSummary = async (params, id, accessToken) =>
  http.get(
    `/online-class/teachers/revenue-summary/${id}?grade=${params?.grade}&subject=${params?.subject}&timeFrame=${params?.timeFrame}&startDate=${params?.startDate}&endDate=${params?.endDate}`,
    {
      headers: { Authorization: accessToken },
    }
  );

export const getCoCompletedClassListing = async (params, id, accessToken) =>
  http.get(
    `/cocurricular-online-class/teachers/completed-classes-listing/${id}?page_no=${params?.page_no}&grade=${params?.grade}&activity=${params?.subject}&timeFrame=${params?.timeFrame}&startDate=${params?.startDate}&endDate=${params?.endDate}`,
    {
      headers: { Authorization: accessToken },
    }
  );

export const getCoRevenueSummary = async (params, id, accessToken) =>
  http.get(
    `/cocurricular-online-class/teachers/revenue-summary/${id}?startDate=${params?.startDate}&timeFrame=${params?.timeFrame}&endDate=${params?.endDate}&grade=${params?.grade}&activity=${params?.subject}`,
    {
      headers: { Authorization: accessToken },
    }
  );
