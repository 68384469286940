import { v4 as uuid } from 'uuid';
import mathIcon from '../../../../assets/images/Mathematics.svg';
import engIcon from '../../../../assets/images/English.svg';
import physicsIcon from '../../../../assets/images/Physics1.png';
import chemIcon from '../../../../assets/images/Chemestry.svg';
import scienceIcon from '../../../../assets/images/Science.svg';
import biologyIcon from '../../../../assets/images/Biology.svg';

const SubjectData = [
  {
    title: 'English',
    image: engIcon,
    discription:
      'Here is a list of the *English* concepts that third-graders acquire. These concepts are categorised into micro level subtopics. Simply click on any link to begin practising, then enjoy responding to challenging inquiries while improving all your skills and learning techniques.',
    key: uuid(),
  },
  {
    title: 'Mathematics',
    image: mathIcon,
    discription:
      'Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tem por incididunt ut',
    key: uuid(),
  },
  {
    title: 'Science',
    image: scienceIcon,
    discription:
      'Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tem por incididunt ut',
    key: uuid(),
  },
  {
    title: 'Physics',
    image: physicsIcon,
    discription:
      'Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tem por incididunt ut',
    key: uuid(),
  },
  {
    title: 'Chemistry',
    image: chemIcon,
    discription:
      'Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tem por incididunt ut',
    key: uuid(),
  },
  {
    title: 'Biology',
    image: biologyIcon,
    discription:
      'Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tem por incididunt ut',
    key: uuid(),
  },
  // {
  //   title: 'Brain-gym',
  //   image: brainIcon,
  //   key: uuid(),
  // },
  // {
  //   title: 'Co-curricular',
  //   image: coIcon,
  //   key: uuid(),
  // },
  // {
  //   title: 'Exams',
  //   image: examIcon,
  //   key: uuid(),
  // },
];

export default SubjectData;
