import React from 'react';
import { array, func, object, string } from 'prop-types';
import bookmarkimg from '../../../../../assets/images/bookmark.png';
import bookmarkfilledimg from '../../../../../assets/images/bookmarkfilled2.png';
import OlympiadAnswerOption from './OlympiadAnswerOption';
import { renderText } from '../BrainGym/QueAnswer/textHelper';
import {
  checkDragDropSnunscramble,
  findKeyByValue,
} from '../BrainGym/QueAnswer/questionHelper';
import speaker from '../../../../../assets/images/speaker.png';
import { startListening } from '../components/Exam/startLearning';
import { readText } from '../BrainGym/QueAnswer/textHelper';

const OlympiadQueAnsWrapper = ({
  questionInExamData,
  selectedQuestion,
  selectedOption,
  setSelectedOption,
  allQuesAns,
  qIndex,
  itemId,
  description,
  options,
  addBookmark,
}) => {
  const questionObject = questionInExamData[selectedQuestion];
  const allquedata = allQuesAns && allQuesAns[selectedQuestion];

  const multipleSelectWord = (index) => {
    if (selectedOption.includes(index)) {
      const filteredValues = selectedOption.filter((val) => val !== index);
      const sortedOptions = filteredValues.sort(function (a, b) {
        return a - b;
      });
      setSelectedOption(sortedOptions);
    } else {
      const filteredValues = [
        ...selectedOption.filter((i) => i !== -1 && i !== index),
        index,
      ];
      const sortedOptions = filteredValues.sort(function (a, b) {
        return a - b;
      });
      setSelectedOption(sortedOptions);
    }
  };

  const questionType = findKeyByValue(questionObject?.solutionType);
  if (checkDragDropSnunscramble(questionType)) return null;
  const extractContent = (s) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  const handleOptionSpeech = (options) => {
    let isText = true;
    const speech = options.map((e) => {
      if (e?.image) {
        isText = false;
      }
      return e?.text ? e.text : ' ';
    });
    if (isText) startListening(readText(speech.join(' . ')));
  };

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          {findKeyByValue(questionObject?.solutionType) === 'passage' ? (
            <div>
              <div className='question-box'>
                <div className='d-flex justify-content-between'>
                  <h4>Question - {qIndex}</h4>
                  <span>
                    <button
                      type='button'
                      id={qIndex}
                      onClick={() => {
                        startListening(
                          readText(
                            questionObject.title
                              ? questionObject.title + ' .'
                              : description
                          )
                        );
                      }}
                    >
                      {' '}
                      <img
                        className='span-one speaker-btn'
                        src={speaker}
                        alt='speaker icon'
                      />
                    </button>
                    <button
                      className='text-right'
                      onClick={() => addBookmark(selectedQuestion)}
                      type='button'
                    >
                      <img
                        className={
                          allquedata?.Bookmarked
                            ? 'bookmarkfilled-img'
                            : 'bookmark-img'
                        }
                        src={
                          allquedata?.Bookmarked
                            ? bookmarkfilledimg
                            : bookmarkimg
                        }
                        alt='bookmark'
                      />
                    </button>
                  </span>
                </div>
                <h2>
                  {' '}
                  <span>{renderText(description)}</span>
                </h2>
              </div>
            </div>
          ) : (
            <div className='question-box'>
              <div className='d-flex justify-content-between'>
                <h4>Question - {selectedQuestion + 1}</h4>
                <span>
                  <button
                    type='button'
                    id={qIndex}
                    onClick={() =>
                      startListening(
                        readText(
                          (questionObject.title
                            ? questionObject.title + '. '
                            : '') + description
                        )
                      )
                    }
                  >
                    {' '}
                    <img
                      className='span-one speaker-btn'
                      src={speaker}
                      alt='speaker icon'
                    />
                  </button>
                  <button
                    className='text-right'
                    onClick={() => addBookmark(selectedQuestion)}
                    type='button'
                  >
                    <img
                      className={
                        allquedata?.Bookmarked
                          ? 'bookmarkfilled-img'
                          : 'bookmark-img'
                      }
                      src={
                        allquedata?.Bookmarked ? bookmarkfilledimg : bookmarkimg
                      }
                      alt='bookmark'
                    />
                  </button>
                </span>
              </div>

              <h4 className='title'>
                {questionObject.title ? renderText(questionObject.title) : ''}
              </h4>
              <h2>
                {findKeyByValue(questionObject?.solutionType) ===
                'select-the-suitable-words' ? (
                  extractContent(questionObject?.description)
                    ?.split(' ')
                    ?.map((item, index) => {
                      return (
                        <button
                          className={`${
                            selectedOption.includes(index)
                              ? 'olympiad_active_btn'
                              : ''
                          }`}
                          style={{ marginRight: '5px' }}
                          key={item}
                          onClick={() => multipleSelectWord(index)}
                        >
                          {item}
                        </button>
                      );
                    })
                ) : (
                  <span>{renderText(description)}</span>
                )}
              </h2>
            </div>
          )}
          <div className='d-flex justify-content-between mt-3'>
            {findKeyByValue(questionObject?.solutionType) !==
              'select-the-suitable-words' && (
              <>
                <span className='answer-title'>Select your answer</span>

                <span className='mt-3 m-5'>
                  <button
                    type='button'
                    id={qIndex}
                    onClick={() => handleOptionSpeech(options)}
                  >
                    {' '}
                    <img
                      className='span-one speaker-btn'
                      src={speaker}
                      alt='speaker icon'
                    />
                  </button>
                </span>
              </>
            )}
          </div>

          <div
            className={
              questionObject?.questionOrientaion === 'vertical'
                ? 'question-options-wrapper selectTyleType'
                : 'question-options-wrapper selectTyleType'
            }
          >
            {options?.map(
              (item, ind) =>
                (item?.text !== '' || item?.image !== '') && (
                  <OlympiadAnswerOption
                    questionObject={questionObject}
                    item={item}
                    itemId={itemId}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    ind={ind}
                    qIndex={qIndex - 1}
                  />
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
OlympiadQueAnsWrapper.propTypes = {
  questionInExamData: object.isRequired,
  selectedQuestion: string.isRequired,
  selectedOption: string.isRequired,
  setSelectedOption: func.isRequired,
  allQuesAns: array.isRequired,
  qIndex: string.isRequired,
  description: string.isRequired,
  options: array.isRequired,
  itemId: string.isRequired,
  addBookmark: func.isRequired,
};

export default OlympiadQueAnsWrapper;
