import React, { useState, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Assignment from '../../../../../../assets/images/My assignments.svg';
import defaultPic from '../../../../../../assets/images/defaultPic.png';
import Student from '../../../../../../assets/images/sidebar-profile.png';
import close from '../../../../../../assets/images/close.png';
import { chevRight } from '../../../../../../assets/icons/IconList';
import SelectAssignmentQuestions from './SelectAssignmentQuestions';
import { getSyllabusMapping } from '../../../../../../services/teacherAssignment';
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';

const localDataVal = JSON.parse(localStorage.getItem('taut-student'));
const AssignmentsAndHomeWork = () => {
  const [syllabusList, setSyllausList] = useState([]);
  const [assignmentList, setAssignmentList] = useState(false);
  const [selectedConcept, setSelectedConcept] = useState('');
  const [selectedSubTopic, setSelectedSubTopic] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [selectedTopic, setSelectTopic] = useState('');
  const [selectedIds, setSelectedIds] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [assignErrorMess, setAssignErrorMess] = useState(false);
  const history = useHistory();

  const clickedData = (e, id, title, conceptId, item, chapterId) => {
    setSelectedIds({
      subConceptId: id,
      conceptid: conceptId,
      chapterid: chapterId,
    });
    setSelectedConcept(e.target.value);
    setSelectedSubTopic(title);
    setSelectTopic(item);
    setDisableButton(false);
    setAssignErrorMess(false);
  };

  const redirectToQue = () => {
    if (disableButton) {
      setAssignErrorMess(true);
      toast.error('Select a sub-concept to add assignment.');
    }
    if (!disableButton) {
      setAssignmentList(true);
    }
  };

  useEffect(() => {}, [assignErrorMess]);

  const getSyllabus = async () => {
    setIsLoading(true);
    const params = {
      student: localStorage.getItem('student'),
      teacher: JSON.parse(localStorage.getItem('taut-student'))?.data?._id,
      subject: localStorage.getItem('subject'),
    };
    try {
      setIsLoading(false);
      const res = await getSyllabusMapping(params);
      if (res.status === 200) {
        setSyllausList(res?.data?.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const redirecToStudentsList = () => {
    history.push('/teacher/students');
    // history.push('/teacher/assignments');
  };

  useEffect(() => {
    getSyllabus();
  }, []);

  return (
    <div>
      {!assignmentList ? (
        !syllabusList?.length > 0 ? (
          <div
            style={{
              height: '100vh',
              width: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner animation='border' variant='warning' />
          </div>
        ) : (
          <div className='teacher-assign-main'>
            <div className='container-body'>
              <div className='heading-and-icon'>
                <div className='row' style={{ width: '100%' }}>
                  <div className='col-md-7'>
                    <img src={Assignment} alt='Assignment' />
                    <h2 className='heading'>New Assignment</h2>
                    <h3>
                      Assign assignments to{' '}
                      <span className='studentNameAssQue'>
                        {localStorage.getItem('TeachStudentName')}
                      </span>{' '}
                      by selecting concepts & sub-concepts.
                    </h3>
                  </div>
                  <div className='col-md-5 text-right'>
                    <button type='button' onClick={redirecToStudentsList}>
                      <img src={close} alt='close' />
                    </button>
                  </div>
                </div>
              </div>

              <div className='container-lists-and-sublists-main'>
                <div className='lists-and-sublists-assignment'>
                  <div className='row'>
                    {syllabusList.map((item) => (
                      <div className='col-sm-4'>
                        <div
                          className='container-lists-and-sublists'
                          key={item.chapter}
                        >
                          <h3 className='list-name'>{item.chapterName}</h3>
                          <div
                            key={item.chapter}
                            className='progress-and-percentage'
                          >
                            <ProgressBar
                              now={
                                isNaN(
                                  Math.round(
                                    (item?.completedSubConceptsCount /
                                      item?.subConceptsCount) *
                                      100
                                  )
                                )
                                  ? 0
                                  : Math.round(
                                      (item?.completedSubConceptsCount /
                                        item?.subConceptsCount) *
                                        100
                                    )
                              }
                            />
                            <p className='percentage'>
                              {isNaN(
                                Math.round(
                                  (item?.completedSubConceptsCount /
                                    item?.subConceptsCount) *
                                    100
                                )
                              )
                                ? 0
                                : Math.round(
                                    (item?.completedSubConceptsCount /
                                      item?.subConceptsCount) *
                                      100
                                  )}
                              %
                            </p>
                          </div>
                          {item.concepts.map((data) => (
                            <div className='label-and-checkbox'>
                              <h3 key={data?.concept} className='sub-topic'>
                                {data?.conceptName}
                              </h3>
                              {data?.subconcepts.map((val) => (
                                <div className='data-desc' key={val.subconcept}>
                                  {val.stars === 5 ? (
                                    <>
                                      <input
                                        className='checkbox'
                                        disabled
                                        checked
                                        name={val.subconcept}
                                        type='radio'
                                      />
                                      <p className='subtopic-name'>
                                        {val.subconceptName}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        className='checkbox'
                                        onChange={(e) =>
                                          clickedData(
                                            e,
                                            val.subconcept,
                                            data?.conceptName,
                                            data?.concept,
                                            item?.chapterName,
                                            item?.chapter
                                          )
                                        }
                                        value={val.subconceptName}
                                        name='radioBtn'
                                        type='radio'
                                      />
                                      <p className='subtopic-name'>
                                        {val.subconceptName}
                                      </p>
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='fixed-assign-bar'>
              <div className='container-fixed-assign-bar'>
                <div className='row'>
                  <div className='heading-and-selected-topics col-md-6'>
                    <h2 className='heading-assigned'>SUB-TOPICS ASSIGNED:</h2>
                    <p className='selected-topics'>
                      {selectedSubTopic}{' '}
                      <span>{selectedSubTopic ? ':' : ''}</span>{' '}
                      {selectedConcept}
                    </p>
                  </div>
                  <div className='student-name-and-assign-button desktop-view col-md-6'>
                    <img
                      style={{ borderRadius: '50%' }}
                      src={
                        JSON.parse(localStorage.getItem('TeachStudent'))
                          .profilePic || defaultPic
                      }
                      alt='Student'
                    />
                    <div className='student-name-and-grades'>
                      <h2 className='student-name'>
                        {localStorage.getItem('TeachStudentName')}
                      </h2>
                      <h4 className='grades'>
                        Grade {localStorage.getItem('TeachStudentgrade')} |{' '}
                        {localStorage.getItem('TeachStudentSub')}
                      </h4>
                    </div>
                    <button
                      type='button'
                      onClick={redirectToQue}
                      className='assign-button button-common'
                    >
                      Assign {/* <span>{chevRight}</span> */}
                    </button>
                  </div>

                  <div className='student-name-and-assign-button mobile-view col-md-6'>
                    <div className='stu-common'>
                      <img src={Student} alt='Student' />
                      <div className='student-name-and-grades'>
                        <h2 className='student-name'>
                          {localStorage.getItem('TeachStudentName')}
                        </h2>
                        <h4 className='grades'>
                          Grade {localStorage.getItem('TeachStudentgrade')} |{' '}
                          {localStorage.getItem('TeachStudentSub')}
                        </h4>
                      </div>
                    </div>
                    <button
                      type='button'
                      onClick={redirectToQue}
                      className='assign-button button-common'
                    >
                      Select questions {/* <span>{chevRight}</span> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <SelectAssignmentQuestions
          selectedIds={selectedIds}
          selectedTopic={selectedTopic}
          selectedConcept={selectedConcept}
          selectedSubTopic={selectedConcept}
          setAssignmentList={setAssignmentList}
        />
      )}
    </div>
  );
};

export default AssignmentsAndHomeWork;
