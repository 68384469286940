import { fork, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './StudentAssignmentTypes';
import { studentAssignmentService } from '../../services/studentAssignment.service';

function* workerStartAssignment(data) {
  const value = data?.payload;
  const response = yield studentAssignmentService.startAssignment(value);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_START_ASSIGNMENT,
      payload: response?.data,
    });
  }
}
function* workerFinishAssignment(data) {
  const value = data?.payload;
  const response = yield studentAssignmentService.finishAssignment(value);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_FINISH_ASSIGNMENT,
      payload: response,
    });
  }
}

function* workerGetQuestionInAssignment(data) {
  const value = data?.payload;
  const response = yield studentAssignmentService.questionsInAssignment(value);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_QUESTION_IN_ASSIGNMENT,
      payload: response?.data[0]?.response,
    });
  }
}

function* watcherMyAssignment() {
  yield takeLatest(actionTypes.START_ASSIGNMENT, workerStartAssignment);
  yield takeLatest(actionTypes.FINISH_ASSIGNMENT, workerFinishAssignment);
  yield takeLatest(
    actionTypes.GET_QUESTION_IN_ASSIGNMENT,
    workerGetQuestionInAssignment
  );
}

function* fetchAll() {
  yield fork(watcherMyAssignment);
}

export default fetchAll();
