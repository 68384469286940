import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import './teachers.scss';
import { array } from 'yup/lib/locale';
import moment from 'moment';
import { func } from 'prop-types';
import Countdown from 'react-countdown';
import AddHistoryPopUp from './Rightsidepart/MyClass/AddHistoryPopUp';
import Warning from '../../../assets/images/warning.svg';

import Gradeicon from '../../../assets/images/gradeicon.svg';
import Studenticon from '../../../assets/images/students.svg';
import Testaudiovideo from '../../../assets/images/testaudiovideo.svg';
import stopwatch from '../../../assets/images/clock2.png';
import { useHistory, useLocation } from 'react-router';
import { startClass } from '../../../services/teacherAssignment';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
// import Timer from './TimerTeacher';

const OnlineClassalert = ({
  latestClass,
  setRemainingTime,
  setResetPage,
  userData,
}) => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [model, setModel] = useState(false);

  const [modelPre, setModelPre] = useState(false);

  const [selectedChapter, setSelectedChapter] = useState('');
  const [selectedConcept, setSelectedConcept] = useState('');
  const [selectedSubConcept, setSelectedSubConcept] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  const [enableJoin, setEnableJoin] = useState(false);
  const studentData = JSON.parse(localStorage.getItem('taut-student'));

  const targetDateTimeString = latestClass?.scheduleInfo?.startTime; // Set your target date and time here
  const targetDateTime = new Date(targetDateTimeString);
  const targetTime = targetDateTime.getTime(); // Convert target time to milliseconds

  // const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  // useEffect(() => {
  //   const currentTime = new Date().getTime(); // Get current time in milliseconds
  //   const twoMinutesInMilliseconds = 2 * 60 * 1000; // 2 minutes in milliseconds

  //   if (targetTime - currentTime <= twoMinutesInMilliseconds) {
  //     setIsButtonEnabled(true);
  //   }
  // }, []);

  const TestaudiovideoFunc = () => {
    setModelPre(true);
  };

  // const MyComponent = () => {
  //   return (
  //     <div>
  //       {/* <h1>My Component</h1> */}
  //       <Timer />
  //     </div>
  //   );
  // };

  const redirectToZoomSite = async () => {
    const obj = {
      schedule: latestClass?.id,
    };
    try {
      const res = await startClass(obj, userData?.data?.accessToken);
      if (res?.status === 200) {
        // window.open(
        //   `https://elearn.tautmore.com/?param1=${window.btoa(
        //     1
        //   )}&param2=${window.btoa(
        //     studentData?.data?.accessToken
        //   )}&param3=${window.btoa(
        //     res?.data?.data?.meetingInfo?.meetingId
        //   )}&param4=${window.btoa(
        //     studentData?.data?.fullName
        //   )}&param5=${window.btoa(
        //     res?.data?.data?.meetingInfo?.encryptedPassword
        //   )}`,
        //   '_blank'
        // );

        // MyComponent();

        window.open(res?.data?.data?.meetingInfo?.startUrl, '_blank');
        setEnableJoin(false);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
    // setCookie('fullname', studentData?.data?.fullName, {
    //   domain: '.tautmore.com',
    // });
    // setCookie('role', 1, {
    //   domain: '.tautmore.com',
    // });
    // setCookie('meetingid', latestClass?.scheduleInfo?.meetingId, {
    //   domain: '.tautmore.com',
    // });
    // setCookie('password', latestClass?.scheduleInfo?.encryptedPassword, {
    //   domain: '.tautmore.com',
    // });
    // setCookie('accessToken', studentData?.data?.accessToken, {
    //   domain: '.tautmore.com',
    // });

    // window.open('https://elearn.tautmore.com/teacher', '_blank');
  };
  const onAddDetailsClick = () => {
    const currentTime = new Date().getTime(); // Get current time in milliseconds
    const twoMinutesInMilliseconds = 2 * 60 * 1000; // 2 minutes in milliseconds

    if (targetTime - currentTime <= twoMinutesInMilliseconds) {
      !enableJoin ? setModel(true) : redirectToZoomSite();
    } else {
      toast.error('You can join 2 minutes before');
    }
  };
  // !enableJoin ? setModel(true) : redirectToZoomSite();

  const startTime = latestClass?.scheduleInfo?.timeSlot.substr(0, 8);
  const startDate = latestClass?.scheduleInfo?.startTime.substr(0, 10);

  const classStartTime = moment(startTime, 'h:mm:ss A').format('HH:mm:ss');
  const classStartDate = moment(startDate).format('DD/MM/YYYY');

  const classTimeAndDate = classStartDate.concat(' ', classStartTime);
  const now1 = new Date();
  const t = now1.toLocaleDateString();
  const t1 = moment(t).format('DD/MM/YYYY');
  const t2 = now1.toLocaleTimeString();

  const t3 = moment(t2, 'h:mm:ss A').format('HH:mm:ss');
  const todayTimeAndDate = t1.concat(' ', t3);
  const ms = moment(classTimeAndDate, 'DD/MM/YYYY HH:mm:ss').diff(
    moment(todayTimeAndDate, 'DD/MM/YYYY HH:mm:ss')
  );
  const d = moment.duration(ms);
  const s = `${Math.floor(d.asHours())}h ${moment.utc(ms).format('mm')}mins`;

  const now = moment();
  const classTime = moment(latestClass?.startTime);

  setRemainingTime(s);

  const [show, setShow] = useState(false);

  useEffect(() => {
    const addtopic = query.get('addtopic');
    const detailviews = query.get('detailviews');

    if (!addtopic && !detailviews) {
      setModel(false);
      history.push('/teacher/classes');
    }
    // else if (braingym){
    //   setViewBrain('workout');
    // }
  }, [location.search]);

  useEffect(() => {
    const checkTime = () => {
      if (!latestClass?.scheduleInfo?.startTime) {
        setShow(false);
      } else {
        setShow(true);
      }
    };
    checkTime();
  });

  const renderer = ({ hours, minutes, seconds, days }) => {
    let dayNumber = '';
    if (days > 1) {
      dayNumber = 'days';
    } else if (days === 1) {
      dayNumber = 'day';
    }
    return (
      <span>
        {' '}
        {days > 0 && days} {days > 0 && dayNumber} {hours} hrs {minutes} mins{' '}
        {seconds} s{' '}
      </span>
    );
  };

  return (
    <div>
      {show && (
        <div className='main1'>
          <div className='main2'>
            <div className='onlineclassmain'>
              <div>
                <p className='onlineHeading'>
                  <img className='rawnimg' src={Warning} alt='warning' />
                  Online class alert!
                </p>
              </div>
              <div>
                <p className='onlineTime'>
                  <img className='rawnimg' src={stopwatch} alt='clock' />
                  Starts in
                  <Countdown
                    date={Date.now() + classTime.diff(now)}
                    renderer={renderer}
                  />
                </p>
              </div>
            </div>
            <div className=' col-md-12 col-sm-12 onlineclasssubject'>
              <div className='row'>
                <div className='col-sm-3 col-md-3 floatleft subdivmain'>
                  <div className='col-sm-4 subimgdiv'>
                    <img
                      className=''
                      src={latestClass?.icons?.image}
                      alt='warning'
                    />
                  </div>
                  <div className='col-sm-8 subtopicsec'>
                    <p className='onlineSubTitle'>{latestClass?.subject}</p>
                    {latestClass?.subChapter ? (
                      <h6 style={{ align: 'center' }}>
                        {latestClass?.subChapter?.length > 15
                          ? `${latestClass?.subChapter.slice(0, 15)}...`
                          : latestClass?.subChapter}
                      </h6>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='col-md-6 col-sm-6'>
                  <div className='row middlediv'>
                    <div className='col-sm-4 d-flex align-items-center'>
                      <img className='' src={Gradeicon} alt='Gradeicon' />{' '}
                      <div className='ps-1'> Grade {latestClass?.grade}</div>
                    </div>
                    <div className='col-sm-4 d-flex align-items-center'>
                      <img className='' src={Studenticon} alt='Studenticon' />{' '}
                      <div className='ps-1'>
                        {latestClass?.numberOfStudent} students
                      </div>
                    </div>
                    <div className='col-sm-4 d-flex align-items-center'>
                      <img
                        style={{ height: '30px', width: '30px' }}
                        className='Clockicon'
                        src={stopwatch}
                        alt='clock'
                      />
                      <div className='ps-1'>
                        {latestClass?.duration} minutes
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-3 col-md-3 floatleft'>
                  <div className='jionlink'>
                    <button
                      type='button'
                      // disabled={!isButtonEnabled}
                      onClick={() => {
                        onAddDetailsClick();
                        history.push({
                          pathname: '/teacher/classes',
                          // search: '?addtopic=true',
                        });
                      }}
                    >
                      {!enableJoin ? 'Add Topic' : 'Join Link'}
                    </button>
                  </div>
                  {/* <div className='audioLinkdiv'>
                    {' '}
                    <button
                      type='button'
                      className='audioLink'
                      onClick={() => TestaudiovideoFunc()}
                    >
                      {' '}
                      <img
                        className='testicon'
                        src={Testaudiovideo}
                        alt='clock'
                      />
                      Test audio & video
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <AddHistoryPopUp
            latestClass={latestClass}
            model={model}
            handleModel={setModel}
            selectedChapter={selectedChapter}
            setSelectedChapter={setSelectedChapter}
            selectedConcept={selectedConcept}
            setSelectedConcept={setSelectedConcept}
            selectedSubConcept={selectedSubConcept}
            setSelectedSubConcept={setSelectedSubConcept}
            setEnableJoin={setEnableJoin}
            setResetPage={setResetPage}
          />
        </div>
      )}
    </div>
  );
};

OnlineClassalert.propTypes = {
  latestClass: array.isRequired,
  setRemainingTime: func.isRequired,
};

const mapStateToProps = (state) => ({
  userData: state.Auth.Login,
});

export default connect(mapStateToProps, null)(OnlineClassalert);
