import React, { useEffect, useState } from 'react';
import { renderText } from '../../BrainGym/QueAnswer/textHelper';
import { array, func, string } from 'prop-types';
import {
  checkDragDropSnunscramble,
  findKeyByValue,
} from '../../BrainGym/QueAnswer/questionHelper';
import { arrayareEqual } from '../../../../../../helpers/utility';
import { FailureAudio, SuccessAudio } from '../../../../audioSignal';
import ReactHtmlParser from 'react-html-parser';
import { renderTextNew } from '../../BrainGym/QueAnswer/newTextHelper';

export const SelectAWordFromQuesPreview = ({
  questionObject,
  statusVal,
  // play,
  // setPlay,
}) => {
  const questionType = findKeyByValue(questionObject?.solutionType);
  if (checkDragDropSnunscramble(questionType)) return null;
  const extractContent = (s) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <div className='question-box custom-question-box'>
            <h4 className='d-flex'>
              {statusVal?.questionInfo?.title == '' ||
              statusVal?.questionInfo?.title == null
                ? findKeyByValue(statusVal?.questionInfo?.solutionType)
                : renderText(statusVal?.questionInfo?.title)}
            </h4>
            {!arrayareEqual(
              statusVal.questionInfo.solutionIndex,
              statusVal.result.solutionIndex
            ) ? (
              <>
                {/* {play && <FailureAudio />} */}
                <span className='answer-title customAnswerTitle answer-wrong'>
                  Your below answer is incorrect
                  {statusVal.questionInfo.options.map((item, i) => (
                    <div>
                      {statusVal.questionInfo.solutionIndex[0] === i && (
                        <div>
                          {item?.imageElement === null && (
                            <span>{renderText(item?.text)} </span>
                          )}
                          {item?.imageElement !== '' && (
                            <span>{renderText(item?.imageElement)}</span>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </span>
              </>
            ) : (
              <>
                {/* {play && <SuccessAudio />} */}
                <span className='answer-title answer-correct'>
                  Your answer is correct
                </span>
              </>
            )}
            <h2>
              {findKeyByValue(questionObject?.solutionType) ===
                'preposition-sentence' ||
              findKeyByValue(questionObject?.solutionType) ===
                'select-the-suitable-words' ? (
                extractContent(questionObject?.description)
                  ?.split(' ')
                  ?.map((item, index) => {
                    return (
                      <button
                        className={`${
                          statusVal?.result?.solutionIndex?.includes(index) &&
                          questionObject?.solutionIndex?.includes(index)
                            ? 'olympiad_active_btn'
                            : statusVal?.result?.solutionIndex?.includes(index)
                            ? 'olympiad_active_incorrect_btn'
                            : ''
                        }`}
                        style={{ marginRight: '5px' }}
                        key={item}
                      >
                        {item}
                      </button>
                    );
                  })
              ) : findKeyByValue(questionObject?.solutionType) ===
                'prepositional-phrase' ? (
                extractContent(questionObject?.description)
                  ?.split(' ')
                  ?.map((item, index) => {})
              ) : (
                <span>{renderText(questionObject?.description)}</span>
              )}
            </h2>
          </div>
        </div>
      </div>
      {!arrayareEqual(
        statusVal.questionInfo.solutionIndex,
        statusVal.result.solutionIndex
      ) ? (
        <>
          <div className='row'>
            <div className='col-12'>
              <div className='question-box custom-question-box'>
                <span className='answer-correct answer-title'>
                  Correct Answer
                </span>
                <div className='col-sm-8'>
                  <h2>
                    {findKeyByValue(questionObject?.solutionType) ===
                      'preposition-sentence' ||
                    findKeyByValue(questionObject?.solutionType) ===
                      'select-the-suitable-words'
                      ? extractContent(questionObject?.description)
                          ?.split(' ')
                          ?.map((item, index) => {
                            return (
                              <button
                                className={`${
                                  questionObject?.solutionIndex?.includes(index)
                                    ? 'olympiad_active_btn'
                                    : ''
                                }`}
                                style={{ marginRight: '5px' }}
                                key={item}
                              >
                                {item}
                              </button>
                            );
                          })
                      : ''}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      {statusVal.questionInfo.solutionDescription ? (
        <div className='option-wrapper-solution mb-4 mt-0'>
          <h4>Solution Description</h4>
          <div>{renderTextNew(statusVal.questionInfo.solutionDescription)}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
SelectAWordFromQuesPreview.propTypes = {
  selectedOption: string.isRequired,
  setSelectedOption: func.isRequired,
  allQuesAns: array.isRequired,
  qIndex: string.isRequired,
  description: string.isRequired,
  options: array.isRequired,
  itemId: string.isRequired,
  addBookmark: func.isRequired,
};
export default SelectAWordFromQuesPreview;
