import React from 'react';
import { convertSecsToMin } from '../../BrainGym/QueAnswer/textHelper';
const PracticeTestHeaderInfoSection = ({
  statusVal,
  timeTakenToAnswer,
  topBar,
  takeTestResponse,
}) => {
  return (
    <div
      className='practicetestHeaderWrapper d-flex d-lg-none  align-items-end justify-content-around col-12 col-lg-5 pt-3 pb-1 mb-1 '
      //   style={{ width: '100%', background: 'white' }}
    >
      <div className='col-md-3  align-items-center'>
        <div className='congrats-detail-sub-part custom-congrats-detail-sub-part-wrapper'>
          <div className='congrats-detail-sub-part-right '>
            <p className='m-0 text-center'>Time taken </p>
            <h5 className='text-center'>
              {topBar
                ? convertSecsToMin(statusVal?.timeTakenInSecs)
                : convertSecsToMin(timeTakenToAnswer)}
            </h5>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='congrats-detail-sub-part custom-congrats-detail-sub-part-wrapper'>
          <div className='congrats-detail-sub-part-right'>
            <p className='m-0 text-center'>Answered</p>
            <h5 className='text-center'>
              {statusVal?.rightAnswers
                ? statusVal?.rightAnswers
                : takeTestResponse.data?.data?.rightAnswers}{' '}
              /{' '}
              {statusVal?.answeredQuestions
                ? statusVal?.answeredQuestions
                : takeTestResponse.data?.data?.result?.length}
            </h5>
          </div>
        </div>
      </div>
      <div className='col-md-3'>
        <div className='congrats-detail-sub-part custom-congrats-detail-sub-part-wrapper'>
          {/* <img src={clipboard} alt='stopwatch' /> */}
          <div className='congrats-detail-sub-part-right'>
            <p className='m-0 text-center'>Smart score</p>
            <h5 className='text-center'>
              {' '}
              {statusVal?.totalMarksObtained
                ? statusVal?.totalMarksObtained
                : takeTestResponse.data?.data?.totalMarksObtained}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PracticeTestHeaderInfoSection;
