export const START_CHEST = 'START_CHEST';
export const UPDATE_START_CHEST = 'UPDATE_START_CHEST';
export const GET_QUESTION_IN_CHEST = 'GET_QUESTION_IN_CHEST';
export const UPDATE_GET_QUESTION_IN_CHEST = 'UPDATE_GET_QUESTION_IN_CHEST';
export const ATTEMPT_QUESTION = 'ATTEMPT_QUESTION';
export const UPDATE_ATTEMPT_QUESTION = 'UPDATE_ATTEMPT_QUESTION';
export const COMPLETE_CHEST = 'COMPLETE_CHEST';
export const UPDATE_COMPLETE_CHEST = 'UPDATE_COMPLETE_CHEST';
export const GET_CHEST_INFO = 'GET_CHEST_INFO';
export const UPDATE_GET_CHEST_INFO = 'UPDATE_GET_CHEST_INFO';
export const GET_ALL_SHELL = 'GET_ALL_SHELL';
export const UPDATE_ALL_SHELL = 'UPDATE_ALL_SHELL';
export const GET_INCORRECT_ANSWER = 'GET_INCORRECT_ANSWER';
export const UPDATE_INCORRECT_ANSWER = 'UPDATE_INCORRECT_ANSWER';
export const GET_LAST_FIVE_CHEST = 'GET_LAST_FIVE_CHEST';
export const UPDATE_GET_LAST_FIVE_CHEST = 'UPDATE_GET_LAST_FIVE_CHEST';
export const GET_LONGEST_STREAK = 'GET_LONGEST_STREAK';
export const UPDATE_LONGEST_STREAK = 'UPDATE_LONGEST_STREAK';
export const GET_QUESTION_IN_CHEST_EMPTY = 'GET_QUESTION_IN_CHEST_EMPTY';
export const ATTEMPT_QUESTION_EMPTY = 'ATTEMPT_QUESTION_EMPTY';
export const CLEAR_SHELL_AND_QUESTION = 'CLEAR_SHELL_AND_QUESTION';
