import * as actionTypes from './MyAssignmentExamTypes';

export const upcomingAssignementExamAction = (data) => ({
  type: actionTypes.MYASSIGNMENTS_UPCOMING_EXAM,
  payload: data,
});
export const getUnattemptedAssignementExamsAction = (data) => ({
  type: actionTypes.UNATTEEMPTED_MYASSIGNMENTS_EXAM,
  payload: data,
});
export const previousAssignementExamAction = (data) => ({
  type: actionTypes.PREVIOUS_MYASSIGNMENTS_EXAM,
  payload: data,
});
export const myAssignementReportAction = (data) => ({
  type: actionTypes.GET_MY_MYASSIGNMENTS_REPORT,
  payload: data,
});
export const clearMyAssignementReportAction = () => ({
  type: actionTypes.UPDATE_MY_MYASSIGNMENTS_REPORT,
  payload: {},
});
export const startExamAction = (data) => ({
  type: actionTypes.START_MYASSIGNMENTS_EXAM,
  payload: data,
});
export const finishAssignementExamAction = (data) => ({
  type: actionTypes.FINISH_MYASSIGNMENTS_EXAM,
  payload: data,
});
export const clearAssignementExamAction = () => ({
  type: actionTypes.CLEAR_FINISH_MYASSIGNMENTS_EXAM,
  payload: {},
});

export const getQuestionInExamAction = (data) => ({
  type: actionTypes.GET_QUESTION_IN_MYASSIGNMENTS_EXAM,
  payload: data,
});
export const clearQuestionInExamAction = (data) => ({
  type: actionTypes.CLEAR_QUESTION_IN_MYASSIGNMENTS_EXAM,
  payload: {},
});

export const updateAssignementAllQueAnsAction = (data) => ({
  type: actionTypes.UPDATE_ALL_MYASSIGNMENTS_QUESTION_ANSWER,
  payload: data,
});
export const addAssignementAllQueAnsAction = (data) => ({
  type: actionTypes.ADD_ALL_MYASSIGNMENTS_QUESTION_ANSWER,
  payload: data,
});

export default {};
