import { func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import close from '../../../../../../assets/images/close.png';
import { getAssignmentView } from '../../../../../../services/teacherAssignment';
import MyassignmentAnswerLeftpart from './MyassignmentAnswerLeftPart';
import MyassignmentAnswerRightpart from './MyassignmentAnswerRightpart';
import Spinner from 'react-bootstrap/Spinner';

const MyassignmentAnswer = ({ assignmentId, setAssignment }) => {
  const [assignReport, setAssignReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const closeReport = () => {
    setAssignment(false);
  };
  const Loader = () => (
    <div
      style={{
        height: '100vh',
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner animation='border' variant='warning' />
    </div>
  );
  const viewAssignment = async () => {
    setIsLoading(true);
    try {
      const res = await getAssignmentView({ assignment: assignmentId });
      if (res.status === 200) {
        setAssignReport(res?.data?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    viewAssignment();
  }, []);
  return (
    <div className='myexam-answer-main-top'>
      <div className='close-top'>
        <button type='button' className='close-btn' onClick={closeReport}>
          <img src={close} alt='close' />
        </button>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='row myexam-answer-main'>
          <div className='col-md-4'>
            <MyassignmentAnswerLeftpart assignReport={assignReport} />
          </div>
          <div className='col-md-8'>
            <MyassignmentAnswerRightpart assignReport={assignReport} />
          </div>
        </div>
      )}
    </div>
  );
};

MyassignmentAnswer.propTypes = {
  assignmentId: string.isRequired,
  setAssignment: func.isRequired,
};

export default MyassignmentAnswer;
