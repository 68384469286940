import config from '../helpers/config';
import { fetchWrapper } from '../helpers/fetch-wrapper';

const baseUrl = `${config.apiBaseUrl}`;

function getStudentClasses(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/online-class/students/upcoming-classes`,
    body,
    token
  );
}

function getStudentCoCurricularClasses(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/cocurricular-online-class/students/upcoming-classes`,
    body,
    token
  );
}

function getStudentClassesHistory(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/online-class/students/history`,
    body,
    token
  );
}
function studentJoinClass(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/online-class/students/join-online-class`,
    body,
    token
  );
}

function studentJoinCocurricularClass(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/cocurricular-online-class/students/join-online-class`,
    body,
    token
  );
}

function getStudentUnattendedClassesHistory(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/online-class/students/unattempted-online-classes-history`,
    body,
    token
  );
}

function getStudentCoCurricularClassesHistory(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/cocurricular-online-class/students/history`,
    body,
    token
  );
}

function getStudentUnattendedCocurricularClassesHistory(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/cocurricular-online-class/students/unattempted-online-classes-history`,
    body,
    token
  );
}

function getStudentList(id, token) {
  return fetchWrapper.get(
    `${baseUrl}/online-class/teachers/students-listing-per-schedule?schedule=${id}&page_no=1`,
    token
  );
}
function getUnseenNotificationCount(token) {
  return fetchWrapper.get(
    `${baseUrl}/notifications/getUnseenNotificationCount`,
    token
  );
}
function readNotifications(body, token) {
  return fetchWrapper.post(
    `${baseUrl}/notifications/readNotification?notificationId=${body?.notificationId}`,
    body,
    token
  );
}

function getUnseenNotifications(token, page) {
  return fetchWrapper.get(
    `${baseUrl}/notifications/getNotifications?page_no=${page}&limit=10`,
    token
  );
}

function getStudentListCurricular(id, token) {
  return fetchWrapper.get(
    `${baseUrl}/cocurricular-online-class/teachers/students-listing-per-schedule?schedule=${id}&page_no=1`,
    token
  );
}

function getStudentsEmiDetailsByEmiIdService(id, token) {
  return fetchWrapper.get(`${baseUrl}/emi/v2/emi-details/${id}`, token);
}

export const studentDataServices = {
  getStudentClasses,
  getStudentCoCurricularClasses,
  getStudentClassesHistory,
  getStudentCoCurricularClassesHistory,
  getStudentList,
  getStudentListCurricular,
  getStudentUnattendedClassesHistory,
  studentJoinClass,
  getStudentUnattendedCocurricularClassesHistory,
  studentJoinCocurricularClass,
  readNotifications,
  getUnseenNotificationCount,
  getUnseenNotifications,
  getStudentsEmiDetailsByEmiIdService,
};

export default {};
