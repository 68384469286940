import React, { useState } from 'react';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input';
import { object, string, func, array } from 'prop-types';
import {
  verifyOtpAction,
  verifyOtpParentAction,
} from '../../../../stores/Auth/AuthAction';

const Mydetailotpinput = ({
  label,
  verifyOtp,
  verifyOtpTeacher,
  userType,
  // otpVal,
  phoneNumVal,
  // setOtpVal,
  verifyOtpTeacherResponse,
  verifyOtpData,
  verifyOtpParent,
  verifyOtpParentData,
}) => {
  const [otpVal, setOtpVal] = useState('');
  const handeInput = (data) => {
    const str = data.toString();
    setOtpVal(data);

    if (str.length === 6 && userType === 'Student') {
      const reqData = {
        phone: phoneNumVal,
        otp: data,
      };
      verifyOtp(reqData);
    }
    if (str.length === 6 && userType === 'Parent') {
      const reqData = {
        phone: phoneNumVal,
        otp: data,
      };
      verifyOtpParent(reqData);
    }

    if (str.length === 6 && userType === 'Teacher') {
      const reqData = {
        phoneNumber: phoneNumVal,
        otp: data,
      };
      verifyOtpTeacher(reqData);
    }
  };

  return (
    <div className='col-md-6 otp-block'>
      <label htmlFor='mydetail-input'>{label}</label>
      <OtpInput
        value={otpVal}
        onChange={(otp) => handeInput(otp)}
        numInputs={6}
        separator={<span> </span>}
      />

      {verifyOtpTeacherResponse?.statusCode === 401 && (
        <span className='error-msg'>
          Provided otp is invalid or already used.
        </span>
      )}
      {verifyOtpTeacherResponse?.statusCode === 403 && (
        <span className='error-msg'>
          Provided otp is invalid or already used.
        </span>
      )}
      {verifyOtpTeacherResponse?.statusCode === 404 && (
        <span className='error-msg'>
          Provided otp is invalid or already used.
        </span>
      )}

      {verifyOtpData?.statusCode === 401 ? (
        <span className='error-msg'>Plese enter valid OTP</span>
      ) : (
        ''
      )}
      {verifyOtpParentData?.statusCode === 401 ? (
        <span className='error-msg'>Plese enter valid OTP</span>
      ) : (
        ''
      )}
    </div>
  );
};

Mydetailotpinput.propTypes = {
  label: string.isRequired,
  // otpVal: string.isRequired,
  // setOtpVal: func.isRequired,
  verifyOtp: func.isRequired,
  phoneNumVal: string.isRequired,
  verifyOtpData: object.isRequired,
  userType: string.isRequired,
  verifyOtpTeacher: func.isRequired,
  verifyOtpTeacherResponse: array.isRequired,
  verifyOtpParent: func.isRequired,
  verifyOtpParentData: object.isRequired,
};
const mapStateToProps = (state) => ({
  verifyOtpData: state.Auth.verifyOtp,
  verifyOtpParentData: state.Auth.verifyOtpParent,
});

const mapDispatchToProps = (dispatch) => ({
  verifyOtp: (data) => dispatch(verifyOtpAction(data)),
  verifyOtpParent: (data) => dispatch(verifyOtpParentAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mydetailotpinput);
