import React from 'react';

const NoNotificationmenu = ({ className }) => {
  return (
    <div
      className={`notification-menu ${className} `}
      style={{ width: '480px' }}
    >
      <div
        className='row d-flex align-items-center'
        style={{ borderBottom: '1px solid #eaeaea', padding: '20px' }}
      >
        <div className='col-4'>
          <h4 className='border-0 p-0 m-0'>{'Notifications'}</h4>
        </div>
      </div>
      <div className='notification-menu-wrapper' style={{ height: '90px' }}>
        <div className='notificationMenuInside'>
          <h3 className='no-notification'>No Notifications</h3>
        </div>
      </div>
    </div>
  );
};

export default NoNotificationmenu;
