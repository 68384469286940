import { fork, put, select, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './AuthTypes';
import { authServices } from '../../services/auth.services';
import { findIndex } from 'lodash';

function* workerLogin(data) {
  yield put({
    type: actionTypes.SET_LOGIN_LOADING,
    payload: true,
  });
  const response = yield authServices.login(data.payload);

  if (response) {
    if (
      response?.data?.userType === 'student' ||
      response?.data?.userType === 'teacher'
    ) {
      localStorage.setItem('taut-student', JSON.stringify(response));
      yield put({
        type: actionTypes.UPDATE_LOGIN,
        payload: response,
      });
    } else if (response?.data?.userType === 'parent') {
      localStorage.setItem('taut-parent', JSON.stringify(response));
      localStorage.setItem(
        'taut-student',
        JSON.stringify({
          data: {
            ...response.data?.students[0],
            userType: 'parent',
            accessToken: response.data?.accessToken,
            currencyCode: response.data?.students[0]?.currencyCode,
          },
        })
      );
      yield put({
        type: actionTypes.UPDATE_LOGIN,
        payload: {
          data: {
            ...response.data?.students[0],
            userType: 'parent',
            accessToken: response.data?.accessToken,
            currencyCode: response.data?.students[0]?.currencyCode,
          },
        },
      });
      yield put({
        type: actionTypes.UPDATE_LOGIN_PARENT,
        payload: response,
      });
    } else if (response?.statusCode === 401) {
      yield put({
        type: actionTypes.UPDATE_LOGIN,
        payload: response,
      });
    } else if (response?.statusCode === 402) {
      localStorage.setItem(
        'loginDetailsData',
        JSON.stringify(response?.details)
      );
      yield put({
        type: actionTypes.UPDATE_LOGIN,
        payload: response,
      });
    } else if (response?.statusCode === 404) {
      yield put({
        type: actionTypes.UPDATE_LOGIN,
        payload: response,
      });
    } else if (response?.statusCode === 410) {
      yield put({
        type: actionTypes.UPDATE_LOGIN,
        payload: response,
      });
    }
    yield put({
      type: actionTypes.SET_LOGIN_LOADING,
      payload: false,
    });
  }
}

function* workerRegister(data) {
  const response = yield authServices.register(data.payload);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_REGISTER,
      payload: response,
    });
  }
}
function* workerStudentProfile(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield authServices.studentMyprofile(value, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_STUDENT_PROFILE,
      payload: response,
    });
  }
}
function* workerStudentChangePass(data) {
  const value = data?.payload;
  const state = yield select();
  const selectedStu = data.id
    ? state.Auth.LoginParent.data.students.filter((stu) => stu._id === data.id)
    : '';
  const token = data.id
    ? selectedStu[0]?.accessToken
    : state.Auth.Login.data.accessToken;
  const response = yield authServices.studentChangePass(value, token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_STUDENT_CHANGE_PASS,
      payload: response,
    });
  }
}
function* workerStudentProfileImg(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield authServices.studentProfilePic(value, token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_STUDENT_PROFILE_IMG,
      payload: response,
    });
  }
}
function* workerParentChangePass(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield authServices.parentChangePass(value, token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_PARENT_CHANGE_PASS,
      payload: response,
    });
  }
}
function* workerParentProfileImg(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield authServices.parentProfilePic(value, token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_PARENT_PROFILE_IMG,
      payload: response,
    });
  }
}

function* workerCoCurricularActivities() {
  // const state = yield select();
  // const token = state.Auth.Login.data.accessToken;
  const response = yield authServices.coCurricularActivities();

  if (response) {
    yield put({
      type: actionTypes.UPDATE_CO_CURRICULAR_ACTIVITIES,
      payload: response,
    });
  }
}

function* workerTimeslots() {
  const response = yield authServices.getTimeSlots();

  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_TIMESLOTS,
      payload: response,
    });
  }
}

function* workerSendOtp(data) {
  yield put({
    type: actionTypes.UPDATE_VERIFY_OTP,
    payload: true,
  });
  const response = yield authServices.sendOtp(data.payload);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_SEND_OTP,
      payload: response,
    });
  }
}

function* workerVerifyOtp(data) {
  const response = yield authServices.verifyOtp(data.payload);
  if (response?.details) {
    localStorage.setItem('loginDetailsData', JSON.stringify(response?.details));
  }

  if (response) {
    yield put({
      type: actionTypes.UPDATE_VERIFY_OTP,
      payload: response,
    });
  }
}

function* workerGetAllGrades(data) {
  const params = data.payload;
  const response = yield authServices.getAllGrades(params);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_ALL_GRADES,
      payload: response,
    });
  }
}

function* workerGetAllBoards() {
  // const state = yield select();
  // const token = state.Auth.Login.data.accessToken;
  const response = yield authServices.getAllBoards();
  if (response) {
    yield put({
      type: actionTypes.UPDATE_ALL_BOARDS,
      payload: response,
    });
  }
}

function* workerGetUniqueSubjects() {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield authServices.getUniqueSubjects(token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_UNIQUE_SUBJECTS,
      payload: response,
    });
  }
}

function* workerGetSubjectsByclass(data) {
  // const state = yield select();
  // const token = state.Auth.Login.data.accessToken;
  const response = yield authServices.getSubjectsByclass(data?.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_SUBJECTS_BY_CLASS,
      payload: response,
    });
  }
}

function* workerRegisterTeacher(data) {
  const response = yield authServices.registerTeacher(data.payload);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_REGISTER_TEACHER,
      payload: response,
    });
  }
}

function* workerGetUniversities() {
  const response = yield authServices.getUniversities();
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_UIVERSITIES,
      payload: response,
    });
  }
}

function* workerGetQualifications() {
  const response = yield authServices.getQualifications();
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_QUALIFICATIONS,
      payload: response,
    });
  }
}

function* workerGetCountries() {
  const response = yield authServices.getCountryList();

  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_COUNTRY_LIST,
      payload: response,
    });
  }
}

function* workerGetSubjects() {
  const response = yield authServices.getAllSubjects();
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_SUBJECTS,
      payload: response,
    });
  }
}

function* workerGetStates(data) {
  const response = yield authServices.getStateList(data.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_STATE_LIST,
      payload: response,
    });
  }
}

function* workerGetTimezone(data) {
  const response = yield authServices.getTimezoneList(data.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_TIMEZONE_LIST,
      payload: response,
    });
  }
}

function* workerDeleteDocument(data) {
  const response = yield authServices.deleteDocument(data.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_DELETE_DOCUMENT,
      payload: response,
    });
  }
}

function* workerVerifyEmailTeacher(data) {
  const response = yield authServices.verifyEmailTeacher(data?.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_CHCEK_EMAIL_TEACHER,
      payload: response,
    });
  }
}

function* workerVerifyEmail(data) {
  const response = yield authServices.verifyEmail(data?.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_VERIFY_EMAIL_RESPOSE,
      payload: response,
    });
  }
}

function* workerSendOtpTeacher(data) {
  const response = yield authServices.sendOtpTeacher(data?.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_SEND_OTP_TEACHER,
      payload: response,
    });
  }
}

function* workerVerifyOtpTeacher(data) {
  const response = yield authServices.verifyOtpTeacher(data.payload);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_VERIFY_OTP_TEACHER,
      payload: response,
    });
  }
}

function* workerSendOtpBookDemo(data) {
  const response = yield authServices.sendOtpBookDemo(data?.payload);
  // sendOtpBookDemo,
  // verifyOtpBookDemo

  if (response) {
    yield put({
      type: actionTypes.UPDATE_SEND_OTP_BOOK_DEMO,
      payload: response,
    });
  }
}

function* workerVerifyOtpBookDemo(data) {
  const response = yield authServices.verifyOtpBookDemo(data.payload);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_VERIFY_OTP_BOOK_DEMO,
      payload: response,
    });
  }
}

function* workerResetOtpBookDemo() {
  yield put({
    type: actionTypes.UPDATE_RESET_OTP_BOOK_DEMO,
    payload: [],
  });
}

function* workerTeacherFileUpload(data) {
  const response = yield authServices.uploadTeacher(data.payload);
  if (response) {
    yield put({
      type: actionTypes.TEACHER_DOCUMENT,
      payload: response,
    });
  }
}
function* workerTeacherFileReset(data) {
  const response = yield authServices.uploadTeacher(data.payload);
  if (response) {
    yield put({
      type: actionTypes.TEACHER_RESET_DOCUMENT,
      payload: response,
    });
  }
}

function* workerforgotPasswordTeacher(data) {
  const response = yield authServices.forgotPasswordTeacher(data?.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_FORGOT_PASSWORD_TEACHER,
      payload: response,
    });
  }
}
function* workerSendOtpParent(data) {
  yield put({
    type: actionTypes.UPDATE_VERIFY_OTP_PARENT,
    payload: true,
  });
  const response = yield authServices.sendOtpParent(data.payload);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_SEND_OTP_PARENT,
      payload: response,
    });
  }
}

function* workerVerifyOtpParent(data) {
  const response = yield authServices.verifyOtpParent(data.payload);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_VERIFY_OTP_PARENT,
      payload: response,
    });
  }
}
function* workerRegisterParent(data) {
  const response = yield authServices.registerParent(data.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_REGISTER_PARENT,
      payload: response,
    });
  }
}
function* workerAddGuardian(data) {
  const response = yield authServices.addGuardian(data.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_ADD_GUARDIAN,
      payload: response,
    });
  }
}
function* workerParentProfile(data) {
  const state = yield select();
  if (state.Auth.Login.data.userType === 'teacher') return;
  const token = state.Auth.Login.data.accessToken;
  const response = yield authServices.parentProfile(data.payload, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_PARENT_PROFILE,
      payload: response,
    });
  }
}
function* workerCreatePaypalOrder(data) {
  const response = yield authServices.createOrderPaypal(data.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_CREATE_ORDER_PAYPAL,
      payload: response,
    });
  }
}
function* workerCapturePaypalpayment(data) {
  const response = yield authServices.capturePaypal(data.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_CAPTURE_PAYPAL_PAYMENT,
      payload: response,
    });
  }
}
function* workerforgotPassword(data) {
  const response = yield authServices.forgotPassword(
    data.payload.formData,
    data?.payload?.userType
  );
  if (response.statusCode === 200) {
    data.payload.onSuccess();
    yield put({
      type: actionTypes.FORGOT_PASSWORD_SUCCESS,
      payload: response,
    });
  }
  if (response.statusCode === 401) {
    data.payload.onError1();
  }
  if (response.statusCode === 400) {
    data.payload.onError();
  }
  if (response.statusCode === 404) {
    data.payload.onError();
  }
}
function* workerGetUpdatedStudentData(data) {
  const studentId = data.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield authServices.getUpdatedStudentData(studentId, token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_STUDENT_DATA,
      payload: response,
    });
  }
}

function* workerGetAllExamTypes() {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield authServices.getAllExamTypes(token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_ALL_EXAM_TYPE_DATA,
      payload: response,
    });
  }
}

function* workerGetAllMonthsSlabs() {
  const response = yield authServices.getAllMonthsSlabs();
  if (response) {
    yield put({
      type: actionTypes.UPDATE_ALL_MONTHS_SLABS,
      payload: response,
    });
  }
}

function* workerUpdateSubscribedSubjects(data) {
  const value = '';
  const state = yield select();
  let token;
  if (state.Auth?.Login?.data?.userType && data?.payload?.studentId) {
    if (state.Auth?.Login?.data?.userType === 'parent') {
      const indexofStudent = state.Auth?.LoginParent?.data?.students.findIndex(
        (eachStudent) => eachStudent._id === data?.payload?.studentId
      );
      token =
        state.Auth?.LoginParent?.data?.students[indexofStudent]?.accessToken;
    } else if (state.Auth?.Login?.data?.userType === 'student') {
      token = state.Auth.Login.data.accessToken;
    } else {
      console.log('ERROR : Couldnt find access token');
    }
  } else {
    console.log('ERROR : Student Id or Usertype error');
  }

  const updatingLoginData = { ...state.Auth.Login };
  const response = yield authServices.studentMyprofile(value, token);

  if (response) {
    if (response.statusCode === 200) {
      const subjectsEnrolled =
        response?.data?.student_details?.subjectsEnrolled;
      const cocurricularsEnrolled =
        response?.data?.student_details?.cocurricularsEnrolled;
      const olympiadSubscription =
        response?.data?.student_details?.olympiadSubscription;
      if (
        updatingLoginData?.data &&
        subjectsEnrolled &&
        cocurricularsEnrolled &&
        olympiadSubscription
      ) {
        updatingLoginData.data.subjectsEnrolled = [...subjectsEnrolled];
        updatingLoginData.data.olympiadSubscription = [...olympiadSubscription];
        updatingLoginData.data.cocurricularsEnrolled = [
          ...cocurricularsEnrolled,
        ];
        if (state.Auth?.Login?.data?.userType && data?.payload?.studentId) {
          if (state.Auth?.Login?.data?.userType === 'parent') {
            let updatingParentData = { ...state.Auth?.LoginParent };
            const indexofStudent =
              state.Auth?.LoginParent?.data?.students.findIndex(
                (eachStudent) => eachStudent._id === data?.payload?.studentId
              );
            updatingParentData.data.students[indexofStudent].subjectsEnrolled =
              [...subjectsEnrolled];
            updatingParentData.data.students[
              indexofStudent
            ].olympiadSubscription = [...olympiadSubscription];
            updatingParentData.data.students[
              indexofStudent
            ].cocurricularsEnrolled = [...cocurricularsEnrolled];

            yield put({
              type: actionTypes.UPDATE_LOGIN_PARENT,
              payload: updatingParentData,
            });
            yield put({
              type: actionTypes.UPDATE_LOGIN,
              payload: updatingLoginData,
            });
          } else if (state.Auth?.Login?.data?.userType === 'student') {
            yield put({
              type: actionTypes.UPDATE_LOGIN,
              payload: updatingLoginData,
            });
          } else {
            console.log('ERROR : usertype not found');
          }
        } else {
          console.log('ERROR : Student Id or Usertype error');
        }
      } else {
        console.log('something went wrong while updating subject content');
      }
    } else {
      console.log('ERROR : couldent retrive Subject data', response);
    }
  }
}

function* workerGetAllClassByCountry(data) {
  const response = yield authServices.getAllClassByCountry(data.payload);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_ALL_CLASS_BY_COUNTRY,
      payload: response,
    });
  }
}

function* workerGetTotalPoints() {
  const state = yield select();
  if (state.Auth.Login.data.userType === 'teacher') return;
  let token;
  const studentId = state.Auth?.Login?.data?._id;
  if (state.Auth?.Login?.data?.userType) {
    if (state.Auth?.Login?.data?.userType === 'parent') {
      const indexofStudent = state.Auth?.LoginParent?.data?.students.findIndex(
        (eachStudent) => eachStudent._id === studentId
      );
      token =
        state.Auth?.LoginParent?.data?.students[indexofStudent]?.accessToken;
    } else if (state.Auth?.Login?.data?.userType === 'student') {
      token = state.Auth.Login.data.accessToken;
    } else {
      console.error('ERROR : Couldnt find access token');
    }
  } else {
    console.error('ERROR : Student Id or Usertype error');
  }

  const response = yield authServices.getPoints(
    { student: studentId, page_no: 1 },
    token
  );

  if (response) {
    const points = response?.data?.availablePoints[0]?.pointsObtained;
    yield put({
      type: actionTypes.UPDATE_TOTAL_POINTS,
      payload: points,
    });
  }
}

function* workerGetOpenPracticeCountryList() {
  const response = yield authServices.getOpenPracticeCountryList();

  if (response) {
    yield put({
      type: actionTypes.UPDATE_OPEN_PRACTICE_COUNTRY_LIST,
      payload: response,
    });
  }
}

function* workerGetGeoLocation() {
  const state = yield select();
  const geoLocationData = state.Auth.geoLocationData;
  if (!geoLocationData) {
    const response = yield authServices.getLocationByIp();
    if (response) {
      localStorage.setItem('ipLocation', JSON.stringify(response));
      yield put({
        type: actionTypes.UPDATE_GEO_LOCATION,
        payload: response,
      });
    }
  }
}

function* watcherAuth() {
  yield takeLatest(actionTypes.LOGIN, workerLogin);
  yield takeLatest(actionTypes.REGISTER, workerRegister);
  yield takeLatest(actionTypes.VERIFY_OTP, workerVerifyOtp);
  yield takeLatest(actionTypes.STUDENT_PROFILE, workerStudentProfile);
  yield takeLatest(actionTypes.STUDENT_CHANGE_PASS, workerStudentChangePass);
  yield takeLatest(actionTypes.STUDENT_PROFILE_IMG, workerStudentProfileImg);
  yield takeLatest(actionTypes.PARENT_PROFILE, workerParentProfile);
  yield takeLatest(actionTypes.PARENT_CHANGE_PASS, workerParentChangePass);
  yield takeLatest(actionTypes.PARENT_PROFILE_IMG, workerParentProfileImg);
  yield takeLatest(
    actionTypes.CO_CURRICULAR_ACTIVITIES,
    workerCoCurricularActivities
  );
  yield takeLatest(actionTypes.SEND_OTP, workerSendOtp);
  yield takeLatest(actionTypes.GET_ALL_GRADES, workerGetAllGrades);
  yield takeLatest(actionTypes.GET_ALL_BOARDS, workerGetAllBoards);
  yield takeLatest(actionTypes.GET_UNIQUE_SUBJECTS, workerGetUniqueSubjects);
  yield takeLatest(actionTypes.GET_SUBJECTS_BY_CLASS, workerGetSubjectsByclass);
  yield takeLatest(actionTypes.REGISTER_TEACHER, workerRegisterTeacher);
  yield takeLatest(actionTypes.GET_TIMESLOTS, workerTimeslots);
  yield takeLatest(actionTypes.GET_UNIVERSITIES, workerGetUniversities);
  yield takeLatest(actionTypes.GET_QUALIFICATIONS, workerGetQualifications);
  yield takeLatest(actionTypes.GET_COUNTRIES, workerGetCountries);
  yield takeLatest(actionTypes.GET_SUBJECTS, workerGetSubjects);
  yield takeLatest(actionTypes.GET_STATE_LIST, workerGetStates);
  yield takeLatest(actionTypes.GET_TIMEZONE_LIST, workerGetTimezone);
  yield takeLatest(actionTypes.DELETE_DOCUMENT, workerDeleteDocument);
  yield takeLatest(actionTypes.CHCEK_EMAIL_TEACHER, workerVerifyEmailTeacher);
  yield takeLatest(actionTypes.VERIFY_EMAIL, workerVerifyEmail);
  yield takeLatest(actionTypes.SEND_OTP_TEACHER, workerSendOtpTeacher);
  yield takeLatest(actionTypes.SEND_OTP_BOOK_DEMO, workerSendOtpBookDemo);
  yield takeLatest(actionTypes.VERIFY_OTP_BOOK_DEMO, workerVerifyOtpBookDemo);
  yield takeLatest(actionTypes.RESET_OTP_BOOK_DEMO, workerResetOtpBookDemo);
  yield takeLatest(actionTypes.VERIFY_OTP_TEACHER, workerVerifyOtpTeacher);
  yield takeLatest(
    actionTypes.UPLOAD_TEACHER_DOCUMENT,
    workerTeacherFileUpload
  );

  yield takeLatest(actionTypes.TEACHER_RESET, workerTeacherFileReset);

  yield takeLatest(
    actionTypes.FORGOT_PASSWORD_TEACHER,
    workerforgotPasswordTeacher
  );
  yield takeLatest(actionTypes.SEND_OTP_PARENT, workerSendOtpParent);
  yield takeLatest(actionTypes.VERIFY_OTP_PARENT, workerVerifyOtpParent);
  yield takeLatest(actionTypes.REGISTER_PARENT, workerRegisterParent);
  yield takeLatest(actionTypes.ADD_GUARDIAN, workerAddGuardian);
  yield takeLatest(actionTypes.CREATE_ORDER_PAYPAL, workerCreatePaypalOrder);
  yield takeLatest(
    actionTypes.CAPTURE_PAYPAL_PAYMENT,
    workerCapturePaypalpayment
  );
  yield takeLatest(actionTypes.FORGOT_PASSWORD, workerforgotPassword);
  yield takeLatest(
    actionTypes.GET_UPDATE_STUDENT_DATA,
    workerGetUpdatedStudentData
  );
  yield takeLatest(actionTypes.GET_ALL_EXAM_TYPE_DATA, workerGetAllExamTypes);
  yield takeLatest(actionTypes.GET_ALL_MONTHS_SLABS, workerGetAllMonthsSlabs);
  yield takeLatest(
    actionTypes.GET_ALL_CLASS_BY_COUNTRY,
    workerGetAllClassByCountry
  );

  yield takeLatest(
    actionTypes.GET_NEW_UPDATED_SUBJECTS,
    workerUpdateSubscribedSubjects
  );
  yield takeLatest(actionTypes.GET_TOTAL_POINTS, workerGetTotalPoints);
  yield takeLatest(
    actionTypes.GET_OPEN_PRACTICE_COUNTRY_LIST,
    workerGetOpenPracticeCountryList
  );
  yield takeLatest(actionTypes.GET_GEO_LOCATION, workerGetGeoLocation);
}

function* fetchAll() {
  yield fork(watcherAuth);
}

export default fetchAll();
